import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useHsStore = defineStore({
  id: "highSchool",
  state: () => ({
    highSchools: useLocalStorage("highSchools", []),
    countries: useLocalStorage("countries", []),
  }),
  getters: {
    getHighSchools: (state) => {
      return state.highSchools;
    },
    getCountries: (state) => {
      return state.highSchools;
    },
  },
  persist: true,
  actions: {
    setHighSchools(data) {
      this.highSchools = data;
    },
    setCountries(data) {
      this.highSchools = data;
    },
    removeHighSchool(id) {
      this.highSchools = this.highSchools.filter((obj) => obj.id !== id);
    },
  },
});
