<template>
  <div class="">
    <!-- Page Header -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div class="position-relative me-4 mb-3">
          <img style="width: 90px; height: 90" alt="uni-img" :src="school.cover_photo_url!=''?school.cover_photo_url:'@/assets/images/highschools-list/highschoolImg.svg'"/>
          <span
            style="
              width: 15px;
              height: 15px;
              border-radius: 100%;
              margin-right: 2px;
              margin-bottom: 1px;
              position: absolute;
              right: 5px;
            "
            :style="
              (school.status === 'Active' && 'background-color:green') ||
              (school.status === 'Pending' &&
                'background-color:rgba(249, 112, 102, 1)') ||
              (school.status === 'Inactive' && 'background-color:red')
            "
          ></span>
        </div>
        <div class="d-flex flex-column align-items-start">
          <h1>{{ school.name }}</h1>
          <p>
            Last Login: {{ lastTimeUpdated }} ,
            {{ lastDateUpdated }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center justify-content-center">
          <InputSwitch
            style=""
            @change="changeStatus"
            v-model="isActiveHighSchool"
          />
          <p
            style="font-weight: 600; font-size: 12px !important"
            class="m-0 p-0 ms-1"
          >
            {{ school.active == 1 ? "Active" : "Inactive" }}
          </p>
        </div>
        <p>Date: 2022-05-2</p>
      </div>
    </div>
    <div class="mt-3 ps-5 border-bottom w-100 d-flex align-items-center">
      <!-- Tabs -->
      <div
        @click="changeTab(1)"
        style="
          color: rgb(29, 41, 57);
          cursor: pointer;
          border-bottom: 4px solid transparent;
        "
        class="me-5 p-3"
        :style="
          currentTab === 1 && 'border-bottom: 4px solid red; font-weight: 600'
        "
      >
        High School Information
      </div>
      <div
        @click="changeTab(2)"
        style="
          cursor: pointer;
          border-bottom: 4px solid transparent;
          color: rgb(29, 41, 57);
        "
        :style="
          currentTab === 2 &&
          'border-bottom: 4px solid red !important ; font-weight:600'
        "
        class="p-3 me-5"
      >
        Contact Details
      </div>
      <div
        @click="changeTab(3)"
        style="
          cursor: pointer;
          border-bottom: 4px solid transparent;
          color: rgb(29, 41, 57);
        "
        :style="
          currentTab === 3 &&
          'border-bottom: 4px solid red !important ; font-weight:600'
        "
        class="p-3"
      >
        General Settings
      </div>
    </div>
    <HighSchoolInformationTab :highSchool="school" v-if="currentTab == 1" />
    <ContactDetailsTab :highSchool="school" v-if="currentTab === 2" />
    <!-- <GeneralSettingsTab :highSchool="school" v-if="currentTab === 3" /> -->
    <div
      v-if="currentTab !== 3"
      class="d-flex align-items-center justify-content-between w-80 m-auto mt-5"
    >
      <!-- Footer Buttons -->
      <button class="border py-2 px-5">Cancel</button>
      <button
        v-if="currentTab === 1"
        @click="changeTab(currentTab + 1)"
        class="bg-warning border py-2 px-5"
      >
        Next
      </button>
      <button
        v-else
        @click="openConfirmModal"
        class="bg-warning border py-2 px-5"
      >
        Save Changes.....
      </button>
    </div>
    <ConfirmModal
      v-if="isConfirmModalOpen"
      @closeConfirmModal="closeConfirmModal"
      @createProfile="createProfile"
    />
  </div>
</template>

<script>
import HighSchoolInformationTab from "@/components/HighSchoolsList/ViewHighSchoolDetails/HighSchoolInformationTab.vue";
import ContactDetailsTab from "@/components/HighSchoolsList/ViewHighSchoolDetails/ContactDetailsTab.vue";
//import GeneralSettingsTab from "@/components/HighSchoolsList/ViewHighSchoolDetails/GeneralSettingsTab.vue";
import ConfirmModal from "@/components/HighSchoolsList/ViewHighSchoolDetails/ConfirmModal.vue";
import { useHsStore } from "../../../stores/highSchools";
// import { highSchools } from "@/data";

export default {
  name: "HighSchoolDetail",
  components: {
    //GeneralSettingsTab,
    ContactDetailsTab,
    HighSchoolInformationTab,
    ConfirmModal,
  },
  props:{
    school:Object,
    index:Number,
    isDashboard:{
      type:Boolean,
      default:function(){
        return false
      }
    }
  },
  data() {
    return {
      isConfirmModalOpen: false,
      isActiveHighSchool: "",      
      currentTab: 1,
      lastTimeUpdated: "",
      lastDateUpdated: "",
    };
  },
  methods: {
    changeStatus() {
     /*  if (this.school.active === 1) {
        this.school.active = 0;
      } else {
        this.school.active = 1;
      } */
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
    setStatus() {
      const store = useHsStore();
      return (
        store.$state.highSchools.find(
          (school) => school.id == this.$route.params.highschoolname
        ).active === 1
      );
    },
    setHighSchool() {
      const store = useHsStore();
      return store.$state.highSchools.find(
        (school) => school.id == this.$route.params.highschoolname
      );
    },
    setUpdatedTime() {
      const updatedTime = this.school.updated_at.split("T");
      console.log(updatedTime[1]);
      this.lastTimeUpdated = updatedTime[1].split(".")[0];
      this.lastDateUpdated = updatedTime[0];
    },
  },
  beforeMount() {
 /*    this.school = this.setHighSchool();
    this.isActiveHighSchool = this.setStatus();
    this.setUpdatedTime(); */
    // console.log(this.school);
    // const store = useUniversityStore()
    // console.log(this.$route.params.universityname)
  },
};
</script>

<style scoped>
:deep(.p-inputswitch-checked .p-inputswitch .p-inputswitch-slider) {
  background-color: var(--teal-500) !important;
}
</style>
