<template>
  <div id="animatedFilterOptions" class="d-flex flex-column w-100">
    <div class="row d-flex justify-content-evenly align-items-center">
      <div class="col inputGroupContainer">
        <!-- University ID -->
        <label style="font-weight: 600 !important" class="mb-1"
          >University ID</label
        >

        <input v-model="search.unique_id" class="inputTypeText" type="text" placeholder="ID" />
      </div>
      <div class="col inputGroupContainer">
        <!-- University Name -->
        <label style="font-weight: 600 !important" class="mb-1"
          >University Name</label
        >
        <input v-model="search.name" class="inputTypeText" type="text" placeholder="Name" />
      </div>
      <div class="col inputGroupContainer">
        <!-- University Type -->
        <label style="font-weight: 600 !important" class="mb-1"
          >University Type</label
        >
        <DropDown
          v-model="search.type"
          :options="['Public','Private']"
          placeholder="High School Type"
          class="inputTypeSelect"
        />
      </div>
    </div>
    <div class="row d-flex justify-content-evenly align-items-center">
      <div class="col inputGroupContainer">
        <!-- University Status -->
        <label style="font-weight: 600 !important" class="mb-1">Status</label>

        <DropDown
          v-model="search.status"
          :options="[{name:'Active',id:1}, {name:'Inactive',id:0}]"
          optionValue="id"
          optionLabel="name"
          placeholder="Status"
          class="inputTypeSelect"
        />
      </div>
      <div class="col inputGroupContainer">
        <!-- University email -->
        <label style="font-weight: 600 !important" class="mb-1"
          >Email Address</label
        >

        <input class="inputTypeText" type="text" placeholder="Email" />
      </div>
      <div class="col inputGroupContainer">
        <!-- University Country -->
        <label style="font-weight: 600 !important" class="mb-1">Country</label>

        <DropDown
          v-model="search.country_id"
          :options="country()"
          optionValue="id"
          optionLabel="name"
          placeholder="Country"
          class="inputTypeSelect"
        />
      </div>
    </div>
    <div class="mt-4 d-flex align-items-center justify-content-start">
      <button @click="clear()" class="border py-2 px-5">Clear All</button>
      <button @click="searchNow()" class="ms-5 bg-warning border py-2 px-5">Search</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterOptions",
  props:['countries'],
  data() {
    return {
      universityTypes: ["Public", "Private"],
      selectedUniversityType: "",
      search:{
        unique_id:'',
        name:'',
        type:'',
        status:'',
        country_id:0,
        verification:''
      }
    };
  },
  methods:{
    country(){
      return this.countries;
    },
    clear(){
      this.search = {
        unique_id:'',
        name:'',
        type:'',
        status:'',
        country_id:''
      }
    },
    searchNow(){
      // Convert the `this.search` object to URL parameters
      if(this.search.country_id == 0){
        this.search.country_id = ''
      }
      const params = new URLSearchParams(this.search);

      // Get the URL parameter value
      const urlParameterValue = params.toString();

      this.$emit('onFilter',`?${urlParameterValue}`);
    }
  }
};
</script>

<style scoped>
#animatedFilterOptions {
  animation-name: animate;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.inputGroupContainer {
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inputTypeText {
  width: 90%;
  padding: 10px 14px 10px 12px;
  border: 0.5px solid #000000;
  border-radius: 4px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.inputTypeSelect {
  width: 90%;
  padding: 2px 2px 2px 2px;
  border: 0.5px solid #000000;
  border-radius: 8px;
}
</style>
