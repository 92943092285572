<template>
  <div>
    <div class="row w-100" style="overflow:auto;">
      <div class="col-md-4 position-relative">
        <div class="d-flex justify-content-between mb-3 w-100">
          <h5>Scene</h5>
          <button
            class="btn s-shadow s-bg2 text-white"
            data-bs-toggle="modal"
            data-bs-target="#sceneModal"
            id="openSceneModal"
          >
            Add
          </button>
        </div>
        <div class="px-3 pt-4 rounded x-card-1 w-100" style="min-height: 350px">
          <div
            v-for="(scene, i) in scenes"
            :key="i"
            :class="[
              'ms-3 mb-3 x-card-1 d-flex align-items-center position-relative rounded px-3 py-2 bg-light s-shadow',
              { 'card-active': selectedSceneIndex === i },
            ]"
            style="left: -33px; width: 100%"
          >
            <img :src="scene.url" alt="" />
            <span>{{ scene.name }}</span>
            <div
              class="position-absolute d-flex menuCont"
              style="
                right: -7px;
                top: -13px;
                flex-direction: column;
                z-index: 6;
              "
            >
              <button
                @click="showMenu('#nav_' + i)"
                class="navbar-toggle dropdown-toggle btn"
              ></button>
              <div class="navbar2" :id="'nav_' + i">
                <ul class="navbar2-nav">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="selectScene(scene, i, '#nav_' + i)"
                      href="#"
                      >View</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      @click="editScene(scene, i, '#nav_' + i)"
                      >Edit</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      @click="deleteScene(scene, '#nav_' + i)"
                      >Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 position-relative">
        <div class="d-flex justify-content-between mb-3 w-100">
          <h5>Hotspot</h5>
          <button
            class="btn s-shadow s-bg2 text-white"
            data-bs-toggle="modal"
            data-bs-target="#hotspotModal"
            id="openHotspotModal"
            :disabled="disableHotspotButton"
          >
            Add
          </button>
        </div>
        <div class="px-3 pt-4 rounded x-card-1 w-100" style="min-height: 350px">
          <div
            :class="[
              'x-card-1 mb-3 align-items-center position-relative rounded px-3 py-2 bg-white s-shadow s-bg2 ms-3',
              { 'card-active': selectedHotspotIndex === x },
            ]"
            style="left: -33px; width: 100%"
            v-for="(hotspot, x) in selectedScene?.hotspots"
            :key="'x' + x"
          >
            <p class="my-1">Name: {{ hotspot.name }}</p>
            <p class="my-1">Position-X: {{ hotspot.position_x }}</p>
            <p class="my-1">Position-Y: {{ hotspot.position_y }}</p>
            <div
              class="position-absolute d-flex menuCont"
              style="
                right: -7px;
                top: -13px;
                z-index: 6;
                flex-direction: column;
              "
            >
              <button
                @click="showMenu('#hot_' + x)"
                class="navbar-toggle dropdown-toggle btn"
              ></button>
              <nav :id="'hot_' + x" class="navbar2">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click="selectHotspot(hotspot, x, '#hot_' + x)"
                      href="#"
                      >View</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      @click="editHotspot(hotspot, x, '#hot_' + x)"
                      >Edit</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      @click="deleteHotspot(hotspot, x, '#hot_' + x)"
                      >Delete</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 position-relative">
        <div class="d-flex justify-content-between mb-3 w-100">
          <h5>Actions</h5>
          <button
            class="btn s-shadow s-bg2 text-white"
            data-bs-toggle="modal"
            data-bs-target="#actionModal"
            id="openActionModal"
            :disabled="disableActionButton"
          >
            Add
          </button>
        </div>
        <div class="px-3 pt-4 rounded x-card-1 w-100" style="min-height: 350px">
          <div
            class="x-card-1 align-items-center position-relative mb-3 rounded px-3 py-2 bg-white s-shadow s-bg2"
            style="left: -18px; width: 100%"
            v-for="(action, y) in selectedHotspot.actions"
            :key="y"
          >
            <p class="my-1">Type: {{ action.type }}</p>
            <p class="my-1">Data: {{ action.data }}</p>
            <!-- <p>Description: {{ action.description }}</p> -->
            <div
              class="position-absolute d-flex menuCont"
              style="
                right: -7px;
                top: -13px;
                flex-direction: column;
                z-index: 6;
              "
            >
              <button
                class="navbar-toggle dropdown-toggle btn menuBtn"
                @click="showMenu('#act_' + y)"
              ></button>
              <nav :id="'act_' + y" class="navbar2">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" href="#">View</a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      @click="editAction(action, y, '#act_' + y)"
                      >Edit</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      href="#"
                      @click="deleteAction(action, y, '#act_' + y)"
                      >Delete</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   <VirtualTour :scenes="tours" :sceneNum="0" /> -->
  </div>
  <!-- add scene Modal -->
  <div
    class="modal fade"
    id="sceneModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xlg"
      style="min-width: 800px"
    >
      <div style="min-width: 800px" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">New Scenes</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeSceneModal"
          ></button>
        </div>
        <div class="modal-body px-5">
          <div
            class="d-flex flex-column align-items-center justify-content-between"
          >
            <div class="inputGroup">
              <label for="otherName">Name</label>
              <input id="OtherName" type="text" v-model="scene.name" />
            </div>
            <div class="inputGroup">
              <label for="phone">Description</label>
              <textarea
                name="scene_description"
                id="scene_description"
                cols="30"
                rows="10"
                v-model="scene.description"
              ></textarea>
            </div>
            <div class="inputGroup mb-5">
              <label>Upload Image</label>
              <div
                style="
                  height: 128px !important;
                  border: 1px dashed #4d5056;
                  border-radius: 12px;
                "
                class="px-3 d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <div>
                    <img
                      style="width: 80px; height: 80px; border-radius: 12px"
                      class="me-3"
                      id="upload-img"
                      :src="
                        scene.url
                          ? scene.url
                          : require('@/assets/images/users-and-roles/uploadImg.svg')
                      "
                      alt=""
                    />
                  </div>
                  <div class="d-flex m-auto flex-column align-items-start">
                    <p class="m-0 mb-2">170px by 170px . Max 5MB</p>
                    <p m-0>High-resolution images (png, jpg)</p>
                  </div>
                </div>
                <div>
                  <form className="">
                    <label className="custom-file-upload" id="fileScene">
                      <input
                        accept=".jpg, .jpeg, .png"
                        @change="showSceneFile"
                        type="file"
                        id="file-input"
                      />
                      Browse
                    </label>
                  </form>
                  <button @click="openSceneFileInput" class="border py-2 px-5">
                    Browse
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn s-shadow s-bg2 text-white"
            @click="saveScene"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- add hotspot Modal -->
  <div
    class="modal fade"
    id="hotspotModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xlg"
      style="min-width: 500px"
    >
      <div style="min-width: 500px" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">New Hotspot</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeHotspotModal"
          ></button>
        </div>
        <div class="modal-body px-5">
          <div
            class="d-flex flex-column align-items-center justify-content-between"
          >
            <div class="inputGroup">
              <label for="otherName">Name</label>
              <input id="hotspotName" type="text" v-model="hotspot.name" />
            </div>
            <div class="inputGroup">
              <label for="hotspotPositionX">Position X</label>
              <select class="form-control"
              id="hotspotPositionX"
              v-model="hotspot.position_x"
              >
                <option>left</option>
                <option>right</option>
                <option>center</option>
              </select>              
            </div>
            <div class="inputGroup">
              <label for="hotspotPositionY">Position Y</label>
              <select class="form-control"
              id="hotspotPositionY"
                v-model="hotspot.position_y"
              >
                <option>top</option>
                <option>bottom</option>
                <option>center</option>
              </select>                          
            </div>
          </div>
        </div>
        <div class="modal-footer mt-2">
          <button
            type="button"
            class="btn s-shadow s-bg2 text-white"
            @click="saveHotspot"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- add action Modal -->
  <div
    class="modal fade"
    id="actionModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-xlg"
      style="min-width: 500px"
    >
      <div style="min-width: 500px" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">New Action</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeActionModal"
          ></button>
        </div>
        <div class="modal-body px-5">
          <div
            class="d-flex flex-column align-items-center justify-content-between"
          >
            <div class="inputGroup">
              <label for="type">Type</label>
              <select
                class="form-select"
                id="inputGroupSelect03"
                aria-label="Example select with button addon"
                v-model="action.type"
              >
                <option value="" disabled>Select Type</option>
                <option value="DISPLAY_INFORMATION">DISPLAY INFORMATION</option>
                <option value="PLAY_VIDEO">PLAY VIDEO</option>
                <option value="NAVIGATE_TO_SCENE">NAVIGATE TO SCENE</option>
              </select>
            </div> 
            <div  class="inputGroup">
              <label for="actionData">Data</label>
              <input v-if="action.type !=='NAVIGATE_TO_SCENE'" id="actionData" type="text" v-model="action.data" />
                    <select v-else v-model="action.data" class="form-select">
                      <option value="" disabled>Select Scene</option>
                      <option v-for="(scene,i) in scenes" :key="i" :value="scene.id">{{ scene.name }}</option>
                    </select>
            </div>
            <div class="inputGroup">
              <label for="actionDescription">Description</label>
              <input
                id="actionDescription"
                type="text"
                v-model="action.description"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer mt-2">
          <button
            type="button"
            class="btn s-shadow s-bg2 text-white"
            @click="saveAction"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import VirtualTour from "@/components/VirtualTour.vue";
/* defineProps(['university_id']) */
import { post, postFormData, get } from "@/api/client";
import jquery from "jquery";
import { showModal } from "@/plugins/modal";

const $ = jquery;
export default {
  data() {
    return {
      selectValue: "",
      scene: {},
      hotspot: {},
      action: {},
      scenes: [],
      selectedScene: {},
      selectedSceneIndex: '',
      selectedHotspot: {
        name:'',
        position_x:'',
        position_y:'',        
      },
      selectedHotspotIndex: '',
      selectedAction: {},
      selectedActionIndex: "",
      editSceneIndex: null,
      editActionIndex: null,
      editHotspotIndex: null,
    };
  },
  methods: {
    async showSceneFile(e) {
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folder_name", "virtualTours");
        const res = await postFormData("file_upload", formData, "admin");
        this.scene.url = res;
        this.scene.file_type = file.type.split("/")[0];
      }
    },
    openSceneFileInput() {
      document.getElementById("fileScene").click();
    },
    async saveScene() {
      this.scene.university_id = this.$attrs.university_id;
      let res;
      if (this.scene.id) {
        let hotspots
        if (this.scene?.hotspots) {
          hotspots = JSON.parse(JSON.stringify(this.scene?.hotspots));
        }
        res = await post("virtual_tours/update_scene", this.scene, "admin");
        if(!res?.message) return false
        this.scenes[this.editSceneIndex] = res.data;
        this.scenes[this.editSceneIndex].hotspots = hotspots;
        this.editSceneIndex = null;        
      } else {
        res = await post("virtual_tours/store_scene", this.scene, "admin");
        this.scenes.push(res.data);        
      }
      this.scene = {};
      document.getElementById("closeSceneModal").click();
      showModal({ text: res?.message })
    },
    async saveHotspot() {
      this.hotspot.scene_id = this.selectedScene.id;
      let res;
      if (this.hotspot.id) {
        let actions 
        if (this.hotspot?.actions) {
          actions = JSON.parse(JSON.stringify(this.hotspot?.actions));
        }
        res = await post(
          "virtual_tours/update_hotspot",
          this.hotspot,
          "admin"
        );
        if(!res?.message) return false
        this.scenes[this.selectedSceneIndex].hotspots[this.editHotspotIndex] =
          res.data;
        this.scenes[this.selectedSceneIndex].hotspots[
          this.editHotspotIndex
        ].actions = actions;
        this.editHotspotIndex = null;
      } else {
        res = await post(
          "virtual_tours/store_hotspot",
          this.hotspot,
          "admin"
        );
        if(!res?.message) return false
        if (this.scenes[this.selectedSceneIndex].hotspots) {
          this.scenes[this.selectedSceneIndex].hotspots.push(res.data);
        } else {
          this.scenes[this.selectedSceneIndex].hotspots = [].push(res.data);
          this.scenes[this.selectedSceneIndex].hotspots.push(res.data);
        }        
      }
      this.hotspot = {};
      console.log(res);
      document.getElementById("closeHotspotModal").click();
      showModal({ text: res?.message })
    },
    async saveAction() {
      this.action.hotspot_id = this.selectedHotspot.id;
      let res
      if (this.action.id) {
        res = await post(
          "virtual_tours/update_action",
          this.action,
          "admin"
        );
        if(!res?.message) return false
        this.scenes[this.selectedSceneIndex].hotspots[
          this.selectedHotspotIndex
        ].actions[this.editActionIndex] = res.data;
        this.editActionIndex = null;
      } else {
        res = await post(
          "virtual_tours/store_action",
          this.action,
          "admin"
        );        
        if(!res?.message) return false
        if (this.scenes[this.selectedSceneIndex].hotspots[
          this.selectedHotspotIndex
        ].actions) {          
          this.scenes[this.selectedSceneIndex].hotspots[
            this.selectedHotspotIndex
          ].actions.push(res.data)
        } else {          
          this.scenes[this.selectedSceneIndex].hotspots[
            this.selectedHotspotIndex
          ].actions = []
          this.scenes[this.selectedSceneIndex].hotspots[
            this.selectedHotspotIndex
          ].actions.push(res.data)
        }       
      }
      this.action = {};
      document.getElementById("closeActionModal").click();
      showModal({ text: res?.message })
    },  
    selectHotspot(hotspot, index, id) {
      $(id).hide();
      (this.selectedHotspot = hotspot), (this.selectedHotspotIndex = index);
    },
    selectScene(scene, index, id) {
      $(id).hide();
      (this.selectedScene = scene), (this.selectedSceneIndex = index);
    },
    selectAction(action, index, id) {
      $(id).hide();
      (this.selectedAction = action), (this.selectedActionIndex = index);
    },
    showMenu(id) {
      $(id).toggle();
    },
    editScene(scene, index, id) {
      this.scene = scene;
      this.editSceneIndex = index;
      this.showMenu(id);
      $("#openSceneModal").click();
    },
    async deleteScene(scene, id) {
      this.showMenu(id);
      let res = await post("virtual_tours/delete_scene", { id: scene.id }, "admin");
      if (res.data.message) {
        this.scenes = await get(
          `virtual_tours/${this.$attrs.university_id}`,
          "admin"
        );
        showModal({ text: res.data.message })
      }
      
    },
    editHotspot(hotspot, index, id) {
      this.hotspot = hotspot;
      this.editHotspotIndex = index;
      this.showMenu(id);
      $("#openHotspotModal").click();
    },
    async deleteHotspot(hotspot, index, id) {
      this.showMenu(id);
      let res = await post("virtual_tours/delete_hotspot", { id: hotspot.id }, "admin");
      if (res.data.message) {
       this.scenes[this.selectedSceneIndex].hotspots.splice(index, 1);
        showModal({ text: res.data.message })
      }
    },
    editAction(action, index, id) {
      this.action = action;
      this.editActionIndex = index;
      this.showMenu(id);
      $("#openActionModal").click();
    },
    async deleteAction(action, index, id) {
      this.showMenu(id);
      let res = await post("virtual_tours/delete_action", { id: action.id }, "admin");
      if (res.data.message) {
        this.scenes[this.selectedSceneIndex].hotspots[
          this.selectedHotspotIndex
        ].actions.splice(index, 1);
       showModal({ text: res.data.message }) 
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      /*   const menu = $(".menuCont");
        $(document).click(function () {
            $(".navbar2").hide();
          })
          .not(menu);  */
    });
  },
  async created() {
    this.scenes = await get(
      `virtual_tours/${this.$attrs.university_id}`,
      "admin"
    );
  },
  computed: {
    disableHotspotButton() {
      if (this.selectedScene.id == null) return true;
      return false;
    },
    disableActionButton() {
      if (this.selectedHotspot.id == null) return true;
      return false;
    },
  },
};
</script>
<style scoped>
.s-shadow {
  box-shadow: #0001 -4px 4px 6px -2px;
}

.input-border {
  border: 1px solid #ee930a;
}

.s-bg {
  background: #ee930a;
}

.s-bg2 {
  background: #ee930a;
}

.s-bg {
  background: #ee930a !important;
}

.x-card-1 {
  border: 2px solid #ee930a;
  padding: 4px 5px;
}

.card-active,
.card-active p,
.card-active button {
  background: #ee930a !important;
  color: white !important;
}

.x-card-1 img {
  width: 50px;
  height: 50px;
  margin-right: 7px;
}

.x-card-active {
  color: white;
  background: "#EE930A";
}

.x-pin {
  width: 25px;
  height: 15px;
  background: "#EE930A";
  color: white;
  box-shadow: #0002 4px -4px 0px 8px;
  transition: all 0.3s;
}

.btn-action {
  line-height: 1;
  margin: 4px 0px;
  padding: 1px 15px;
}

.navbar2 {
  background-color: white;
  box-shadow: #0006 -4px 4px 8px -4px;
  border-radius: 4px;
  padding: 10px;
  display: none;
}

.navbar-toggle {
  font-size: 1.4em;
  color: #ee930a;
  padding: 0px;
  margin-right: 10px;
  align-self: end;
  margin-top: 5px;
}

.navbar {
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  padding: 7px 14px;
}

.nav-link {
  color: #333;
  text-decoration: none;
  padding: 5px 10px;
}

.nav-link:hover {
  background-color: #e9ecef;
}

.menuBtn {
  transition: all 0.05s;
}

.menuBtn:focus {
  border: 1px solid transparent !important;
  transform: scale(0.6);
}

.inputGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1.5rem;
}

label {
  font-weight: 600;
  margin-bottom: 4px;
}

input {
  border: 0.5px solid #000000;
  border-radius: 4px;
  height: 40px;
  padding-left: 0.5rem;
}

.btn:hover {
  background-color: #ee930aa1;
}
</style>
