<template>
  <div>
    <div class="skeletal-loader" v-for="r in row" :key="r">
      <swiper :modules="autoplay"  :slides-per-view="slidesPerView" :space-between="10" :loop="true">
          <swiper-slide   v-for="x in col" :key="x" style="display: inline-block;">    
            <div class="skeletal-loader-item"></div>
          </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
  <script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Pagination, Autoplay } from "swiper/modules";
  export default {
    name: 'SkeletalLoader',
    components:{  Swiper,SwiperSlide },
    data() {
      return {
        modules: [Pagination],
        autoplay: [Autoplay],     
      };
    },
    computed:{
      slidesPerView() {      
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 1024) {
          return 4;
        } else if (viewportWidth >= 768) {        
          return 3;
        } else {        
          return 1;
        }
      },
    },
    props: {
      col: {
        type: Number,
        default: function () {
          return 3;
        }
      },
      row: {
        type: Number,
        default: function () {
          return 1;
        }
      },
      w: {
        type: String,
        default: function () {
          return '100px';
        }
      },
      h: {
        type: String,
        default: function () {
          return '20px';
        }
      },
      m: {
        type: String,
        default: function () {
          return '10px';
        }
      }

    }
    // Component properties, methods, etc.
  }
  </script>
  
  <style>
  .skeletal-loader {    
    align-items: center;
    width:100%;
  }
  
  .skeletal-loader-item {
    width: v-bind(w);
    height: v-bind(h);
    background-color: #e7e7e7;
    margin-right: v-bind(m);
    animation: skeleton-loading 0.7s ease-in-out infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.2;
    }
  }
  </style>
  