<template>    
    <div class="row w-90 mx-auto">
        <!-- <div class="container-fluid h-100 p-0" style="background-color: aqua"> -->
        <div class="px-4 py-3">
            <h3 class="fw-bold">Update Country Cover Photo</h3>
            <div class="row col-12 mt-4">
                <div class="col-6">
                    <label style="font-weight: 600 !important" class="mb-1">Select Country<span
                            style="color: red">*</span></label>
                    <DropDown v-model="selectedCountry"
                        :options="countries" optionLabel="name"  placeholder="" filter
                        class="w-100 required"  />                        
                </div>
                <div style=" height: 128px !important;
                        border: 1px dashed #4d5056;
                        margin-top:20px;
                        border-radius: 12px;
                        " class="px-3 d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <img style="width: 80px; height: 80px; border-radius: 12px" class="me-3" id="upload-img"
                                :src="selectedCountry?.cover_photo
                                        ? selectedCountry?.cover_photo
                                        : require('@/assets/images/universities-list/upload-svg.svg')
                                    " alt="" />
                        </div>
                        <div class="d-flex m-auto flex-column align-items-start">                    
                            <p style="margin: 0; color: rgb(77, 80, 86) !important">
                                High-resolution images (png, jpg)
                            </p>
                        </div>
                    </div>
                    <div>
                        <form className="">
                            <label className="custom-file-upload" id="file">
                                <input accept=".jpg, .jpeg, .png" @change="showFile" type="file" id="file-input" />
                                Browse
                            </label>
                        </form>
                        <button @click="openFileInput" class="border py-2 px-5">
                            Browse
                        </button>
                    </div>
                </div>
                <button class="w-50 mx-auto my-5 btn btn-secondary" @click="updateNow">Update Cover Photo</button>
            </div>
        </div>
    </div>
</template>
<script>
import { get, postFormData, post } from "@/api/client";
import { showModal } from '@/plugins/modal';
export default {
    name: "CountryPage",
    data() {
        return {
            countries: [],
            selectedCountry: {},
        };
    },
    methods: {
        openFileInput() {
      document.getElementById("file").click();
    },
        async showFile(e) {
            const file = e.target.files[0];
            if (file) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("folder_name", "CountryPhoto");
                const res = await postFormData("file_upload", formData, "admin");
                this.selectedCountry.cover_photo = res;                           
            }
        },
        async updateNow() {            
            if(!this.selectedCountry?.id) {
                showModal({text:'select a country'}) 
                return false;
            }
            if(this.selectedCountry?.cover_photo == '') {
                showModal({text:'select a picture'}) 
                return false;
            }
            const res = await post("update_country_cover_picture",{id:this.selectedCountry.id,cover_photo:this.selectedCountry.cover_photo}, "admin", false);
            if(res?.message){
                showModal({text:res?.message})
            }            
        },
    },
    async created() {
        const response = await get("countries", "api", false);
        this.countries = response;
    },
};
</script>