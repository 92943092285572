<template>
  <div>
    <div>
      <!-- Header -->
      <div class="row">
        <div class="col-md-8">
          <h1 v-if="!selectedUser?.id">Creating A User</h1>
          <h1 v-else>Updating A User</h1>
        </div>
        <div class="col-md-4">
          <button class="btn btn-dark text-white" @click="resetSelectedUser">
            New User
          </button>
        </div>
      </div>
    </div>
    <hr style="opacity: 1 !important; border: 1px solid black" />
    <div class="row">
      <div class="col-md-4">
        <div
          class=""
          style="
            min-height: 50%;
            border-radius: 4px;
            /* border: 1px solid rgb(34 46 63); */
            box-shadow: #dedbdb 4px 9px 20px 0px;
            width: 100%;
          "
        >
          <input type="text" placeholder="search user" class="m-3" />
          <div>
            <ul class="pane">
              <li
                v-for="(user, i) in users.data"
                :key="i"
                tabindex="1"
                @click="selectUser(user, i)"
                :class="{ 'active-user': selectedUser?.id === user.id }"
              >
                {{ user.first_name }} {{ user.email }}
              </li>
            </ul>
          </div>
          <Pagination
            class="mt-5"
            :paginationData="users"
            @onFetch="onFetchFunc($event)"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="d-flex align-items-center justify-content-between">
          <!-- First Lıne Inputs -->
          <div class="inputGroup">
            <label for="FirstName">First Name</label>
            <input
              id="firstName"
              type="text"
              v-model="selectedUser.first_name"
            />
          </div>
          <div class="inputGroup">
            <label for="lastName">Last Name</label>
            <input id="lastName" type="text" v-model="selectedUser.last_name" />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <!-- Second Lıne Inputs -->
          <div class="inputGroup">
            <label for="otherName">Other Name</label>
            <input
              id="OtherName"
              type="text"
              v-model="selectedUser.other_name"
            />
          </div>
          <div class="inputGroup">
            <label for="phone">Phone Number</label>
            <input id="phone" type="text" v-model="selectedUser.phone" />
          </div>
        </div>
        <div class="d-flex align-items-end justify-content-between">
          <!-- Third Line Inputs -->
          <div class="inputGroup">
            <label for="email">Email (Required)*</label>
            <input
              id="email"
              type="text"
              placeholder="Enter The Email"
              v-model="selectedUser.email"
            />
          </div>

          <div class="inputGroup" v-if="selectedUser?.id">
            <label for="password">Password</label>
            <input
              id="password"
              type="text"
              placeholder="Enter The password"
              v-model="selectedUser.password"
            />
          </div>
          <div class="inputGroup" v-else>
            <label for="unique_id">Unique ID(Required)*</label>
            <input
              id="email"
              type="text"
              placeholder="Enter Your Unique Id"
              v-model="selectedUser.unique_id"
            />
          </div>
        </div>
        <div style="margin-top: 2rem">
          <label>Upload Photo</label>
          <div
            style="
              height: 128px !important;
              border: 1px dashed #4d5056;
              border-radius: 12px;
            "
            class="px-3 d-flex align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <div>
                <img
                  style="width: 80px; height: 80px; border-radius: 12px"
                  class="me-3"
                  id="upload-img"
                  :src="
                    selectedUser.profile_photo_url
                      ? selectedUser.profile_photo_url
                      : require('@/assets/images/users-and-roles/uploadImg.svg')
                  "
                  alt=""
                />
              </div>
              <div class="d-flex m-auto flex-column align-items-start">
                <p class="m-0 mb-2">170px by 170px . Max 5MB</p>
                <p m-0>High-resolution images (png, jpg)</p>
              </div>
            </div>
            <div>
              <form className="">
                <label className="custom-file-upload" id="file">
                  <input
                    accept=".jpg, .jpeg, .png"
                    @change="showFile"
                    type="file"
                    id="file-input"
                  />
                  Browse
                </label>
              </form>
              <button @click="openFileInput" class="border py-2 px-5">
                Browse
              </button>
            </div>
          </div>
        </div>
        <div style="margin-top: 2rem">
          <!-- Notification Check -->
          <label style="margin: 0; margin-bottom: 12px" class=""
            >Send User Notification</label
          >
          <div class="d-flex align-items-center">
            <p class="m-0">Send the new user an email about their account.</p>
            <input
              style="accent-color: #fac515; width: 5rem; color: red"
              class="ms-4"
              type="checkbox"
              v-model="selectedUser.send_email"
            />
          </div>
          <hr style="margin-top: 30px" />
        </div>
        <div style="margin-top: 2rem">
          <!-- Role Select -->
          <label>Assign Role</label>
          <p>
            The user will have access to the selected roles. You can selected
            multiple roles, and also restrict the permissions from the role
            page.
          </p>

          <div class="d-flex flex-column">
            <div class="inputGroup">
              <label for="roles">Roles</label>
              <MultiSelect
                v-model="selectedUser.roles"
                :options="roles"
                optionLabel="name"
                :multiple="true"
                :showHeader="false"
                placeholder="Select Roles"
                @change="addToUserPermission"
              />
            </div>
            <div class="inputGroup ms-0">
              <label for="permissions">Permissions</label>
              <MultiSelect
                v-model="selectedUser.permissions"
                :options="permissions"
                :optionLabel="transformedLabel"
                optionValue="name"
                :multiple="true"
                :showHeader="false"
                placeholder="Select Permissions"
              />
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-end"
        >
          <button
            type="submit"
            @click="save()"
            style="background-color: rgba(234, 236, 240, 1)"
            class="border px-5 py-2 mt-4"
          >
            {{ selectedUser.id ? "Update" : "Create" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Global/Pagination.vue";
import { get, post, postFormData } from "@/api/client";
import MultiSelect from "primevue/multiselect";
import { showModal } from "@/plugins/modal";
export default {
  name: "CreateUser",
  components: { MultiSelect, Pagination },
  data() {
    return {
      roles: [],
      selectedUserIndex: null,
      selectedUser: {
        first_name: "",
        last_name: "",
        other_name: "",
        phone: "",
        unique_id: "",
        profile_photo_url:
          "",
        email: "",
        roles: [],
        permissions: [],
        send_email: true,
      },
      users: [],
      permissions: [],
      modalProps: {
        response: "The account has been created successfully!!!",
        image: "sent-success-icon.svg",
        heading: "Created Successfully",
      },
    };
  },
  methods: {
    transformedLabel(data) {
      return data.name.replaceAll('_', ' ');
    },
    addToUserPermission() {
      this.selectedUser.permissions = [...new Set(this.selectedUser.roles.flatMap((role) => {
        return role.permissions?.map((permission) => permission.name) || [];
      }))]
    },
    selectUser(user, index) {
      this.selectedUser = JSON.parse(JSON.stringify(user));
      this.selectedUser.permissions = [...new Set(this.selectedUser.all_permissions.map((permission) => permission.name))]
      this.selectedUserIndex = index
    },
    resetSelectedUser() {
      this.selectedUser = {
        first_name: "",
        last_name: "",
        email: "",
        roles: [],
        permissions: [],
        send_email: true,
      };
    },
    onFetchFunc(data) {
      this.users = data;
    },
    async save() {
      let payload = JSON.parse(JSON.stringify(this.selectedUser))
      let res;
      payload.roles = payload.roles.map((role) => role.name)
      if (this.selectedUser?.id) {
        res = await post("users/update", payload, "admin")
        if (res?.message) this.users.data[this.selectedUserIndex] = res.data
      } else {
        res = await post("users", payload, "admin");
        if (res?.message) this.users.data.unshift(res.data)
        this.resetSelectedUser();
      }
      if (res?.message) {
        showModal({text: res?.message})
      }
    },
    async showFile(e) {
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folder_name", "adminUsers");
        const res = await postFormData("file_upload", formData, "admin");
        this.selectedUser.profile_photo_url = res;
      }
    },
    openFileInput() {
      document.getElementById("file").click();
    },
    async fetchAllUsers(){
      this.users = await get("users", "admin");
    }
  },
  async created() {
    await this.fetchAllUsers()
    let b = await get("roles", "admin");
    let c = await get("permissions", "admin");
    this.roles = b.message;
    this.permissions = c.message;
  },
};
</script>

<style scoped>
.inputGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1.5rem;
}
.inputGroup:nth-of-type(2) {
  margin-left: 2rem;
}

label {
  font-weight: 600;
  margin-bottom: 4px;
}
input {
  border: 0.5px solid #000000;
  border-radius: 4px;
  height: 40px;
  padding-left: 0.5rem;
}
input[type="checkbox"] {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 15% !important;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  box-shadow: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none !important;
  border: 1px solid black !important;

  padding: 0;
  padding: 12px !important;
}

input[type="checkbox"]:checked {
  background: #fac515;
  border: 1px solid transparent !important;
}
input[type="checkbox"]:checked:after {
  content: "✔";

  color: white;
}
.pane li.active-user {
  padding: 5px 10px 3px 30px;
  font-weight: bolder;
}
.pane li {
  cursor: pointer;
  list-style: none;
  color: rgba(34, 46, 63, 0.9);
  padding: 5px 10px 3px 20px;
  border-bottom: 1px solid rgba(34, 46, 63, 0.2);
  background: white;
  transition: all 0.3s;
}

.pane li:hover {
  background: rgba(34, 46, 63, 0.7);
  color: white;
}
</style>
