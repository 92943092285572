<template>
  <div class="container h-auto px-0 py-3">    
    <div class="applications-main">
      <div class="mb-5 mx-5">        
        <div class="d-flex justify-content between">          
          <button class="btn btn-secondary" @click="downloadFilesAsZip">Download docuemnts</button>
        </div>
        <hr />
      </div>
      <div class="container">
        <div>
          <div class="row d-flex flex-row">
            <div class="col-lg-8">
              <div
                class="row d-flex flex-row"
                v-for="(name, i) in Object.keys(data)"
                :key="i"
              >
                <div class="col-1 m-6">
                  <div class="m-2">
                    <img
                      class="img-size"
                      v-if="fileType(data[name]) === 'pdf'"
                      :src="require('/src/assets/images/pdf.png')"
                      alt=""
                    />
                    <img
                      class="img-size"
                      v-if="fileType(data[name]) === 'image'"
                      :src="data[name]"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-6 m-2" style="padding-left: 60px">
                  <h6 class="m-1">{{ documents[name] || "others" }}</h6>
                  <p class="m-1 text-secondary text-xs">
                    <!-- {{doc.size}} -->
                  </p>
                  <!-- <p class="m-0 text-secondary text-xs">
                    {{doc.date}}
                  </p> -->
                </div>
                <div class="col-1" style="padding-left: 10px">
                  <button
                    @click="viewDocument(data[name])"
                    class="view-btn btn text-dark m-3 px-2 py-2 d-flex"
                  >
                    <img
                      class="me-2"
                      :src="require('/src/assets/images/d-board/view.svg')"
                      alt=""
                    />
                    view
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="application-review p-3">
                <h6 class="text-center my-3">Program Application Review</h6>
                <div class="form-group sub-input sub-input-large">
                  <div class="form-group dropdown">
                    <Dropdown
                      style="border-radius: 10px"
                      v-model="selectedReview"
                      :options="AppliactionReview"
                      optionLabel=""
                      placeholder="Waiting  Review"
                      class="w-100"
                      :default="AppliactionReview[0]"
                    />
                  </div>

                  <div class="form-group">
                    <label class="label-text">Comments</label>
                    <textarea
                      v-model="comment"
                      class="form-control"
                      placeholder="Enter a comment..."
                      :autoResize="true"
                      rows="4"
                      cols="17"
                    ></textarea>
                  </div>

                  <div class="btn-container">
                    <button
                      @click="saveReview()"
                      class="save-next-btn ripple text-dark"
                    >
                      Save and Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showModal">
          <div class="modal-overlay" @click="closeModal"></div>
          <div class="modal-custom">
            <div class="modal-header">
              <button class="modal-close" @click="closeModal">X</button>
            </div>
            <div
              class="modal-body d-flex justify-content-center align-items-center"
              style="overflow: auto"
            >
              <iframe v-if="docType != 'image'" :src="doc"></iframe>
              <img v-else :src="doc" style="display: block" id="imageViewer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showModal } from "@/plugins/modal";
import Dropdown from "primevue/dropdown";
import { post } from "@/api/client";
import JSZip from 'jszip';
import { useUserStore } from '@/stores/user';
export default {
  name: "StudentDocument",
  components: { Dropdown },
  props: {
    data: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      showModal: false,
      stuForm: false,
      selectedReview: this.student?.application_status,
      AppliactionReview: ["pending", "rejected", "approved"],
      comment: this.student?.comment,
      loading:false,
      documents: {
        highschool_marksheet: "High School Transcript (Final Grade)",
        highschool_certificate: "High School Diploma/Certificate",
        formal_photo: "Formal Photo",
        local_id_card: "Local ID Card (Front and Back)",
        passport_front: "Passport (Front)",
      },
      studentData:null
    };
  },
  methods: {
    downloadFilesAsZip() {
      if(Object.keys(this.data)?.length <1){
        return false
      }
      const store = useUserStore()
      store.isLoading =true
      const zip = new JSZip();

      // Function to fetch each file and add it to the zip archive
      const addFileToZip = (url,file_name) => {
        return fetch(url)
          .then((response) => response.blob())
          .then((blob) => {            
            zip.file(file_name, blob);
          });
      };

      // Create a promise for each file to be added to the zip archive
      const filePromises = Object.keys(this.data).map((key) => addFileToZip(this.data[key],this.documents[key]));

      // Wait for all promises to resolve
      Promise.all(filePromises)
        .then(() => {
          // Generate the zip file
          return zip.generateAsync({ type: "blob" });
        })
        .then((content) => {
          // Trigger the download of the zip file
          const zipFileName = "files.zip";
          const link = document.createElement("a");
          link.href = URL.createObjectURL(content);
          link.download = zipFileName;
          store.isLoading =false 
          link.click();
        });
    },

    viewDocument(doc) {
      this.showModal = true;
      this.docType = this.fileType(doc);
      this.doc = doc;
      if (this.docType == "image") {
        this.autoResizeImage();
      }
    },
    fileType(url) {
      const PDfExtensions = [".pdf"];
      let extension = url.substr(url.lastIndexOf(".")).toLowerCase();
      if (PDfExtensions.includes(extension)) {
        return "pdf";
      }

      const videoExtensions = [".mp4", ".avi", ".mov", ".wmv"];
      extension = url.substr(url.lastIndexOf(".")).toLowerCase();
      if (videoExtensions.includes(extension)) {
        return "video";
      }
      const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
      extension = url.substr(url.lastIndexOf(".")).toLowerCase();
      if (imageExtensions.includes(extension)) {
        return "image";
      }
    },
    closeModal() {
      this.showModal = false;
      this.doc = "";
    },

    autoResizeImage() {
      // Get the image element
      const image = document.getElementById("imageViewer");
      image.onload = () => {
        // Get the current width and height
        const currentWidth = image.width;
        const currentHeight = image.height;

        // Calculate the new width and height with 10% reduction
        const newWidth = currentWidth * 0.9;
        const newHeight = currentHeight * 0.9;

        // Set the new width and height
        image.style.width = newWidth + "px";
        image.style.height = newHeight + "px";
      };
    },
    async saveReview() {
      if (!this.selectedReview) {
        showModal({ text: "select a status option" });
        return false;
      }

      post(
        "application/update-status",
        {
          status: this.selectedReview,
          comment: this.comment,
          student_id: this.$props.student?.id,
        },
        "admin"
      ).then((response) => {
        this.studentData.application_status = this.selectedReview
        showModal({
          text: response.data.responseBody,
          confirmText: "Ok",
        });
      });
    },
  },
  created() {   
    this.studentData = this.student 
  },
  mounted() {
    this.$nextTick(() => {
      if (this.student?.application_status) {
        this.selectedReview = this.student?.application_status;
        this.comment = this.student?.comment;
      }
    });
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 60px;
}

.application-review div {
  margin-bottom: 10px;
}

.img-size {
  border-radius: 2px;
  background-color: gray;
  height: 80px;
  width: 70px;
}

.label-text {
  font-size: 15px;
}

.btn-container {
  padding: 2px;
}

.dropdown {
}

.view-btn {
  background-color: #eaecf0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .view-btn:hover {
    color: rgb(245, 245, 245) !important;
    box-shadow: inset 150px 0 0 0 #fac515 !important;
} */

.view-btn img {
  width: 20px;
}

.save-next-btn {
  border-radius: 4px;
  width: 100%;
  padding: 0.6rem;
  border: none;
  background-color: #fac515;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.modal-custom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  border-radius: 5px;
  z-index: 11;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close {
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  height: 100%;
  background: white;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
