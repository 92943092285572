<template>
  <div class="">
    <button @click="back()" class="btn btn-light btn-sm ms-4">
      <i class="pi pi-arrow-left cursor-pointer"></i>
    </button>
    <div>
      <div class="ps-5 mb-3 d-flex align-items-center justify-content-between">
        <!-- Page Header -->
        <h1 style="font-size: 36px">Create High School Association Profile</h1>
      </div>
    </div>
    <div class="ps-5 border-bottom w-100 d-flex align-items-center">
      <div @click="changeTab(1)" style="
          color: rgb(29, 41, 57);
          cursor: pointer;
          border-bottom: 4px solid transparent;
        " class="me-5 p-3" :style="currentTab === 1 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'">
        Association Information
      </div>
      <div @click="changeTab(2)" style=" cursor: pointer;border-bottom: 4px solid transparent;color: rgb(29, 41, 57);" :style="currentTab === 2 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'" class="p-3">
        Contact Details
      </div>
      <div @click="changeTab(3)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 3 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'" class="p-3">
        Commission
      </div>
 <!--      <div @click="changeTab(4)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 4 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'" class="p-3">
        Members
      </div> -->
    </div>
    <div style="margin-top: 41px" class="md:ml-10 md:w-[650px] ">
      <div v-show="currentTab === 1">
        <AssociationInformationTab :missingFields="infoMissinFields" @validate-email="validateEmail" @onUpdateInfo="onUpdateInfoFunc($event)" />
      </div>
      <div v-show="currentTab === 2">
        <ContactDetailsTab :missingFields="contactMissinFields" :countries="countries" :isSubmitBtnClicked="isSubmitBtnClicked"
          @onUpdateContact="onUpdateContactFunc($event)" />
      </div>
      <div v-show="currentTab === 3">
        <CommissionTab type="high_school" @updateCommission="updateCommissionFunc($event)" />
      </div>
      <div v-if="currentTab !== 4" class="d-flex align-items-center justify-content-between w-80 m-auto my-4">
        <!-- Footer Buttons -->
        <button  @click="back()" v-if="currentTab == 1" class="border py-2 px-5">Cancel</button>
        <button v-else @click="changeTab(currentTab - 1)" class="border py-2 px-5">Previous</button>
        <button v-if="currentTab < 3" @click="changeTab(currentTab + 1)" class="bg-warning border py-2 px-5">
          Next
        </button>
        <button v-else @click="openConfirmModal" class="bg-warning border py-2 px-5">
          Create Profile
        </button>
      </div>
    </div>   
  </div>
  <ConfirmModal v-if="isConfirmModalOpen" @closeConfirmModal="closeConfirmModal" @createProfile="createProfile" />
</template>

<script>
import AssociationInformationTab from "@/components/Association/AssociationInformationTab.vue";
import ContactDetailsTab from "@/components/Association/ContactDetailsTab.vue";
import CommissionTab from "@/components/UniversitiesList/CommissionTab.vue";
//import AgreementTab from "@/components/UniversitiesList/AgreementTab.vue";
import ConfirmModal from "@/components/Association/ConfirmModal.vue";


import { post } from "@/api/client";
import { showModal } from "@/plugins/modal";
import { useHsStore } from "@/stores/highSchools";
import router from '@/router/index.js'
export default {
  name: "CreateAssociationProfile",

  data() {
    return {
      currentTab: 1,
      isConfirmModalOpen: false,
      isValid: false,
      isSubmitBtnClicked: false,
      updateInfo: {},
      updateContact: {},
      countries: [],
      contactMissinFields:[],
      infoMissinFields:[]
    };
  },
  methods: {
    back() {
      router.push('/highschool-associations');
    },
    onUpdateInfoFunc(data) {
      this.updateInfo = data
    },
    onUpdateContactFunc(data) {
      this.updateContact = data
    },
    updateLetterFunc(e) {
      this.updateLetters = e;
    },
    updateCommissionFunc(e) {
      this.updateCommissions = e;
    },
    validateFields(data, require = []) {
        let missingFields = [];

        require.forEach((key) => {
          if (!data[key] || data[key] === '') {
            missingFields.push(key);
          }
        });
        return missingFields.length > 0 ? missingFields : [];
    },
    changeTab(tab) {
      if (this.isValid) {
        if(tab == 2){
          this.infoMissinFields = this.validateFields(this.updateInfo,['name','type','registered_date'])
          if(this.infoMissinFields.length<1){
            this.currentTab = tab;
          }
        }else if(tab == 3){          
          this.contactMissinFields = this.validateFields(this.updateContact,['phone','site_url'])
          if(this.contactMissinFields.length<1){
            this.currentTab = tab;
          }
        }
      } else {
        showModal({ text: "Enter a valid email or a picture that is jpg or png!" });
      }
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
      this.isSubmitBtnClicked = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
    createProfile() {
      if (this.publish()) {
        // window.alert("Profile Created");
        this.isConfirmModalOpen = false;
      } else {
        // window.alert("Profile Creation Error");
        this.isConfirmModalOpen = false;
      }
    },
    validateEmail(n) {
      this.isValid = n;
    },
    removeData() {

    },
    async publish() {
      const res = await post(
        "add_association",
        {
          ...this.updateInfo,
          ...this.updateContact,         
          commission_type: this.updateCommissions.commission_type,
          commission_value: this.updateCommissions.commission_value          
        },
        "admin", false
      );
      if (res) {
        showModal({ text: res?.message }).then((c) => {
          console.log(c)
          this.$router.push('/high-schools-list');
        })
      }
    },
  },
  created() {
    this.countries = useHsStore().countries
  },
  components: {
    ContactDetailsTab,
    AssociationInformationTab,
    ConfirmModal,
    CommissionTab,    
  },
};
</script>

<style scoped></style>
