<template>
  <div
    style="width: 90%; margin: auto; margin-top: 20px"
    class="d-flex flex-column"
  >
    <h5>Paramount Students ERP system</h5>
    <p class="mt-2">
      <span class="fw-bold">1.0</span>
      Last updated February 26,2022.
    </p>
    <hr style="border-width: 2px; border-color: black" />
    <h5>Recent Login Activity</h5>
    <div
      style="box-shadow: 0px 2px 10px rgba(58, 53, 65, 0.1); border-radius: 6px"
      class="mt-3"
    >
      <div style="height: 75px" class="d-flex align-items-center ps-3">
        <h6>Recent devices</h6>
      </div>
      <div class="w-100">
        <table class="w-100">
          <tr style="height: 60px; background-color: rgba(0, 0, 0, 0.05)">
            <th class="ps-3">DEVICE</th>
            <th>IP ADDRESS</th>
            <th>LOCATION</th>
            <th>RECENT ACTIVITY</th>
          </tr>
          <tr v-for="activity in recentActivities" :key="activity.ip">
            <td>{{ activity.device }}</td>
            <td>{{ activity.ip }}</td>
            <td>{{ activity.location }}</td>
            <td>{{ activity.recentActivity }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { highSchoolRecentLoginActivity } from "@/data";
export default {
  name: "GeneralSettingsTab",
  data() {
    return {
      recentActivities: highSchoolRecentLoginActivity,
    };
  },
};
</script>

<style scoped>
tr {
  color: rgba(58, 53, 65, 0.87);
  font-size: 12px;
}
td:first-of-type {
  padding-left: 1rem;
}
td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-collapse: collapse;
}
table {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
</style>
