<template>
  <div>
    <div v-if="selectedUser.id">
      <ViewSingleUser :user="selectedUser" @closeView="selectedUser = {}" />
    </div>
    <div v-else>
      <div class="" style="margin-bottom: 38px">
        <!-- Page Header -->
        <h1 class="ms-3">Users and Roles</h1>
        <hr />
        <StatKeeper :doc-file-count="docFileCount" />
      </div>
      <div>
        <!-- User List -->
        <ul>
          <li class="listItem" style="font-weight: 600; font-size: 30px">
            All Users
          </li>
          <li v-for="user in users.data" :key="user.id" class="listItem">
            <div style="
                  position: relative;
                  display: flex;
                  align-items: center;
                  width: fit-content;
                " class="">
              <span style="
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                    bottom: 0;
                    position: absolute;
                    right: 0px;
                  " :style="
                    user.isActive
                      ? 'background-color: green '
                      : 'background-color:red'
                  "></span>
              <img style="width: 60px; height: 60px" :src="user.profile_photo_url" alt="userImg" />
            </div>
            <div style="width: 12.5%" class="d-flex flex-column">
              <span class="listItemCategoryName">User</span>
              <span class="listItemInfo">{{ user.username }}</span>
            </div>
            <div style="width: 35%; max-width: 200px; overflow: hidden" class="d-flex flex-column">
              <span class="listItemCategoryName">Email</span>
              <span class="listItemInfo">{{ user.email }}</span>
            </div>
            <div style="width: 45%; max-width: 350px; overflow: hidden" class="d-flex flex-column">
              <span class="listItemCategoryName">Role</span>
              <div>
                <span v-for="role in user.role" :key="user.role.indexOf(role)" class="listItemInfoBadge">
                  {{ role }}
                </span>
              </div>
            </div>
            <div style="width: 10%%" class="">
              <button @click="selectUser(user)" style="font-size: 14px"
                class="border p-2 px-4 d-flex align-items-center justify-content-between">
                <img class="pe-2" alt="" src="@/assets/images/d-board/studentCourses.svg" />
                View
              </button>
            </div>
          </li>
        </ul>
        <Pagination class="mt-5 mx-auto" style="width: max-content" :paginationData="users"
          @onFetch="onFetchFunc($event)" />
        <ul style="margin-top: 40px !important">
          <li class="listItem" style="font-weight: 600; font-size: 30px">
            Roles and Permissions
            <button style="color: unset; font-size: 16px; font-weight: 400"
              class="border p-2 px-4 bg-warning d-flex align-items-center justify-content-between" type="button"
              @click="showNewRoleForm = !showNewRoleForm">
              <img src="@/assets/images/highschools-list/create-highschool-profile.svg" alt="" class="me-3" />
              Roles and Permissions
            </button>
          </li>
          <li class="listItem px-4" v-if="showNewRoleForm">
            <div class="my-4">
              <!-- Role Select -->
              <label>Assign Role</label>
              <p>
                The user will have access to the selected roles. You can
                selected multiple roles, and also restrict the permissions from
                the role page.
              </p>
              <div class="d-flex flex-column">
                <div class="inputGroup">
                  <label for="role">Role</label>
                  <input id="role" type="text" v-model="newRole.name" />
                </div>
                <div class="inputGroup ms-0">
                  <label for="permissions">Permissions</label>
                  <MultiSelect style="z-index: 44" v-model="newRole.permissions" :options="permissions" :optionLabel="transformedLabel" optionValue="name"
                    :multiple="true" :showHeader="false" placeholder="Select Permissions" />
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button type="submit" @click="reset()" style="background-color: #dc3545;"
                  class="border px-5 py-2 mt-4">
                  Reset
                </button>
                <button type="submit" @click="save()" style="background-color: rgba(234, 236, 240, 1)"
                  class="border px-5 py-2 mt-4 ms-3">
                  {{ newRole.id ? "Update" : "Create" }}
                </button>
              </div>
            </div>
          </li>
          <li class="listItem px-3 d-flex align-items-center justify-content-between" v-for="(role, i) in roles" :key="i">
            <div style="width: 70%" class="d-flex align-items-center">
              <div style="width: 30%" class="d-flex flex-column align-items-start">
                <span class="listItemCategoryName">Role Name</span>
                <span class="listItemInfo">{{ role.name }}</span>
              </div>
              <div style="width: 70%" class="d-flex flex-column align-items-start">
                <span class="listItemCategoryName">Permission</span>
                <div>
                  <span class="listItemInfoBadge" v-for="(permission, i) in role.permissions" :key="i">{{ permission.name.replace(/_/g, " ")
                  }}
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center w-30">
              <div class="d-flex flex-column me-4">
                <span class="listItemCategoryName">Date Created</span>
                <span class="listItemInfo">{{ role.created_at?.split("T")[0] }}</span>
              </div>
              <div class="ms-4">
                <button style="
                      background-color: rgba(234, 236, 240, 1);
                      font-size: 14px;
                    " @click="editRole(role)" class="border p-3 px-5 d-flex align-items-center justify-content-between">
                  <i class="pi pi-pencil me-2"></i>
                  View
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import StatKeeper from "@/components/Global/StatKeeper.vue";
import Pagination from "@/components/Global/Pagination.vue";
import ViewSingleUser from "@/components/ViewSingleUser.vue";
import MultiSelect from "primevue/multiselect";
import { showModal } from "@/plugins/modal";
import { get, post } from "@/api/client";
export default {
  name: "UsersAndRoles",
  data() {
    return {
      users: {
        data: [],
      },
      selectedUser: {},
      roles: [],
      permissions: [],
      newRole: {
        name: '',
        permissions: []
      },
      showNewRoleForm: false,
    };
  },
  async created() {
    await this.fetchAllUsers();
    let b = await get("roles", "admin");
    let c = await get("permissions", "admin");
    this.roles = b.message;
    this.permissions = c.message;
  },
  methods: {
    async fetchAllUsers() {
      this.users = await get("users", "admin");
    },
    onFetchFunc(data) {
      this.users = data;
    },
    selectUser(user) {
      this.selectedUser = user;
    },
    editRole(role){
      this.newRole = JSON.parse(JSON.stringify(role));
      this.newRole.permissions = [...new Set(this.newRole.permissions.map((permission) => permission.name))]
      this.showNewRoleForm = true
    },
    reset(){
      this.newRole = {
        name: '',
        permissions: []
      }
    },
    transformedLabel(data) {
      return data.name.replaceAll('_', ' ');
    },
    async save() {
        let res = await post("make_role", this.newRole, "admin");
        let b = await get("roles", "admin");
        this.roles = b.message;
        this.reset()
      if (res?.message) {
        showModal({ text: `Role has been ${this.newRole.id ? 'updated' : 'created'}` })
      }
    },
  },
  computed: {
    activeUsers() {
      const activeUsers = this.users.data.filter((user) => user.isActive);
      return activeUsers;
    },
    docFileCount() {
      return [
        {
          id: 1,
          style:
            "border border-primary px-lg-3 p-3 py-4 rounded border-2 gradientPrimary",

          count: this.users.data.length,
          img: "arrow-1.svg",
          title: "All Users",
          // excerpt: "Number of students documents for evaluation.",
          class: "col-lg-4 col-md-6 col-xl-3 mb-3 ",
        },
        {
          id: 2,
          style:
            "border border-success px-lg-3 p-3 py-4 rounded border-2 gradientSuccess",
          count: this.activeUsers.length,
          img: "arrow-2.svg",
          title: "Active Users",
          // excerpt: "Number of students documents for evaluation.",
          class: "col-lg-4 col-md-6 col-xl-3 mb-3 ",
        },
        {
          id: 3,
          style:
            "border border-danger px-lg-3 p-3 py-4 rounded  border-2 gradientDanger",
          count: this.users.data.length - this.activeUsers.length,
          img: "arrow-3.svg",
          title: "Inactive Users",
          // excerpt: "Number of students documents for evaluation.",
          class: "col-lg-4 col-md-6 col-xl-3 mb-3 ",
        },
        {
          id: 4,
          style:
            "border border-secondary px-lg-3 p-1  rounded border-2 gradientSecondary",
          count: "04",
          img: "arrow-4.svg",
          title: "Manage Users",
          // excerpt: "Number of students documents for evaluation.",
          class: "col-lg-4 col-md-6 col-xl-3 mb-3 ",
        },
      ];
    },
  },
  components: { StatKeeper, Pagination, ViewSingleUser, MultiSelect },
};
</script>

<style scoped>
.inputGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1.5rem;
}

.inputGroup:nth-of-type(2) {
  margin-left: 2rem;
}

label {
  font-weight: 600;
  margin-bottom: 4px;
}

input {
  border: 0.5px solid #00000081;
  border-radius: 4px;
  height: 40px;
  padding-left: 0.5rem;
}

.total-information-box {
  height: 150px;
  width: 245px !important;

  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  margin: 1rem;
  box-sizing: border-box;

  font-weight: 600;
}

.total-information-box:nth-last-child(1) {
  padding: 0 !important;
}

.total-information-box p:nth-child(1) {
  font-size: 60px !important;
  margin-bottom: 0;
}

.total-information-box:nth-child(1) {
  background: linear-gradient(180deg,
      rgba(115, 117, 253, 0.2) 0%,
      rgba(115, 117, 253, 0) 100%);
  border: 2px solid rgba(115, 117, 253, 1);
}

.total-information-box:nth-child(2) {
  background: linear-gradient(180deg,
      rgba(50, 213, 131, 0.2) 0%,
      rgba(50, 213, 131, 0) 100%);
  border: 2px solid rgba(50, 213, 131, 1);
}

.total-information-box:nth-child(3) {
  background: linear-gradient(180deg,
      rgba(249, 112, 102, 0.2) 0%,
      rgba(249, 112, 102, 0) 100%);
  border: 2px solid #f97066;
}

.total-information-box:nth-child(4) {
  background: rgba(234, 236, 240, 1);
  border: 1px solid black;
}

.listItem {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 16px 0px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  padding: 0rem 1rem;
  margin-bottom: 18px;
}

.listItemCategoryName {
  font-weight: 600;
  color: rgba(102, 112, 133, 1);
}

.listItemInfo {
  font-weight: 500;
  color: rgba(29, 41, 57, 1);
}

.listItemInfoBadge {
  background-color: rgba(225, 227, 232, 1);
  border-radius: 16px;
  font-weight: 500;
  color: rgba(37, 48, 65, 1);
  padding: 5px 10px;
  display: inline-block;
  font-size: 12px;
}

.listItem .listItemInfoBadge:not(:last-of-type) {
  margin: 0px 5px 5px 0px;
}
</style>
