import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useCreateProgrammeStore = defineStore({
  id: "createProgramme",
  state: () => ({
    programDetails: useLocalStorage("programDetails", {}),
    admissionDetails: useLocalStorage("admissionDetails", {}),
    tuitionFees: useLocalStorage("tuitionFees", {}),
    academicRequirements: useLocalStorage("academicRequirements", {}),
    applicationFees: useLocalStorage("applicationFees", {}),
  }),
  persist: true,
  actions: {
    setProgramDetails(details) {
      this.programDetails = details;
    },
    setAdmissionDetails(details) {
      this.admissionDetails = details;
    },
    setTuitionFees(details) {
      this.tuitionFees = details;
    },
    setApplicationFees(details) {
      this.applicationFees = details;
    },
    removeProgram(id) {
      this.programmes = this.programmes.filter((obj) => obj.id !== id);
    },
  },
});
