<template>
  <button @click="showList()" v-show="viewHighSchool" class="btn btn-secondary">Show List</button>
  <div v-show="!viewHighSchool" class="" :key="listKey">   
    <div class="d-flex align-items-center justify-content-between">
      <!-- Page Header -->
      <h1 style="font-size: 36px">High Schools Profile List</h1>
      <router-link
        style="color: unset"
        to="/high-schools-list/create-profile"
        class="border p-2 px-4 bg-warning d-flex align-items-center justify-content-between"
      >
        <img
          src="@/assets/images/highschools-list/create-highschool-profile.svg"
          alt=""
          class="me-3"
        />
        Create High School Profile
      </router-link>
    </div>
    <hr />
    <div
      class="total-information-boxes d-flex align-items-center justify-content-between"
    >
      <!-- Total Information Boxes -->
      <div class="total-information-box">
        <img alt="" src="@/assets/images/highschools-list/new-tab-icon.svg" />
        <h2>{{ highSchoolCounter.total }}</h2>
        <p>Total High Schools</p>
      </div>
      <div class="total-information-box">
        <img alt="" src="@/assets/images/highschools-list/new-tab-icon.svg" />
        <h2>{{ highSchoolCounter?.total_active }}</h2>
        <p>Active High Schools</p>
      </div>
      <div class="total-information-box">
        <img alt="" src="@/assets/images/highschools-list/new-tab-icon.svg" />
        <h2>{{ highSchoolCounter?.total_inactive }}</h2>
        <p>Inactive High Schools</p>
      </div>
      <div class="total-information-box">
        <img alt="" src="@/assets/images/highschools-list/new-tab-icon.svg" />
        <h2>{{ highSchoolCounter?.total_pending }}</h2>
        <p>Pending High Schools</p>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-content-between">
      <!-- Filter Button -->
      <FilterOptions :countries="countries" @onFilter="searchFilter($event)" v-if="isShowFilterOptions" />
      <div class="ms-auto">
        <button
          @click="toggleFilterOptions"
          style="background-color: rgb(231, 230, 232)"
          class="border ms-auto me-4 p-2 px-4 d-flex align-items-center justify-content-between"
          :class="isShowFilterOptions && 'bg-warning'"
        >
          <img class="me-2" alt="" src="@/assets/images/highschools-list/filter-icon.svg"/>
          Filter
        </button>
      </div>
    </div>
    <hr />
    <div style="overflow:auto">
      <!-- High Schools List Group -->

      <ul style="min-width:1200px;">
        <li
          class="highSchoolListItem d-flex align-items-center justify-content-between p-0"
          v-for="(school,index) in highSchools?.data"
          :key="school.id"
        >
          <div
            style="width: 95%"
            class="row p-0 m-0 d-flex align-items-center justify-content-center"
          >
            <div style="width: fit-content; height: fit-content" class="col-1 m-auto position-relative">
              <span
                style="
                  width: 15px;
                  height: 15px;
                  border-radius: 100%;
                  margin-right: 2px;
                  margin-bottom: 1px;
                  position: absolute;
                  right: 5px;
                "
                :style="
                  (school?.status === 'Active' && 'background-color:green') ||
                  (school?.status === 'Pending' &&
                    'background-color:rgba(249, 112, 102, 1)') ||
                  (school?.status === 'Inactive' && 'background-color:red')
                "
              ></span>
              <img
                style="width: 60px; height: 60"
                alt="uni-img"
                :src="school.cover_photo_url != '' ? school.cover_photo_url:'@/assets/images/highschools-list/highschoolImg.svg'"
              />
            </div>
            <div class="col-2 d-flex flex-column align-items-start">
              <span class="listItemTitle">High School ID</span>
              <span>{{ school?.unique_id }}</span>
            </div>
            <div class="col-3 d-flex flex-column align-items-start">
              <span class="listItemTitle">High School Name</span>
              <span>{{ school?.name }}</span>
            </div>
            <div class="col-2 d-flex flex-column align-items-start">
              <span class="listItemTitle">High School Type</span>
              <span>{{ school?.type }}</span>
            </div>
            <div class="col-1 d-flex flex-column align-items-start">
              <span class="listItemTitle">Country</span>
              <span>{{ school?.country }}</span>
            </div>
            <div class="col-1 d-flex flex-column align-items-start">
              <span class="listItemTitle">Status</span>
              <span class="d-flex align-items-center">
                <span
                  style="
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    margin-right: 2px;
                    margin-bottom: 1px;
                  "
                  :style="
                    (school?.status === 'Active' && 'background-color:green') ||
                    (school?.status === 'Pending' &&
                      'background-color:rgba(249, 112, 102, 1)') ||
                    (school?.status === 'Inactive' && 'background-color:red')
                  "
                ></span>
                {{ school?.active == 1 ? "Active" : "Inactive" }}</span
              >
            </div>
            <div class="col-1 m-auto d-flex">
              <button @click="view(school,index)" style="font-size: 14px" class="border me-0 p-2 px-4 d-flex align-items-center justify-content-between">
                  <img class="pe-2" alt="" src="@/assets/images/d-board/studentCourses.svg"/>View</button>  
                <button :class="{'bg-success':school.agreement_letters[0]?.url||false}" title="view agreement letter" @click="viewLetter(school)" style="font-size: 14px"
                class="border ms-2 p-2   d-flex align-items-center justify-content-between">
                <img style="min-width:25px" v-if="!school.agreement_letters[0]?.url||false" alt="" src="@/assets/images/d-board/studentCourses.svg" /> 
                <img style="min-width:25px" v-else alt="" src="@/assets/images/d-board/studentCoursesWhite.svg" /> 
              </button>            
            </div>
          </div>
        </li>
      </ul>
      <PaginationData :paginationData="highSchools" @onFetch="onFetchFunc($event)" />
    </div>

    <div v-if="showModal">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-custom">
        <div class="modal-header p-2">
          <!-- <button title="Resend agreement letter" @click="sendLetter(school)" style="font-size: 14px"
              class="border ms-2 p-2  d-flex align-items-center justify-content-between">
              Re-send new
          </button> -->
          <button class="modal-close" @click="closeModal">X</button>
        </div>
        <div class="modal-body p-0">
          <div v-if="letters.length>0" style="height:inherit">
            <div style="height:inherit" v-for="(letter,i) in letters" :key="i">
              <button title="Resend agreement letter" @click="sendLetter(letter.id)" style="font-size: 14px"
                  class="border ms-2 p-2 btn btn-sm btn-danger  d-flex align-items-center justify-content-between">
                  Request change
              </button>
            <embed  :src="letter?.url"  style="width: 100%; height: 100%;" :class="i>0?'mb-3':''">
            </div>
          </div>
          <div v-else style="font-size:2em; font-weight:bold;color:#aaa" class="pt-5 text-center">No file found</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="viewHighSchool">
    <HighSchoolDetail :countries="countries" :school="selectedSchool" @onStatusChange="updateStatu($event)" />
  </div>
</template>

<script>
import FilterOptions from "@/components/HighSchoolsList/FilterOptions.vue";
import HighSchoolDetail from "@/components/HighSchoolsList/HighSchoolDetail.vue";
// import { highSchools } from "@/data";
import PaginationData from "@/components/Global/Pagination";
import { get, post} from "@/api/client";
import {showModal,showToast} from "@/plugins/modal";
import { useHsStore } from "@/stores/highSchools";

export default {
  name: "HighSchoolList",  
  data() {
    return {
      listKey:0,
      viewHighSchool:false,
      selectedSchool:{},
      highSchools: [],
      schools: [],
      err: [],
      selectedScholIndex:0,
      highSchoolCounter:{},
      isShowFilterOptions: false,
      countries:[],
      letterUrl:null,
      letters:[],
      school:{},
      showModal:false
    };
  },
  computed: {
  },
  async created(){
    const response = await get("countries", 'api');               
    const store = useHsStore()
    store.countries= response
    this.countries = response;       
     this.loadData()
    },
  methods: {
    closeModal() {
      this.showModal = false;  
      this.letterUrl = null   
    },  
    viewLetter(school){
      this.school = school
      this.letters = school.agreement_letters
      this.showModal = true
    },
    async sendLetter(highSchool){
      showModal({
        title:'Are you sure you want to re-send Letter',
        text: "as this will allow them to re-sign their agreement letter",
        confirmText: "Send",
        cancelText: "Cancel",
    }).then(async (result) => {
      window.modalOpened = false;      
      if (result) {
       let res = await post('send_agreement_letter',{platform:'high_school',id:highSchool.id},'admin',false)       
       if(res?.message){
        showToast('sent')
       }  
      } 
    });
    },
    showList(){
      this.viewHighSchool=false,
      this.loadData()
    },
    async searchFilter(search){
      const res = await get(`get_highschools${search}`,'admin');      
      this.highSchools = res.message
    },
    async loadData(){
      const res = await get('get_highschools/','admin');      
      this.highSchools = res.message
      this.setHighSchools(this.highSchools)      
      const res2 = await get('highschool_dashboard','admin',false);      
      this.highSchoolCounter = res2   
    },
    view(school,index){
      this.selectedScholIndex = index
      this.selectedSchool = school
      this.viewHighSchool = true
    },
    setHighSchools(highSchools){
      const store = useHsStore();
      store.$state.highSchools= highSchools;
    },
    toggleFilterOptions() {
      this.isShowFilterOptions = !this.isShowFilterOptions;
    },
    async getHighSchoolList(data) {
   
      if (!data && data !== undefined) {        
        showModal({text:"There was an error fetching the data!"});
      } else {        
        this.setHighSchoolList(data);
      }
    },
   
    setHsStore(data) {
      const store = useHsStore();
      store.setHighSchools(data);
    },
    updateStatu(status){
      this.selectedSchool.active = status;
    },
    async onFetchFunc(data){            
      this.highSchools = data;
    },
  }, 
  components: { FilterOptions,HighSchoolDetail,PaginationData },
};
</script>

<style scoped>
.total-information-box {
  width: 245px;
  height: 152px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  padding-left: 1rem;
}
.total-information-box h2 {
  font-size: 60px;
  margin-bottom: 0;
}

.total-information-box img {
  position: absolute;
  right: 5px;
  top: 5px;
}
.total-information-box:nth-child(1) {
  background: linear-gradient(
    180deg,
    rgba(115, 117, 253, 0.2) 0%,
    rgba(115, 117, 253, 0) 100%
  );
  border: 2px solid rgba(115, 117, 253, 1);
}
.total-information-box:nth-child(2) {
  background: linear-gradient(
    180deg,
    rgba(50, 213, 131, 0.2) 0%,
    rgba(50, 213, 131, 0) 100%
  );
  border: 2px solid rgba(50, 213, 131, 1);
}
.total-information-box:nth-child(3) {
  background: linear-gradient(
    180deg,
    rgba(253, 176, 34, 0.2) 0%,
    rgba(253, 176, 34, 0) 100%
  );
  border: 2px solid rgba(253, 176, 34, 1);
}
.total-information-box:nth-child(4) {
  background: linear-gradient(
    180deg,
    rgba(249, 112, 102, 0.2) 0%,
    rgba(249, 112, 102, 0) 100%
  );
  border: 2px solid rgba(249, 112, 102, 1);
}
.highSchoolListItem {
  font-size: 14px;
  box-shadow: 0px 4px 20px 0px rgba(16, 24, 40, 0.16);
  border-radius: 8px;
  height: 100px;
  margin: 1rem 0.5rem;
}
.listItemTitle {
  font-weight: 600;
  color: rgb(102, 112, 133);
  margin-bottom: 12px;
}
button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}
img{
  max-width:25px !important;
}
</style>
