import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useUniversityStore = defineStore({
  id: "universities",
  state: () => ({
    universities: useLocalStorage("universities", []),
    associations: useLocalStorage("associations", []),
  }),
  getters: {
    getUniversities: (state) => {
      return state.universities;
    },
  },
  persist: true,
  actions: {
    setUniversities(data) {
      this.universities = data;
    },
    setAssociations(data) {
      this.associations = data;
    },
    removeProgramme(id) {
      this.programmes = this.programmes.filter((obj) => obj.id !== id);
    },
  },
});
