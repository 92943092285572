<template>
  <div class="row">
    <div class="col-sm-4 mb-3">
      <button @click="back()" class="btn btn-light btn-sm">
        <i class="pi pi-arrow-left cursor-pointer"></i>
      </button>
      <h2 style="font-size: 100%">Student Applications</h2>
      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <img
              style="border-radius: 50px"
              :src="
                student.document.formal_photo != ''
                  ? student.document.formal_photo
                  : require('/src/assets/images/d-board/avater-1.svg')
              "
              alt="logo"
              width="80"
            />
            <!-- <h6 class="my-1 text-truncate">Rejected:</h6> -->
            <p class="text-truncate">
              No.of application: {{ student.programs.length }}/3
            </p>
          </div>
          <div>
            <h5 style="font-size: 100%">Profile</h5>
            <hr />

            <div class="container px-0">
              <div class="my-2">
                <h6 class="card-title text-truncate px-2 py-2 row mb-0">
                  <span class="text-secondary col-5 text-dark p-0"
                    >First Name:
                  </span>
                  <span class="col">{{ student.first_name }}</span>
                </h6>
                <h6 class="card-title text-truncate px-2 py-2 row mb-0">
                  <span class="text-secondary col-5 text-dark p-0"
                    >Last Name:
                  </span>
                  <span class="col">{{ student.last_name }}</span>
                </h6>
                <h6 class="card-title text-truncate px-2 py-2 row mb-0">
                  <span class="text-dark col-5 text-truncate p-0"
                    >Fathers Name:
                  </span>
                  <span class="col">{{ student.father_name }}</span>
                </h6>
                <h6 class="card-title text-truncate px-2 py- row mb-0">
                  <span class="text-dark col-5 p-0">Mothers Name: </span>
                  <span class="col">{{ student.mother_name }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="text-dark col-5 p-0">Date Of Birth: </span>
                  <span class="col">{{ student.dob }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="text-dark col-5 p-0">Gender:</span>
                  <span class="col">{{ student.gender }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="col-5 text-truncate p-0">Email Address:</span>
                  <span class="col text-truncate">{{ student.email }}</span>
                </h6>
              </div>
            </div>
          </div>
          <div>
            <h6>Application Program</h6>
            <hr />

            <div
              class="container px-0"
              v-for="(program, index) in studentData.programs"
              :key="index"
            >
              <div class="my-2">
                <h6 class="card-title text-truncate px-2 py-2 row mb-0">
                  <span class="text-secondary col-5 text-dark p-0"
                    >Study Location:
                  </span>
                  <span class="col">{{
                    program?.program?.study_location
                  }}</span>
                </h6>
                <h6 class="card-title text-truncate px-2 py-2 row mb-0">
                  <span class="text-secondary text-truncate col-5 text-dark p-0"
                    >Program Type:
                  </span>
                  <span class="col text-truncate">
                    {{ program?.program?.program_type }}</span
                  >
                </h6>
                <h6 class="card-title text-truncate px-2 py-2 row mb-0">
                  <span class="text-dark col-5 text-truncate p-0"
                    >Program Name:
                  </span>
                  <span class="col-4 text-truncate">{{
                    program?.program?.program_name
                  }}</span>
                </h6>
                <h6 class="card-title text-truncate px-2 py- row mb-0">
                  <span class="text-dark col-5 p-0">Learnig Mode: </span>
                  <span class="col">
                    {{ program?.program?.learning_mode?.join(",") }}</span
                  >
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="text-dark col-5 p-0">Program Code: </span>
                  <span class="col">{{ program?.program?.program_code }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="text-dark text-truncate col-5 p-0"
                    >Program Award:</span
                  >
                  <span class="col"
                    >{{ program?.program?.program_award }}
                  </span>
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="col-5 text-truncate p-0">Course Duration:</span>
                  <span class="col text-truncate"
                    >{{ program?.program?.program_duration }} ({{
                      program?.program?.program_ECTS
                    }}
                    ECTS)</span
                  >
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="text-dark text-truncate col-5 p-0"
                    >Study Language:</span
                  >
                  <span class="col">{{
                    program?.program?.study_language?.join(",")
                  }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="col-5 text-truncate p-0">Studey Type :</span>
                  <span class="col text-truncate">{{
                    program?.program?.study_type?.join(",")
                  }}</span>
                </h6>
                <h6 class="card-title text-dark px-2 py-2 row mb-0">
                  <span class="col-5 text-truncate p-0">Status:</span>
                  <span
                    :class="[
                      'col text-truncate',
                      className(program.application_status),
                    ]"
                    >{{ program?.application_status }}</span
                  >
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="application-review py"
        style="border: 1px solid black; margin-top: 25px; border-radius: 8px"
      >
        <h6 class="text-center my-3">Program Application Review</h6>
        <div class="form-group sub-input sub-input-large">
          <div class="form-group dropdown">
            <label class="label-text">Student Applied Program</label>
            <div style="width: 90%; margin: auto">
              <Dropdown
                style="border-radius: 10px"
                v-model="student_program_id"
                :options="student.programs"
                optionLabel="program.program_name"
                optionValue="id"
                placeholder=""
                class="w-100"
              />
            </div>
          </div>
          <div class="form-group dropdown">
            <label class="label-text">Status</label>
            <div style="width: 90%; margin: auto">
              <Dropdown
                style="border-radius: 10px"
                v-model="selectedReview"
                :options="AppliactionReview"
                optionLabel=""
                placeholder="Waiting  Review"
                class="w-100"
                :default="AppliactionReview[0]"
              />
            </div>
          </div>

          <div class="form-group">
            <label class="label-text">Comments</label>
            <Textarea
              v-model="comment"
              class="form-control textarea"
              placeholder="Enter a comment..."
              :autoResize="true"
              rows="4"
              cols="5"
            ></Textarea>
          </div>

          <div class="btn-container">
            <button
              @click="updateStatus"
              class="save-next-btn ripple text-dark"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-8">
      <div class="applications-nav">
        <div class="tab-container">
          <ul
            class="nav nav-pills py-5"
            id="pills-tab"
            role="tablist"
            style="align-items: flex-end"
          >
            <li class="nav-item m-1" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Contact
              </button>
            </li>
            <li class="nav-item m-1" role="presentation">
              <button
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Education
              </button>
            </li>
            <li class="nav-item m-1" role="presentation">
              <button
                class="nav-link"
                id="pills-education-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-education"
                type="button"
                role="tab"
                aria-controls="pills-education"
                aria-selected="false"
              >
                Documents
              </button>
            </li>
            <li class="nav-item m-1" role="presentation">
              <button
                class="nav-link"
                id="pills-document-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-document"
                type="button"
                role="tab"
                aria-controls="pills-document"
                aria-selected="false"
              >
                Chat history
              </button>
            </li>
            <li class="nav-item m-1" role="presentation">
              <button
                class="nav-link"
                id="pills-activities-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-activities"
                type="button"
                role="tab"
                aria-controls="pills-document"
                aria-selected="false"
              >
                Activities
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="container h-auto px-0 py-3 form-controll">
        <div class="applications-main tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabindex="0"
          >
            <StudentContact :student="studentData" />
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
            tabindex="0"
          >
            <StudentEducation :student="studentData" />
          </div>
          <div
            class="tab-pane fade"
            id="pills-education"
            role="tabpanel"
            aria-labelledby="pills-education-tab"
            tabindex="0"
          >
            <StudentDocument :data="studentData?.document" :student="studentData" />
          </div>
          <div
            class="tab-pane fade"
            id="pills-document"
            role="tabpanel"
            aria-labelledby="pills-document-tab"
            tabindex="0"
          >
            <ChatBox :student="studentData" />
          </div>
          <div
            class="tab-pane fade"
            id="pills-activities"
            role="tabpanel"
            aria-labelledby="pills-activities-tab"
            tabindex="0"
          >
            <ActivitiesHistory :student="student" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import Dropdown from "primevue/dropdown";
import StudentDocument from "@/components/studentcourses/StudentDocument.vue";
import StudentContact from "@/components/studentcourses/StudentContact.vue";
import StudentEducation from "@/components/studentcourses/StudentEducation.vue";
import ChatBox from "@/components/studentcourses/ChatBox.vue";
import ActivitiesHistory from "@/components/studentcourses/ActivitiesHistory.vue";
import { showModal } from "@/plugins/modal";
import { post } from "@/api/client";
export default {
  props: {student:Object},
  // components: { Dropdown,  },
  components: {
    Dropdown,
    StudentContact,
    StudentDocument,
    StudentEducation,
    ChatBox,
    ActivitiesHistory,
  },
  data() {
    return {
      studentData:this.student,
      stuForm: false,
      selectedReview: null,
      AppliactionReview: ["Submitted", "Accepted", "Enrolled", "Rejected"],
      details: {},
      student_program_id: null,
    };
  },
  methods: {
    back() {
      this.$emit("back", false);
    },
    className(status) {
      status = status.toLowerCase()
      if (status == "applying") {
        return "bg-primary text-white py-2 px-3 rounded";
      }

      if (status == "accepted") {
        return "bg-warning text-white py-2 px-3 rounded";
      }

      if (status == "approved") {
        return "bg-success text-white py-2 px-3 rounded";
      }

      if (status == "rejected") {
        return "bg-danger text-white py-2 px-3 rounded";
      }

      return "bg-dark text-white py-2 px-3 rounded";
    },
    async updateStatus() {
      if (!this.selectedReview) {
        showModal({ text: "select a status option" });
        return false;
      }
      if (!this.student_program_id) {
        showModal({ text: "select a program" });
        return false;
      }

      let res = await post(
        "program/update_application_status",
        {
          id: this.student_program_id,
          comment: this.comment,
          application_status: this.selectedReview,
        },
        "admin"
      );

      if (res) {        
        let index = this.studentData.programs.findIndex((item)=>item.id === this.student_program_id)
        this.studentData.programs[index].application_status = this.selectedReview
        showModal({ text: res.responseBody });
      }
    },
  },
};
</script>
 
 <style scoped>
.container {
  margin-bottom: 60px;
}

.application-review div {
  margin-bottom: 10px;
}

.label-text {
  margin: 10px;
}
.profile-image {
  background-color: #fbc02d;

  width: 40%;
  height: 60px;
  padding-top: 2px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-radius: 6px;
}
.profile-image > h6 {
  font-size: 90%;
}

.label-text {
  font-size: 15px;
}
.textarea {
  width: 95%;
  margin-left: 10px;
}
.btn-container {
  padding: 2px;
}

.card-title {
  font-size: 12px;
}
.tab-container {
  height: 107px;
  border-bottom: 1px solid rgb(235, 233, 233);
}
.view-btn {
  background-color: #eaecf0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .view-btn:hover {
   color: rgb(245, 245, 245) !important;
   box-shadow: inset 150px 0 0 0 #fac515 !important;
} */

.view-btn img {
  width: 20px;
}

.save-next-btn {
  border-radius: 4px;
  width: 95%;
  padding: 0.6rem;
  border: none;
  margin-left: 8px;

  background-color: gray;
}

.nav-link {
  color: grey;
}
.active {
  color: rgb(12, 11, 11) !important;
  font-weight: 600;
  background-color: transparent !important;
  border-radius: 0px !important;
  border-bottom: 2px solid rgb(134, 132, 132) !important;
}
@media (max-width: 1024px) {
  .profile-image {
    width: 60%;
  }
  .profile-image > h6 {
    font-size: 13px;
  }
  .profile-image > p {
    font-size: 1px;
  }
}
@media (max-width: 664px) {
  .profile-image {
    width: 70%;
  }
  .profile-image > h6 {
    font-size: 12px;
  }
}
.nav-link > button {
  color: red;
}
.nav-link.active {
  background-color: #f9fafb;
  color: black;
}
.img-size {
  border-radius: 2px;
  background-color: gray;
  height: 80px;
  width: 70px;
}
</style>


