<template>
  <div class="">
    <div>
      <div class="ps-4 mt-2 d-flex align-items-center justify-content-between">
        <!-- Page Header -->
        <h1 style="font-size: 25px">Create University Profile</h1>
      </div>
      <hr class="mb-0">
    </div>
    <div class="ps-5 border-bottom w-100 d-flex align-items-center" style="overflow: auto;">
      <div @click="changeTab(1)" style="
          color: rgb(29, 41, 57);
          cursor: pointer;
          border-bottom: 4px solid transparent;
        " class="me-5 p-3" :style="currentTab === 1 && 'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight: 600'
          ">
        University Information
      </div>
      <div @click="changeTab(2)" style="
          cursor: pointer;
          border-bottom: 4px solid transparent;
          color: rgb(29, 41, 57);
        " :style="currentTab === 2 &&
          'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600'
          " class="p-3">
        Contact Details
      </div>
      <div @click="changeTab(3)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 3 && 'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600' " class="p-3"> 
        Commission
      </div>
      <div @click="changeTab(4)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 4 && 'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600' " class="p-3">
        Agreement
      </div>
    </div>
    <div v-show="currentTab === 1">
      <UniversityInformationTab @validate-email="validateEmail" @onUpdateInfo="updateInfoFunc($event)" />
    </div>
    <div v-show="currentTab === 2">
      <ContactDetailsTab :countries="countries" :isSubmitBtnClicked="isSubmitBtnClicked"
        @onUpdateContact="updateContactFunc($event)" />
    </div>
    <div v-show="currentTab === 3">
      <CommissionTab @updateCommission="updateCommissionFunc($event)" />
    </div>
    <div v-show="currentTab === 4">
      <AgreementTab @updateLetter="updateLetterFunc($event)"  />
    </div>
    <div class="d-flex align-items-center justify-content-between w-80 m-auto mt-5">
      <!-- Footer Buttons -->
      <button class="border py-2 px-5">Cancel</button>
      <button v-if="currentTab <4" @click="changeTab(currentTab + 1)" class="bg-warning border py-2 px-5">
        Next
      </button>
      <button v-else @click="openConfirmModal" class="bg-warning border py-2 px-5">
        Create Profile
      </button>
    </div>
  </div>
  <ConfirmModal v-if="isConfirmModalOpen" @closeConfirmModal="closeConfirmModal" @createProfile="createProfile" />
</template>

<script>
import UniversityInformationTab from "@/components/UniversitiesList/UniversityInformationTab.vue";
import ContactDetailsTab from "@/components/UniversitiesList/ContactDetailsTab.vue";
import CommissionTab from "@/components/UniversitiesList/CommissionTab.vue";
import AgreementTab from "@/components/UniversitiesList/AgreementTab.vue";
import ConfirmModal from "@/components/UniversitiesList/ConfirmModal.vue";
import { post } from "@/api/client";
import { showModal } from '@/plugins/modal';
import { useHsStore } from "@/stores/highSchools";
export default {
  name: "createUniversityProfile",

  data() {
    return {
      currentTab: 1,
      isConfirmModalOpen: false,
      isValid: false,
      updateInfo: {},
      updateContact: {},
      isSubmitBtnClicked: false,
      countries: [],
      updateLetters:[],
      updateCommissions:{}
    };
  },
  methods: {
    updateInfoFunc(e) {
      this.updateInfo = e
    },
    updateContactFunc(e) {
      this.updateContact = e
    },
    updateLetterFunc(e){
      this.updateLetters = e;
    },
    updateCommissionFunc(e){
      this.updateCommissions = e;
    },
    changeTab(tab) {
      if (this.isValid) {
        this.currentTab = tab;
      } else {
        showModal({ text: "Enter a valid email or a picture that is jpg or png!" });
      }
    },
    openConfirmModal() {
      if (!this.updateContact.country_id) {
        this.isConfirmModalOpen = false;
        showModal({ text: 'Country field is required' })
        return false
      }
      if (!this.updateContact.state_id) {
        showModal({ text: 'State field is required' })
        return false
      }
      if (!this.updateContact.phone) {
        showModal({ text: 'Phone field is required' })
        return false
      }
      this.isConfirmModalOpen = true;
      this.isSubmitBtnClicked = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
    createProfile() {

      if (this.publish()) {
        this.isConfirmModalOpen = false;
      } else {
        this.isConfirmModalOpen = false;
      }
    },
    validateEmail(n) {
      this.isValid = n;
    },
    removeData() {
      localStorage.removeItem("highSchoolInfo");
      localStorage.removeItem("contactDetails");
    },
    async publish() {

      let res = await post(
        "add_university",
        {
          ...this.updateInfo,
          ...this.updateContact,
          letters: this.updateLetters,
          commission_type: this.updateCommissions.commission_type,
          commission_value: this.updateCommissions.commission_value,
          commission_targets: this.updateCommissions.blocks
        },
        "admin", false
      );
      if (res?.message) {
        showModal({ text: res?.message }).then(() => {
          if(res.status != 'error'){
            this.$router.push('/universities-list');
          }
        })
      }
      this.isConfirmModalOpen = false;
      this.removeData();

    },
  },

  components: { ContactDetailsTab, UniversityInformationTab, ConfirmModal,CommissionTab,AgreementTab },
  created() {
    this.countries = useHsStore().countries
  },
};
</script>

<style scoped></style>
