<template>
  <button @click="showList()" v-show="showViewAssociation" class="btn btn-secondary">
    Show List
  </button>
  <div v-show="!showViewAssociation">
    <div class="d-flex align-items-center justify-content-between">
      <!-- Page Header -->

      <h1 style="font-size: 36px">Highschool Association List</h1>
      <router-link style="color: unset" to="/highschool-associations/create-association"
        class="border p-2 px-4 bg-warning d-flex align-items-center justify-content-between">
        <img src="@/assets/images/universities-list/create-university-profile.svg" alt="" class="me-3" />
        Create Highschool Association
      </router-link>
    </div>
    <hr />
    <div class="total-information-boxes d-flex align-items-center justify-content-between">
      <!-- Total Information Boxes -->
      <swiper :modules="autoplay"  :slides-per-view="slidesPerView" :space-between="20" :loop="true">
            <swiper-slide  style="display: inline-block;">    
              <div class="total-information-box w-100">
                <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                <h2>{{ associationCounter?.total_association }}</h2>
                <p>Total High School Association</p>
              </div>
            </swiper-slide>
            <swiper-slide  style="display: inline-block;">    
              <div class="total-information-box w-100">
                <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                <h2>{{ associationCounter?.total_joined_high_school }}</h2>
                <p>Total Joined High School</p>
              </div>
            </swiper-slide>
            <swiper-slide  style="display: inline-block;">    
              <div class="total-information-box w-100">
                <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                <h2>{{ associationCounter?.total_pending_request }}</h2>
                <p>Total Pending Request</p>
              </div>
            </swiper-slide>
         <!--    <swiper-slide  style="display: inline-block;">    
              <div class="total-information-box">
                <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                <h2>{{ associationCounter?.total_pending }}</h2>
                <p>Pending High School Association</p>
              </div>
            </swiper-slide> -->
          </swiper>
    </div>
    <hr />
    <div class="d-flex justify-content-between">
      <FilterOptions :countries="countries" @onFilter="searchFilter($event)" v-if="isShowFilterOptions" />
      <div class="ms-auto">
        <!-- Filter Button -->
        <button @click="toggleFilterOptions" style="background-color: rgb(231, 230, 232)"
          class="border me-4 p-2 px-4 d-flex align-items-center justify-content-between"
          :class="isShowFilterOptions && 'bg-warning'">
          <img class="me-2" alt="" src="@/assets/images/universities-list/filter-icon.svg" />
          Filter
        </button>
      </div>
    </div>
    <hr />
    <div style="overflow:auto">
      <!-- University Association List Group -->

      <ul style="min-width:1200px;">
        <li class="universityListItem d-flex align-items-center justify-content-between p-0"
          v-for="association in associations?.data" :key="association.id">
          <div style="width: 95%" class="row p-0 m-0 d-flex align-items-center justify-content-center">
            <div style="width: fit-content; height: fit-content" class="col-1 m-auto position-relative">
              <span style="
                  width: 15px;
                  height: 15px;
                  border-radius: 100%;
                  margin-right: 2px;
                  margin-bottom: 1px;
                  position: absolute;
                  right: 5px;
                " :style="(association.status === 'Active' &&
                    'background-color:green') ||
                  (association.status === 'Pending' &&
                    'background-color:rgba(249, 112, 102, 1)') ||
                  (association.status === 'Inactive' && 'background-color:red')
                  "></span>
              <img style="border-radius: 100%; width: 50px; height: 50px" alt="uni-img"
                :src="association.cover_photo_url" />
            </div>           
            <div class="col-3 d-flex flex-column align-items-start">
              <span class="listItemTitle">Association Name</span>
              <span>{{ association.name }}</span>
            </div>
            <div class="col-2 d-flex flex-column align-items-start">
              <span class="listItemTitle">Association Email</span>
              <span>{{ association.email }}</span>
            </div>
            <div class="col-1 d-flex flex-column align-items-start">
              <span class="listItemTitle">Country</span>
              <span>{{ association.country }}</span>
            </div>
            <div class="col-1 d-flex flex-column align-items-start">
              <span class="listItemTitle">State</span>
             <span>{{ association.state }}</span>
            </div>
            <div class="col-1 m-auto d-flex">
              <button @click="selectAssociation(association)" style="font-size: 14px"
                class="border me-0 p-2 px-4 d-flex align-items-center justify-content-between">
                <img class="pe-2" alt="" src="@/assets/images/d-board/studentCourses.svg" />
                View
              </button>
              
            </div>
          </div>
        </li>
      </ul>
      <PaginationData :paginationData="associations" @onFetch="onFetchFunc($event)" />
    </div>
  </div>
  <div v-if="showViewAssociation">
    <AssociationDetail :countries="countries" :association="selectedAssociation" @back="back($event)" />
  </div> 
</template>

<script>
import AssociationDetail from "@/components/Association/AssociationDetail.vue";
import FilterOptions from "@/components/AssociationFilterOptions.vue";
import PaginationData from "@/components/Global/Pagination";
import { post, get } from "@/api/client";
import { showModal } from "@/plugins/modal";
import { useUniversityStore } from "../../stores/universities";
import { useHsStore } from "@/stores/highSchools";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
export default {
  name: "AssociationsList",
  // components: {  },
  data() {
    return {
      showModal: false,
      selectedAssociation: {},
      showViewAssociation: false,
      associations: {data:[]},
      isShowFilterOptions: false,
      post: null,
      error: null,
      associationCounter: {},
      countries: [],
      modules: [Pagination],
      autoplay: [Autoplay],     
    };
  },
  computed: {
    slidesPerView() {      
      const viewportWidth = window.innerWidth;
      if (viewportWidth >= 1024) {
        return 3;
      } else if (viewportWidth >= 768) {        
        return 3;
      } else {        
        return 1;
      }
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;  
      this.letterUrl = null   
    },
    async onFetchFunc(data) {
      this.associations = data;
    },
    showList() {
      (this.showViewAssociation = false), this.loadData();
    },
    async loadData() {
      const res = await post("get_associations",{}, "admin", false);      
      if(res?.message){
        this.associations = res.message;
        this.setAssociations(this.associations);
      }

    },
    selectAssociation(association) {
      this.selectedAssociation = association;
      this.showViewAssociation = true;
    },
    back(value) {
      this.showViewAssociation = value;
    },
    setAssociations(associations) {
      const store = useUniversityStore();
      store.$state.associations = associations;
    },
    toggleFilterOptions() {
      this.isShowFilterOptions = !this.isShowFilterOptions;
    },
    getAssociationsList(data) {
      if (!data && data !== undefined) {
        showModal({ text: "There was an error fetching the data!" });
      } else {
        this.setAssociations(data);
      }
    },
    setAssociationStore(data) {
      const store = useUniversityStore();
      store.setAssociations(data);
    },
  },
  beforeMount() {
    this.getAssociationsList();
  },
  mounted() {
    // this.fetchData();
  },
  async created() {
    const response = await get("countries", 'api');
    const store = useHsStore()
    store.countries = response
    this.countries = response;
    this.loadData();
    const response2 = await get("get_association_dashboard", 'admin',false);
    if(response2?.message){
      this.associationCounter =response2.message
    }
    /* this.docFileCount.map(function(item){
           item.count = res2.responseBody[item.title.toLowerCase().replaceAll(' ','_')]??0
      }) */
  },
  components: { FilterOptions, AssociationDetail, PaginationData,Swiper,SwiperSlide  },
};
</script>

<style scoped>
.total-information-box {
  width: 245px;
  height: 152px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  padding-left: 1rem;
}

.total-information-box h2 {
  font-size: 60px;
  margin-bottom: 0;
}

.total-information-box img {
  position: absolute;
  right: 5px;
  top: 5px;
}

.total-information-box:nth-child(1) {
  background: linear-gradient(180deg,
      rgba(115, 117, 253, 0.2) 0%,
      rgba(115, 117, 253, 0) 100%);
  border: 2px solid rgba(115, 117, 253, 1);
}

.total-information-box:nth-child(2) {
  background: linear-gradient(180deg,
      rgba(50, 213, 131, 0.2) 0%,
      rgba(50, 213, 131, 0) 100%);
  border: 2px solid rgba(50, 213, 131, 1);
}

.total-information-box:nth-child(3) {
  background: linear-gradient(180deg,
      rgba(253, 176, 34, 0.2) 0%,
      rgba(253, 176, 34, 0) 100%);
  border: 2px solid rgba(253, 176, 34, 1);
}

.total-information-box:nth-child(4) {
  background: linear-gradient(180deg,
      rgba(249, 112, 102, 0.2) 0%,
      rgba(249, 112, 102, 0) 100%);
  border: 2px solid rgba(249, 112, 102, 1);
}

.universityListItem {
  font-size: 14px;
  box-shadow: 0px 4px 20px 0px rgba(16, 24, 40, 0.16);
  border-radius: 8px;
  height: 100px;
  margin: 1rem 0.5rem;
}

.listItemTitle {
  font-weight: 600;
  color: rgb(102, 112, 133);
  margin-bottom: 12px;
}

button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}
</style>
