<template>

    <div class="accordion-item shadow my-2 " style="background-color: #fff">
      <div class="d-flex px-3 py-2" style="height: 80px;">
        <div class="col-12 d-flex align-items-center">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <div class="col-1 track-cell">
              <img
                style="width: 30px; height: 30px; border-radius: 5px"
                class=""
                :src="require('/src/assets/images/d-board/avater-1.svg')"
                alt=""
              />
            </div>
            <div class="text-secondary track-cell col-3 px-1">
              <span class="t-head">High School Name</span>
              <p class="text-dark mb-0 text-truncate">
                {{ member.name }}
              </p>
            </div>
            <div class="text-secondary track-cell col-2 px-1">
              <span class="t-head">High School Email</span>
              <p class="text-dark mb-0 text-truncate">
                {{ member?.email }}
              </p>
            </div>
            <div class="text-secondary track-cell col-2 px-1">
              <span class="t-head">High School Type</span>
              <p class="text-dark mb-0 text-truncate">
                {{ member.type }}
              </p>
            </div>
            <div class="text-secondary track-cell col-2 px-1">
              <span class="t-head">Country </span>
              <p class="text-dark mb-0 text-truncate">
                {{ member?.country }}
              </p>
            </div>
            <div class="text-secondary track-cell col-1 px-1">
              <span class="t-head">Status</span>
              <p class="text-dark mb-0 text-truncate">
                {{
                  member?.active == "1"
                    ? "Active"
                    : "Inactive"
                }}
              </p>
            </div>
            <div class="col-1 d-flex" style="text-align: end; width: fit-content">
              <button
                class="btn btn-sm btn-warning py-1 px-3"
                style="font-size: 12px"
                type="button"
                @click="accordBtn('collapse' + member.id)"
              >
                <span class="">View</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        :id="'collapse' + member.id"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionExample"
      >
        <hr />
        <div class="accordion-body px-5">
          <div class="row gx-5">
            <div class="col-lg-6 offset-lg-3">
              <div class="card p-3">
                <h4>High School Information</h4>
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="type">Name:</h6>
                  <h6 class="type-content">
                    {{ member.name }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="type">Type:</h6>
                  <h6 class="type-content">
                    {{ member.type }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="type">Country:</h6>
                  <h6 class="type-content">
                    {{ member.country }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="type">Date Joined:</h6>
                  <h6 class="type-content">
                    {{ member.registered_date }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="type">City:</h6>
                  <h6 class="type-content">
                    {{ member.city }}
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h6 class="type">High School Address</h6>
                  <h6 class="type-content">
                    {{ member.address }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
        </div>
        <br>
        <br>
      </div>
    </div>
  </template>
  
  <script>
  import jquery from "jquery";
  const $ = jquery;
  export default {
    props: {
      member: {
        type: Object
      },
  
    },
    components: {},
    data() {
      return {
      };
    },
    methods: {

      accordBtn(id) {
        $("#" + id).slideToggle(function(){
            $(this).toggleClass('visible')
            $(this).toggleClass('show')
        });
      },
    },
  };
  </script>
  <style scoped>
  .accordion-button:not(.collapsed) {
    color: black !important;
    background-color: #c3b5fd !important;
    box-shadow: none !important;
  }
  .accordion-button:focus {
    z-index: 3;
    font-size: 12px !important;
    background-color: #c3b5fd;
    outline: 0;
    box-shadow: none !important;
  }
  .accordion-button {
    background-color: #eaecf0 !important;
    border-radius: 10px !important;
  }
  .track-cell {
    font-size: 12px;
  }
  .track-cell p {
    font-size: 14px;
  }
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
  }
  
  .t-head {
    color: #667085;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
  }
  .card {
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(16, 24, 40, 0.16);
  }
  .type {
    color: var(--base-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
  }
  .type-content {
    color: #1d2939;
    text-align: right;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
  }
  .button{
    padding: 10px 15px;
    border-radius: 10px;
    outline: none;
    border: none;
    width: 30%;
  }
  </style>
  