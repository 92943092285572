<template>
  <div class="row w-100 mx-auto">
    <!-- <div class="container-fluid h-100 p-0" style="background-color: aqua"> -->
    <div class="">
      <div
        class="col-12 mb-2 d-flex align-items-center justify-content-between"
      >
        <div>
          <h3 class="display" style="text-transform: capitalize;">{{ userType }} Chat</h3>
        </div>
        <div class="col-8">
          <div class="input-group">
            <div class="w-100">
              <span
                type="button"
                id="basic-addon1"
                style=""
                class="position-relative p-input-icon-left w-100"
              >
                <img
                  style="width: 1.5rem"
                  class="position-absolute mx-2 top-0 my-auto bottom-0"
                  :src="require(`/src/assets/images/search_icon.png`)"
                  alt=""
                />
                <InputText
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  @keyup="filter($event)"
                  type="text"
                  placeholder="Search"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="position-relative">
          <i
            style="cursor: pointer"
            class="pi pi-bars fs-4"
            @click="showDetails1()"
          ></i>
          <Sidebar
            style="
              height: 80vh;
              align-self: flex-end;
              border-radius: 10px;
              align-items: flex-start;
            "
            position="right"
            v-model:visible="trackDetails1"
            class="p-sidebar-sm"
            :show-close-icon="true"
          >
            <ul class="d-flex flex-column w-100" style="background-color: sea">
              <router-link
                to="/chat/university/newmessage"
                @click="showDetails1()"
                style="
                  font-size: 18px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
                class="fw-light btn"
              >
                <i class="mx-4 pi pi-pencil"></i>
                Create New Messages
              </router-link>
              <router-link
                to="/chat/university"
                @click="showDetails1()"
                style="
                  font-size: 18px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
                class="fw-light btn"
              >
                <i class="mx-4 pi pi-comments"></i>
                All Messages
              </router-link>
              <router-link
                to="/chat/university/inbox#favourites"
                @click="showDetails1()"
                style="
                  font-size: 18px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
                class="fw-light btn"
              >
                <i class="mx-4 pi pi-star"></i>
                Favourite Messages
              </router-link>
              <router-link
                to="/chat/university/inbox#archived"
                @click="showDetails1()"
                style="
                  font-size: 18px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                "
                class="fw-light btn"
              >
                <i class="mx-4 pi pi-bookmark"></i>
                Archived Messages
              </router-link>
              <router-link
                to="/chat/university/inbox#unread"
                @click="showDetails1()"
                style="
                  font-size: 18px !important;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
                class="fw-light btn"
              >
                <i class="mx-4 pi pi-envelope"></i>
                Unread Messages
              </router-link>
            </ul>
          </Sidebar>
        </div>
      </div>
    </div>
    <hr class="mt-1" />
    <ul>
      <div
        style="margin-bottom: 1rem; height: 75px; color: unset"
        class="d-flex w-100 align-items-center chatListItem filterable"
        v-for="(chat,index) in filteredChats"
          @click="openContact('/chat/view-chats/'+userType+'-' + index,chat)"
          :key="chat.sender_id"
          :id="'UniversityChatId' + chat.sender_id"
        >
        <!-- Message Options Box -->
        <div v-if="trackDetails2 == chat.sender_id" class="tracking-container " style="position: absolute; z-index: 1; right: 0" >
          <ul
            class=" "
            style="
              border-radius: 5px;
              border: 0.5px solid #8a8d93;
              background-color: #ffff;
              width: fit-content;

              display: flex;
              flex-direction: column;

              text-align: left;
              position: relative;
              box-shadow: 0px 4px 8px 0px #00000040;

              right: 55px;
              bottom: 30px;
            "
          >
            <li
              @click="switchDeleteModal(chat.sender_id)"
              class="fw-light btn"
              style="width: fit-content; font-size: 12px; color: #4d5056"
            >
              <svg-icon
                style="margin-right: 10px"
                type="mdi"
                :path="deleteIconPath"
              ></svg-icon>
              Delete
            </li>
            <li
              @click="chat.is_read = false"
              class="fw-light btn"
              style="width: fit-content; font-size: 12px; color: #4d5056"
            >
              <svg-icon
                style="margin-right: 10px"
                type="mdi"
                :path="unreadIconPath"
              ></svg-icon>

              Unread
            </li>
            <li
              @click="chat.is_archived = !chat?.is_archived"
              class="fw-light btn"
              style="width: fit-content; font-size: 12px; color: #4d5056"
            >
              <svg-icon
                style="margin-right: 10px"
                type="mdi"
                :path="archiveIconPath"
              ></svg-icon>
              Archive
            </li>
          </ul>
        </div>
        <!-- Message Options Box -->

        <div style="width: 15px; height: 15px; margin-right: 0.5rem" class="d-flex align-items-center justify-content-center">
          <span :class="chat?.online?'online':'offline' "></span>
        </div>
        <div
          id="chatListItem-inner"
          style="height: 100%; border-bottom: 0.5px solid rgba(77, 80, 86, 1)"
          :style="!chat.is_read && 'background-color:rgba(0,0,0,.05)'"
          class="w-100 d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <img
              style="width: 40px; height: 40px; border-radius:50px; margin-right: 1rem"
              class=""
              :src="chat?.photo_url"
              alt=""
            />
            <div class="d-flex flex-column align-items-start">
              <!-- Body Col -->
              <span
                style="color: #1d2939; font-weight: 600; font-sizie: 20px"
                class="text-dark"
              >
                <span>{{ splittedUserName(chat?.name).before }}</span>
                <span class="text-warning">{{
                  splittedUserName(chat?.sender_email).matched
                }}</span>
                <span>{{
                  splittedUserName(chat?.sender_email).after
                }}</span></span
              >
              <span v-html="chat.message"></span>
            </div>
          </div>
          <div
            class="h-100 d-flex align-items-end flex-column justify-content-evenly"
          >
            <!-- Options Col -->
            <i
              id="showDetailsDots"
              @click="showDetails2(chat.sender_id)"
              style="cursor: pointer"
              class="pi pi-ellipsis-v fs-6"
            />
            <span>{{ formatDateTime(chat.created_at) }}</span>
          </div>
        </div>
      </div>
    </ul>
    <!-- Delete Modal -->
    <div
      v-if="isDeleteModal"
      style="
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div
        style="
          width: 360px;
          height: 220px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          background-color: white;
          justify-content: space-between;
          padding: 2rem;
          border-radius: 12px;
        "
      >
        <div class="d-flex justify-content-between align-items-center w-100">
          <h3 style="font-size: 30px">Delete</h3>
          <span @click="switchDeleteModal(null)" style="cursor: pointer"
            >X</span
          >
        </div>
        <p>Do you want to delete the conversation?</p>
        <div class="w-100 d-flex align-items-center justify-content-between">
          <button
            style="
              font-size: 12px;
              border: none;
              width: 40%;
              padding: 0.5rem;
              border-radius: 8px;
            "
            class="bg-warning"
            @click="removeElement"
          >
            Delete
          </button>
          <button
            style="
              font-size: 12px;
              border: none;
              width: 40%;
              padding: 0.5rem;
              border-radius: 8px;
              background-color: transparent;
              border: 0.5px solid rgba(29, 41, 57, 1);
            "
            @click="switchDeleteModal(null)"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { UniversityChatMessages } from "@/data";
import InputText from "primevue/inputtext";
import Sidebar from "primevue/sidebar";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiEmailMarkAsUnread, mdiPackageDown, mdiDelete } from "@mdi/js";
import { get } from "@/api/client";
import { useChatsStore } from "@/stores/chats";

export default {
  name: "ChatContactView",
  data() {
    return {      
      search: "",
      isDeleteModal: false,
      deleteModalId: "",
      timeoutLeft: 0,
      chats: [],
      trackDetails2: "",
      trackDetails1: false,
      unreadIconPath: mdiEmailMarkAsUnread,
      archiveIconPath: mdiPackageDown,
      deleteIconPath: mdiDelete,
      userType:''
    };
  },
  components: { InputText, Sidebar, SvgIcon },
  
  created() {    
    //this.chats = await this.fetchChats()
    this.updateUserType()    
    this.chats = this.$globals.chats[this.userType]    
  },  
  watch: {
    '$globals.chats': function (newChats, oldChats) {            
      this.chats = newChats[this.userType]     
       newChats
      this.initContact(this.chats)  
     // console.log(this.userType,this.chats, 555)
      oldChats      
    }, 
    '$route':function(){
      this.updateUserType()
      this.chats = this.$globals.chats[this.userType]
    }
  }, 
  methods: {
    openContact(route,selected){
      const chatStore = useChatsStore();
      chatStore.setChats(selected);
      this.$router.push(route)
    },
    updateUserType(){
      if(this.$router.currentRoute.value.fullPath== '/chat/high-school'){
        this.userType = 'highschool'
      }else{
        this.userType = 'university'
      }
    },
    initContact(chats){     
     chats.forEach((chat)=>{
          window.Echo.join(`go-online.${chat.user_type}-${chat.sender_id}`)    
        })   
    },
    formatDateTime(dateTimeStr) {
      if(!dateTimeStr){
        return "Empty"
      }
      const dateObj = new Date(dateTimeStr);
      const today = new Date(); // Current date

      // Check if the date has passed today
      if (dateObj.toDateString() === today.toDateString()) {
        let hours = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        let period = "AM";

        // Convert hours to 12-hour format and determine AM/PM
        if (hours >= 12) {
          period = "PM";
          if (hours > 12) {
            hours -= 12;
          }
        }

        // Pad single-digit minutes with a leading zero
        if (minutes < 10) {
          minutes = "0" + minutes;
        }

        return hours + ":" + minutes + " " + period;
      } else {
        // Return the full date in "DD/MM/YYYY" format
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        return day + "/" + month + "/" + year;
      }
    },
    async fetchChats(){
      const  res = await get('chat','admin')        
      const chatStore = useChatsStore()
      chatStore.setChats(res?.message?.chats)
      return res?.message?.chats.university
    },
    removeElement() {
      let a = document.getElementById("UniversityChatId" + this.deleteModalId);

      a.parentNode.removeChild(a);
      this.switchDeleteModal(null);
    },
    switchDeleteModal(id) {
      if (this.deleteModalId === "") {
        this.deleteModalId = id;
        this.isDeleteModal = true;
      } else {
        this.deleteModalId = "";
        this.isDeleteModal = false;
      }
    },
    closeDetails2(e) {
      if (e.target.id !== "showDetailsDots") {
        this.trackDetails2 = "";
      }
    },
    showDetails2(id) {
      this.trackDetails2 = id;
      this.timeoutLeft = 3000;
    },
    showDetails1() {
      console.log(this.trackDetails1);
      this.trackDetails1 = !this.trackDetails1;
    },
    splittedUserName(sender_email) {
      if (this.search.length > 0) {
        let index = sender_email
          .toLowerCase()
          .lastIndexOf(this.search.toLowerCase());        
        let name = sender_email.split("");
        let before = index <= 0 ? "" : name.slice(0, index).join("");
        let after = name.slice(index + this.search.length).join("");

        return { before: before, matched: this.search, after: after };
      }
      return { before: sender_email };
    },
    filter(e) {      
      var input = e.target;
      var filter = input.value.toLowerCase();
      var nodes = document.querySelectorAll('.filterable');
      
      for (let i = 0; i < nodes.length; i++) {
        console.log(nodes[i].innerText.toLowerCase().includes(filter))
        if (nodes[i].innerText.toLowerCase().includes(filter)) {
          nodes[i].style.display = "flex";
          nodes[i].style.setProperty("display", "flex", "important");
        } else {
          nodes[i].style.display = "none";
          nodes[i].style.setProperty("display", "none", "important");          
        }
      }
    }

  },
  computed: {
    filteredChats() {
     /*  if (this.search.trim().length > 0) {
        return this.chats.filter((res) =>
          res.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } */
      if (this.$route.fullPath == "/chat/university/inbox#unread") {        
        return this.chats.filter((res) => !res.is_read);
      }
      if (this.$route.fullPath == "/chat/university/inbox#archived") {
        return this.chats.filter((res) => res?.is_archived == true);
      }
      if (this.$route.fullPath == "/chat/university/inbox#favourites") {
        return this.chats?.filter((res) => res?.is_fav == true);
      }

      return this.chats;
    },
    chatsTitle() {
      if (this.$route.hash === "#archived") {
        return "Archived";
      }
      if (this.$route.hash === "#favourites") {
        return "Favourites";
      }
      if (this.$route.hash === "#unread") {
        return "Unread";
      }
      return "University Chats";
    },
  },
  mounted() {
   /*  this.chats = this.$globals.highschool
    this.initContact(this.chats)   */
    window.Echo.join('go-online-university')
    .here((users) => {

        this.chats = this.chats.map((user) => {      
            user.online = users.some((u) => u.id === user?.sender_id  && u.user_type !== 'admin');
            return user;
        });
    })
    .joining((user)=>{
      console.log(user,'join')
      this.chats = this.chats.map((u) => {
            if(user.id == u.sender_id  && user.user_type === 'highschool'){
              user.online = true;
            }
            return user;
        });
    }).leaving((user)=>{
      console.log(user,'left')
      this.chats = this.chats.map((u) => {
            if(user.id == u.sender_id  && user.user_type === 'highschool'){
              user.online = false;
            }
            return user;
        });
    })
    document.addEventListener("click", this.closeDetails2);
  },
};
</script>

<style lang="scss" scoped>
$primary: #fac515;
$warning: #fdb022;
$scrollbar-thumb-bg: #dc6803;
$scrollbar-color: #f79009;
$gray: #d0d5dd;

.chatListItem {
  padding-right: 5px;
  padding-left: 5px;
}
.chatListItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.chatListItem:hover > #chatListItem-inner {
  background-color: transparent !important;
}

.p-sidebar-sm li:hover {
  background-color: rgba(16, 24, 40, 0);
}

.p-inputtext {
  width: 100%;
  background-color: #e7e6e8 !important;
  text-align: center;
  outline: none !important;
  border: none !important;
}

.texteditor {
  width: 95%;
  margin: auto;
  padding-bottom: 20px;
  padding-top: 10px;
}

.paperclip {
  padding-left: 70px !important;
  font-size: 30px;
  padding-top: 70px !important;
  color: rgb(199, 204, 197);
}

.sendicon {
  padding-top: 80px !important;
  color: rgb(204, 197, 197);
}
.trackform-input {
  input {
    padding-right: 40px;
  }
  .form-btna {
    position: absolute;
    right: 10px;
    z-index: 5;
    padding: 4px;
    border: 1px solid #98a2b3;
    border-radius: 100%;
    font-size: 10px;
    background-color: #98a2b3;
    color: white;

    &:hover {
      background-color: #c0c8d4;
    }
  }
}
.active {
  background-color: $primary !important;
}

.search-btn {
  background-color: $gray;
  padding: 8px 24px;
}
.search-btn {
  font-size: 14px !important;
}

.filter-btn {
  background-color: $gray;
}
.text-xs {
  font-size: 0.85rem !important;
}
</style>
