<template>
  <div style="width: 90%" class="m-auto">
    <p class="mt-4 mb-1 fs2 fw-bold">Set Commission Type</p>
    <div style="height:1px;background:#ccc" class="w-100 mb-4"></div>
    <div class="d-flex " style="width:90%">
        <div class="me-4 w-50">
            <div>
              <RadioButton @change="$emit('updateCommission', commission)" v-model="commission.commission_type" inputId="commission1"  name="percentage" value="percentage" />
            </div>
            <label for="commission1" class="ml-2 fs1 mb-2 mt-1">Percentage Commission (%)</label>
            <span class="p-input-icon-left w-100 align-items-center">
              <i>%</i>
              <InputText @change="$emit('updateCommission', commission)" :disabled="commission.commission_type!=='percentage'" v-model="commission.commission_value" placeholder="Search" />
            </span>
        </div>
        <div class="ms-4 w-50">
            <div>
              <RadioButton @change="$emit('updateCommission', commission)"  v-model="commission.commission_type" inputId="commission2" name="fixed" value="fixed" />
            </div>
            <label for="commission2" class="ml-2 fs1 mb-2 mt-1">Fixed Commission</label>
            <span class="p-input-icon-left w-100 align-items-center">
              <i>$</i>
              <InputText @change="$emit('updateCommission', commission)" :disabled="commission.commission_type!=='fixed'" v-model="commission.commission_value" placeholder="Search" />
            </span>
        </div>     
    </div>

    <p  v-if="type ==='university'" class="mt-5 mb-1 fs2 fw-bold">Set Commission Type</p>
    <div  v-if="type ==='university'" style="height:1px;background:#ccc" class="w-100 mb-4"></div>
 
    <div v-for="(block,i) in commission.blocks" :key="i" class="mb-4 row rounded px-0 pb-3 border">
      <div style="background:#eee;place-content: flex-end;" class="rounded-top mb-3 col-12 p-2 d-flex align-items-center">
        <button @click="commission.blocks.splice(i,1)" class="btn btn-sm btn-light"><span style="fill:red;" v-html="$globals.icons.delete" class="me-2"></span>Delete</button>
      </div>
        <div class="inputGroupContainer col-6 d-flex flex-column align-items-start ">
          <!--Students -->
          <label style="font-weight: 600 !important" class="mb-1"
            >Number of Students <span class="text-danger">*</span></label
          >
          <InputText
              v-model="block.number_of_students"
              @change="$emit('updateCommission', commission)"
              style="padding-left: 45px; width: 100%;"
              class="inputTypeText"
              type="number"
            />
        </div>
        <div class="inputGroupContainer col-6 d-flex flex-column align-items-start">      
          <label style="font-weight: 600 !important" class="mb-1"
            >Semester Type</label>
          <DropDown v-model="block.semester_name" @change="$emit('updateCommission', commission)"
            :options="['Fall Semester','Spring Semester','Summer Semester','Winter Semester']"
            placeholder="Select Semester"
            class="w-100"
          />
        </div>
        <div style="margin-top: 24px !important" class="inputGroupContainer col-6 d-flex flex-column align-items-start">    
          <label style="font-weight: 600 !important" class="mb-1" >Percentage Earnings (%)<span class="text-danger">*</span></label
          >
          <div class="d-flex align-items-center position-relative w-100">
            <span
              style="width: 40px; background-color: rgba(0, 0, 0, 0.3)"
              class="position-absolute h-100 d-flex align-items-center justify-content-center"
            >
              <i class="pi pi-dollar text-white fw-bold"></i>
            </span>
            <InputText v-model="block.percentage" @change="$emit('updateCommission', commission)" style="padding-left: 45px; width: 100%;"
              class="inputTypeText" type="number"
            />
          </div>
        </div> 
    </div>
    <div  v-if="type ==='university'" class="d-flex justify-content-center align-items-center mt-3">
      <span @click="insertBlock" class="d-inline-block" style="cursor:pointer">
        <span v-html="$globals.icons.add" class="mr-2"></span> <span class="fs2 fw-bold">Add new comission target</span>
      </span>
    </div>
  </div>
</template>


<script>
import RadioButton from 'primevue/radiobutton';

export default {
  name: "CommissionTab",
  props: {
    commission_type:{
      default:''
    },
    commission_value:{
      default:''
    },
    blocks:{
      default:[]
    },
    type:{
      default:'university'
    }
  },
  components: { RadioButton },
  data() {
    return {
      commission:{
        commission_value:0,
        commission_type:'percentage',
        blocks: [        
        ],
      }
    };
  }, 
  methods: {
    insertBlock() {
      this.commission.blocks.push({
            id: 1,
            semester_name: "",
            number_of_students: "",
            percentage: 0
          });
    },
  },
  created(){
    this.commission ={
        commission_value:this.commission_value,
        commission_type:this.commission_type,
        blocks: this.blocks
      }
  }
};
</script>
<style scoped>
.fs1{
  font-size:0.8em !important;
}
.fs2{
  font-size:0.9em !important;
}
input.p-inputtext.p-component.p-filled {
    width: 100% !important;
}
.p-input-icon-left > i:first-of-type {
    left: 0.5px !important;
    color: #6c757d;
    background: #ccc;
    width: 26px;
    height: 40px;
    top: 22%;
    display: inline-block;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>