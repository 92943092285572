<template>
  <div>
    <div>
      <!-- Header -->
      <h1>{{ user.username }}</h1>
      <p>{{ user.email }}</p>
    </div>
    <hr style="opacity: 1 !important; border: 1px solid black" />
    <div>
      <div
        style="max-width: 730px"
        class="d-flex align-items-center justify-content-between"
      >
        <!-- Fırst Line Inputs -->
        <div class="inputGroup">
          <label for="username">Username (Required)*</label>
          <input
            disabled
            :value="user.username"
            id="username"
            type="text"
            placeholder="Enter The User Name"
          />
        </div>
        <div class="inputGroup">
          <label for="email">Email (Required)*</label>
          <input
            disabled
            :value="user.email"
            id="email"
            type="text"
            placeholder="Enter The Email"
          />
        </div>
      </div>
      <div
        style="max-width: 730px"
        class="d-flex align-items-center justify-content-between"
      >
        <!-- Second Lıne Inputs -->
        <div class="inputGroup">
          <label for="username">First Name</label>
          <input disabled :value="user.firstName" id="firstName" type="text" />
        </div>
        <div class="inputGroup">
          <label for="username">Last Name</label>
          <input disabled :value="user.lastName" id="lastName" type="text" />
        </div>
      </div>
      <div style="max-width: 730px; margin-top: 2rem">
        <!-- Upload Photo -->
        <label>Upload Photo</label>
        <div
          style="padding: 24px; border: 1px dashed #4d5056; border-radius: 12px"
          class="d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <img
              src="@/assets/images/users-and-roles/uploadImg.svg"
              alt="upload"
            />
            <div class="ms-3 d-flex flex-column justify-content-center">
              <p class="m-0 mb-2">170px by 170px . Max 5MB</p>
              <p class="m-0">High resolution images (png, jpg)</p>
            </div>
          </div>
          <div>
            <button
              style="background-color: rgba(234, 236, 240, 1)"
              class="border px-5 py-2"
            >
              Browse
            </button>
          </div>
        </div>
      </div>

      <div style="margin-top: 2rem">
        <!-- Role Select -->
        <label>Assign Role</label>
        <p>
          The user will have access to the selected roles. You can selected
          multiple roles, and also restrict the permissions from the role page.
        </p>

        <div class="d-flex flex-column mt-5">
          <label>Roles</label>
          <div>
            <span
              v-for="role in user.role"
              :key="user.role.indexOf(role)"
              class="roleBadge"
              >{{ role }}</span
            >
          </div>
        </div>
      </div>
      <div style="margin-top: 2rem">
        <h4
          style="
            margin-left: 10%;
            display: inline;
            padding: 8px;

            border-bottom: 4px solid rgba(117, 117, 117, 1);
          "
          class=""
        >
          Activity
        </h4>
        <hr class="mt-2" />
        <div id="activitiesContainer">
          <!-- Activities -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { users } from "@/data";
export default {
  name: "ViewUser",
  data() {
    return {
      user: users.find((tempuser) => tempuser.id == this.$route.params.userId),
    };
  },
};
</script>

<style scoped>
.inputGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1.5rem;
}
.inputGroup:nth-of-type(2) {
  margin-left: 2rem;
}

label {
  font-weight: 600;
  margin-bottom: 4px;
}
input {
  border: 0.5px solid #000000;
  border-radius: 4px;
  height: 40px;
  padding-left: 0.5rem;
}
input[disabled] {
  background-color: rgba(77, 80, 86, 0.4);
}

.roleBadge {
  background-color: rgba(225, 227, 232, 1);
  border-radius: 16px;
  font-weight: 500;
  color: rgba(37, 48, 65, 1);
  padding: 5px 10px;
  display: inline-block;
  font-size: 12px;
  width: fit-content;
  margin: 5px;
}
.roleBadge:first-of-type {
  margin-left: 0;
}
.roleBadge:last-of-type {
  margin-right: 0;
}
#activitiesContainer {
  box-shadow: 0px 2px 10px rgba(58, 53, 65, 0.1);
  border-radius: 6px;
  padding: 20px;
  width: 90% !important;
  margin: auto;
}
</style>
