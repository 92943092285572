<template>
  <div style="width: 730px; margin: auto; margin-top: 41px" class="d-flex flex-column">
    <div v-if="highSchoolProp" class="inputGroupContainer d-flex flex-column align-items-start position-relative">
      <!-- High School ID -->
      <label style="font-weight: 600 !important" class="mb-1">High School Id</label>
      <i style="
          position: absolute;
          bottom: 7px;
          right: 5px;
          font-size: 2rem;
          opacity: 0.5;
        " class="pi pi-lock"></i>

      <input disabled class="inputTypeText" type="text" :value="highSchoolProp.unique_id"
        placeholder="Please Write High School Name" />
    </div>
    <div style="margin: auto !important; width: 100%" class="w-100">
      <!-- Upload Cover Photo -->
      <label class="fw-bold mb-1">Upload Cover Photo <span class="text-danger"> * </span></label>
      <div style="
          height: 128px !important;
          border: 1px dashed #4d5056;

          border-radius: 12px;
        " class="px-3 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div>
            <img style="width: 80px; border-radius: 5px" class="me-3" :src="highSchool.cover_photo_url != ''
                ? highSchool.cover_photo_url
                : require('@/assets/images/universities-list/upload-svg.svg')
              " alt="" id="upload-img" />
          </div>
          <div class="d-flex m-auto flex-column align-items-start">
            <p style="color: rgb(77, 80, 86) !important">
              960px by 960px . Max 5MB
            </p>
            <p style="margin: 0; color: rgb(77, 80, 86) !important">
              High-resolution images (png, jpg)
            </p>
          </div>
        </div>
        <div>
          <form className="">
            <label className="custom-file-upload" id="file">
              <input accept=".jpg, .jpeg, .png" @change="showFile($event, 'cover')" type="file" id="file-input" />
              Browse
            </label>
          </form>
          <button @click="openFileInput('file-input')" class="border py-2 px-5">Browse</button>
        </div>
      </div>
    </div>
    <div style="width: 100%" class="w-100 mt-4">
        <!-- Upload Cover Photo -->
        <label class="fw-bold mb-1">Upload Logo <span class="text-danger"> * </span></label>
        <div style="
            height: 128px !important;
            border: 1px dashed #4d5056;

            border-radius: 12px;
          " class="px-3 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div>
              <img class="me-3" style="width: 80px; border-radius: 12px" :src="highSchool?.logo != ''
                ? highSchool?.logo
                : require('@/assets/images/universities-list/upload-svg.svg')
                " alt="" id="upload-img" />
            </div>
            <div class="d-flex m-auto flex-column align-items-start">
              <p style="color: rgb(77, 80, 86) !important">
              200px by 200px . Max 200kb
              </p>
              <p style="margin: 0; color: rgb(77, 80, 86) !important">
                High-resolution images (png, jpg)
              </p>
            </div>
          </div>
          <div>
            <form className="">
              <label className="custom-file-upload" id="file">
                <input accept=".jpg, .jpeg, .png" @change="showFile($event, 'logo')" type="file" id="file-input2" />
                Browse
              </label>
            </form>
            <button class="border py-2 px-5" @click="openFileInput('file-input2')">Browse</button>
          </div>
        </div>
      </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">High School Name</label>
      <input class="inputTypeText" type="text" v-model="highSchool.name" placeholder="Please Write High School Name" />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!--High School Type -->
      <label style="font-weight: 600 !important" class="mb-1">High School Type</label>
      <DropDown v-model="highSchool.type" :options="highSchoolTypes" placeholder="Please Select High School Type"
        class="w-100" />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- Registered Date -->
      <label style="font-weight: 600 !important" class="mb-1">Registered Date</label>

      <div style="border-radius: 8px; overflow: hidden; width: 50%" class="position-relative">
        <CalendarComponent style="width: 100%" v-model="highSchool.registered_date" />
        <i style="
            background-color: rgb(253, 176, 34);
            position: absolute;
            height: 100%;
            right: 0;
            display: flex;
            align-items: center;
            font-size: 1.75rem;
            top: 0;
            bottom: 0;
            padding: 5px;
            color: black;
          " class="pi pi-calendar"></i>
      </div>
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- Registered Email Address -->
      <label style="font-weight: 600 !important" class="mb-1">Registered Email Address</label>
      <input class="inputTypeText" type="email" v-model="highSchool.email" @focusout="validateEmail"
        placeholder="Please Write Email Address" />
      <p v-if="showWarning" class="text-danger pt-2 mb-0">Please Enter A Valid Email</p>
      <p v-if="showIsValid" class="text-success pt-2 mb-0">Valid Email</p>
    </div>
    <div class="d-flex justify-content-between">
      <div class="inputGroupContainer d-flex flex-column align-items-start me-3 w-100">
        <!-- First Name -->
        <label style="font-weight: 600 !important" class="mb-1">First Name</label>
        <input class="inputTypeText" type="text" v-model="highSchool.first_name" placeholder="Please Write First Name" />
      </div>
      <div class="inputGroupContainer d-flex flex-column align-items-start w-100 ms-3">
        <!-- Last Name -->
        <label style="font-weight: 600 !important" class="mb-1">Last Name</label>
        <input class="inputTypeText" type="text" v-model="highSchool.last_name" placeholder="Please Write Last Name" />
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <!-- user auto generated ID -->
      <!--   <div
        class="inputGroupContainer d-flex flex-column align-items-start me-5 w-100"
      >        
        <label style="font-weight: 500 !important" class="mb-1"
          >User Auto Generated ID</label
        >
        <div class="position-relative w-100">
          <input
            class="inputTypeText"
            type="text"
            v-model="highSchool.unique_id"
            readonly
          />
          <button
            style="
              right: 2px;
              height: 90%;
              top: 0;
              bottom: 0;
              border-radius: 8px !important;
              margin: auto !important;
            "
            @click="generateNumber('id')"
            class="border px-2 bg-dark text-light position-absolute"
          >
            Auto Generate
          </button>
        </div>
      </div> -->
      <!--   <div
        class="inputGroupContainer d-flex flex-column ms-5 align-items-start w-100"
      >        
        <label style="font-weight: 500 !important" class="mb-1"
          >First time login Password</label
        >
        <div class="position-relative w-100">
          <input
            class="inputTypeText"
            type="text"
            v-model="highSchool.password"
            readonly
          />
          <button
            style="
              right: 2px;
              height: 90%;
              top: 0;
              bottom: 0;
              border-radius: 8px !important;
              margin: auto !important;
            "
            @click="generateNumber('password')"
            class="border px-2 bg-dark text-light position-absolute"
          >
            Auto Generate
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { showModal } from "@/plugins/modal";
import { postFormData } from "@/api/client";
export default {
  props: ["highSchoolProp"],
  name: "HighSchoolInformationTab",
  components: ["CalendarComponent"],
  data() {
    return {
      registeredDate: "01/01/2023",
      autoGeneratedId: "12345",
      autoGeneratedPassword: "123123",
      selectedHighSchoolType: "",
      highSchoolTypes: ["Public", "Private"],
      email: "",
      coverPhoto: "",
      highSchoolName: "",
      firstName: "",
      lastName: "",
      showWarning: false,
      showIsValid: false,
      isEmailValid: false,
      highSchool: {
        unique_id: "",
        name: "",
        type: "",
        registered_date: "",
        email: "",
        first_name: "",
        last_name: "",
        cover_photo_url: "",
        password: "",
      },
    };
  },
  watch: {
    highSchool: {
      deep: true, // Watch for nested changes within each block
      handler(newData) {
        this.$emit("onUpdateInfo", newData);
        // Handle changes to the blocks array
      },
    },
  },
  methods: {
    generateNumber(eventType) {
      if (eventType == "id") {
        this.highSchool.unique_id = Math.floor(Math.random() * 9000000);
      } else if (eventType == "password") {
        this.highSchool.password = Math.floor(Math.random() * 10000000);
      }
    },
    openFileInput(id) {
      document.getElementById(id).click();
    },
    readFile(e) {
      var files;
      if (e.target.files) {
        files = e.target.files;
      } else {
        files = e.dataTransfer.files;
      }
      if (files.length == 0) {
        showModal("What you dropped is not a file.");
        return;
      }
      var file = files[0];
      document.getElementById(
        "file"
      ).textContent = `${file.name}| ${file.type}`;
      const reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById("file-input").value = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getTheFile(e) {
      e.preventDefault();
      this.readFile(e);
    },
    dragOver(e) {
      e.currentTarget.classList.add("blue-bg");
      e.currentTarget.style.color = "white";
      e.stopPropagation();
      e.preventDefault();
    },
    dragLeave(e) {
      e.currentTarget.classList.remove("blue-bg");
    },
    async showFile(e, type) {
      const file = e.currentTarget.files;
      if (file) {
        const formData = new FormData();
        formData.append("file", file[0]);
        formData.append("folder_name", "highSchoolProfile");
        const res = await postFormData("file_upload", formData, "admin");
        if(type=='cover'){
          this.highSchool.cover_photo_url = res;
        }else{
          this.highSchool.logo = res;
        }
     /*    const uploadImg = document.getElementById("upload-img");
        uploadImg.style.width = "80px";
        uploadImg.style.height = "80px";
        uploadImg.src = res;
        uploadImg.style.borderRadius = "12px"; */
        // const data = new FormData();
        // data.set("cover_photo_url", e.currentTarget.files[0]);
        // this.coverPhoto = file;
      }
    },
    uploadImgToCloud() { },
    validateEmail(e) {
      var email = e.currentTarget.value;
      var regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.trim() == "" || !regex.test(email)) {
        this.showWarning = true;
        setTimeout(() => {
          this.showWarning = false;
        }, 1500);
      } else {
        this.showIsValid = true;
        this.isEmailValid = true;
        this.$emit("validate-email", this.isEmailValid);
        setTimeout(() => {
          this.showIsValid = false;
        }, 1500);
      }
    },
  },
  created() {
    if (this.highSchoolProp) {
      let highSchool_obj = {
        ...this.highSchoolProp,
        ...this.highSchoolProp.employee,
      };
      Object.keys(this.highSchool).forEach((key) => {
        this.highSchool[key] = highSchool_obj[key];
      });
      this.highSchool.id = highSchool_obj.id;
    }
  },
  unmounted() {
    // this.$emit("validate-email", this.isEmailValid);
  },
};
</script>

<style scoped>
.inputGroupContainer {
  margin-top: 32px;
}

.inputTypeText {
  width: 100%;
  padding: 10px 14px 10px 12px;
  border: 0.5px solid #000000;
  border-radius: 4px;
}

button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: none !important;
  text-decoration: underline;
  color: #0017ff;
  cursor: pointer;
}
</style>
