<template>    
    <div class="pdf-container" style="overflow: auto;width:100%;height:100%;">
        <div class="ps-5 border-bottom w-100 d-flex justify-content-between align-items-center" style="overflow: auto;">
            <div class="d-flex align-items-center">    
                <div @click="currentTab =1" style=" color: rgb(29, 41, 57); cursor: pointer; border-bottom: 4px solid transparent; font-size:13px; " class="me-5 p-3" :style="currentTab === 1 && 'border-bottom: 3px solid #bbb; font-weight: 600' ">
                    Upload Signature
                </div>
                <div @click="currentTab =2" style="cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); font-size:13px; " :style="currentTab === 2 && 'border-bottom:  3px solid #bbb; font-weight:600' " class="p-3"> 
                    All Signature
                </div>
            </div>
            <button  :class="{'d-inline':selectedLetterIndex !=null,'d-none':selectedLetterIndex ==null}" @click="selectedLetterIndex=null" class="btn btn-warning btn-sm">Back to Upload Signature</button>
      </div>
      <div :class="{'d-flex':currentTab === 1,'d-none':currentTab !== 1}" class="container"   @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <div  class="container" >                      
                <div style="min-height:360px; width:100%" >
                    <!-- previewing pdf of uploads -->
                    <div v-show="selectedLetterIndex===null" class="row">
                        <div  v-for="(letter, x) in letters" :key="x" :style="'padding: 10px;border: 1px dashed #aaa;  margin: 20px 10px;'" class="position-relative col-md-3 d-flex flex-column align-items-center"> 
                            <canvas  @click="selectLetter(x)"  :ref="'prevCanvas_' + x" style="width:160px;border:1px solid #aaa;" class="mx-auto" height="200"></canvas>
                            <button @click="deleteLetter(x,letter)" class="btn btn-secondary btn-sm position-absolute text-dark px-2" style="right: 0px;top: 0px;">Delete</button>                                                        
                            <p class="text-center">
                                <span style="font-size:0.85em" class="text-center">{{ letter.pages }} Pages</span>
                            </p>
                        </div>
                    </div> 
                    <div v-show="selectedLetterIndex !=null"  style="border-bottom:1px solid #aaa;">
                        <div :class="{'d-flex':loadingStatus == 100,'d-none':loadingStatus < 100}" class="w-100">                        
                            <div style="padding: 10px;height: 63vh;width: 20%;">
                                <div style="height: 80%;width: 100%;overflow-y: auto;">
                                    <div v-for="(page, pageIndex) in pdfPages" @click="selectPage(pageIndex)"  :key="pageIndex" :style="{
                                            background: pageIndex === selectedPageIndex ? $globals.colorsHex.warningLight : '',padding: '10px',border: '1px solid ' + $globals.colorsHex.warning,width: '100%',margin: '20px 0px',cursor:'pointer'}">
                                        <canvas :ref="'pagePrevCanvas_' + pageIndex" style="width:100%;" height="200" ></canvas>
                                    </div>                                                         
                                </div>
                                <div>
                                    <button @click="activateSign = true"  class="btn btn-white my-2 btn-sm w-100" :style="'border:1px solid' +$globals.colorsHex.warning">Signature</button>
                                    <button class="btn btn-white btn-sm w-100" :style="'border:1px solid' +$globals.colorsHex.warning">Stamp</button>
                                </div>
                            </div>
                            <div  style="width:95%;border-left: 1px solid #aaa;">     
                                <br/><br/>                       
                                <div  style="width:90%;overflow-x: auto;margin-left:auto;margin-right:auto;line-hti">     
                                    <div class="p-0 position-relative mx-auto" style="width:700px" >
                                        <canvas id="pageCanvasRef" style="width:100%;"></canvas>
                                        <div v-for="(sign, y) in getSigns" :key="'y_'+y"  :style="'position:absolute;top:'+sign.pos_y+'px;left:'+sign.pos_x+'px'">
                                            <div v-if="selectedPageIndex+1 == sign.page">
                                                <button @dblclick="letters[selectedLetterIndex].signs.splice(y,1)" class="btn btn-sm btn-secondary shadow">Signature Placeholder</button>
                                            </div>                                        
                                        </div>
                                        <div @dblclick="attachSignature" v-show="activateSign" ref="draggableresizableimage" class="ui-widget-content" style="position:absolute;top:0px;padding: 15px">
                                            <button  ref="signature" id="signature" class="btn btn-sm btn-secondary">Signature Placeholder</button>                                        
                                            <div class="l ui-resizable-handle ui-resizable-w resizable-wrapper" id="w"></div>
                                            <div class="r ui-resizable-handle ui-resizable-e resizable-wrapper" id="e"></div>
                                            <div class="tl ui-resizable-handle ui-resizable-nw" id="nw"></div>
                                            <div class="tr ui-resizable-handle ui-resizable-ne" id="ne"></div>
                                            <div class="bl ui-resizable-handle ui-resizable-sw" id="sw"></div>
                                            <div class="br ui-resizable-handle ui-resizable-se" id="se"></div>
                                            <!-- <button  class="position-absolute btn bg-white" style="color:rgb(255, 150, 14); border:1px solid rgb(255, 150, 14); left:0px;white-space:nowrap; bottom:-35px">Insert Here</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="loadingStatus <100">
                            <CircleProgress :value="loadingStatus"></CircleProgress>
                        </div>
                    </div>
                </div>
                <!-- upload section -->
                <div :class="{'d-flex':selectedLetterIndex ==null,'d-none':selectedLetterIndex !=null}" class="mx-auto  justify-content-center align-items-center" :style="'border:1px solid '+$globals.colorsHex.warning+';background:'+$globals.colorsHex.warningLight+';border-radius:20px;padding:25px;width:80%;height:inherit;' ">
                    <p v-if="isDragging" style="border:2px dashed #bbb; border-radius:10px; height:inherit;" class="w-100 d-flex justify-content-center align-items-center">Drop the files here ...</p>
                    <div v-else class="w-75 text-center">
                        <span v-html="$globals.icons.cloud"></span>
                        <p class="m-0">Please click to select documents or drop them over here </p>
                        <p class="m-0">You can only upload files up to 10 MB in size each from the following types: PDF, Docx. </p>
                    </div>
                    <input type="file"  @change="onChange" ref="file" accept=".pdf" style="display:none">
                </div>
            
        </div>       
      </div>
      <div :class="{'d-flex':currentTab === 2,'d-none':currentTab !== 2}" >
        <div v-if="savedLetters.length<1" class="d-flex flex-column align-items-center justify-content-center text-center w-100" style="height:60vh; align-items:center; justify-content:center">
            <span v-html="$globals.icons.noDocs"></span>
            <h1 class="mt-3">No file found</h1>
            <p class="m-0">It seems we can’t find any file.</p>
        </div>
        <div v-else class="w-100">            
          <div  v-for="(lett, l) in savedLetters" :key="l" class="d-flex flex-column align-items-center my-2">
            <LetterCard  @view="OpenLetterCard($event,l, lett,'prevCanvasFinal_' + l)" :letter="lett"></LetterCard>
            <div v-show="lett?.show"  class="w-100  bg-white">                          
              <div  class="d-flex pt-3 flex-column align-items-center  bg-white w-75" style="margin-left:11%">
                <div  @dblclick="preview(lett)" :style="'padding: 10px;border: 1px dashed #aaa;  margin: 20px 10px;'" class="position-relative col-md-3 d-flex flex-column align-items-center"> 
                  <canvas :ref="'prevCanvasFinal_' + l" style="width:160px;border:1px solid #aaa;" class="mx-auto" height="200"></canvas>
                  <p class="text-center">
                    <span style="font-size:0.85em" class="text-center">{{ lett.pages }} Pages</span>
                  </p>
                </div>
                <div class="mb-3">
                  <button @click="updateStatus(lett,'approved')" :disabled="lett.status === 'approved'" class="btn mx-2 btn-secondary btn-sm text-dark px-2" >Aprove Document</button>
                  <button @click="updateStatus(lett,'rejected')" class="btn mx-2 btn-secondary btn-sm text-dark px-2" >Reject Document</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- <button @click="submitSignedPdf">Submit</button> -->
      <div v-if="showModal">
        <div class="modal-overlay" @click="closeModal"></div>
        <div class="modal-custom">
          <div class="modal-header">
            <button class="modal-close" @click="closeModal">X</button>
          </div>
          <div class="modal-body">
              <PdfPreview :url="prevUrl"></PdfPreview>
          </div>
        </div>
      </div>
    </div>
   
  </template>
    
  <script>
  
  import * as pdfjsLib from "pdfjs-dist";
  import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";  
  import jquery from "jquery";
  import "jquery-ui-dist/jquery-ui";
  import { showModal } from "@/plugins/modal";
  import { postFormData, post } from '@/api/client';
  import CircleProgress from '@/components/Global/CircleProgress.vue'
  import LetterCard from '@/components/UniversitiesList/LetterCard.vue'
  import PdfPreview from '@/components/UniversitiesList/PdfPreview.vue'
  const $ = jquery;
  let pdfDocument = null;
  export default {
    props:{documents:{default:[]}},
    components: {
        CircleProgress,
        LetterCard,
        PdfPreview
    },
    data() {
      return {     
        prevUrl:'',
        loadingStatus:0,
        selectedPageIndex:0,   
        previewing:false,
        currentTab:1,
        letters:[],
        savedLetters:[
            {
                owner_id:21,
                platform:'university',
                receiver_status:'accepted',
                status:'rejected', 
                signed_date:'20-08-2023',
                approval_date:'20-08-2023',
                name:'agreement letter',
                url:"blob:http://localhost:8080/f929c03f-b7c3-483c-a893-173df4f9caec",                
                template:"blob:http://localhost:8080/f929c03f-b7c3-483c-a893-173df4f9caec",                
                signs:[{"pos_x":135,"pos_y":194,"page":1}],            
                pages:4,
                created_at:'20-08-2023'
            }
        ],
        selectedLetterIndex:null,
        pdfUploadActive:false,
        isDragging: false,
        pdfUrl:null,
        pdfPages: [], // Array to store loaded PDF pages
        signaturePosition: null,
        selectedPage: '',
        showModal: false,
        activateSign:false,
        showTextFrame:false,
        text: {
          value: "Your text here",
          fontSize: 14, 
          x:0,
          y:0,
          w:0,
          h:0
        },
        sign: {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
          file: null,
        },
        pdfScale: 3,
        userInfo:JSON.parse(localStorage.getItem('userInfo')||'{}'),
        canvasHistory: [], // Array to store canvas states
        canvasIndex: -1, // Current index in canvas history
      };
    }, 
    methods: {
      preview(letter){
        this.showModal = true
        this.prevUrl = letter?.url || letter.template ;
      },
      async updateStatus(letter, status ){        
         let res  = await post('upadate_document',{id:letter.id,status},'admin',false)
         console.log(res,32232)
         if(res?.message){
           letter.status = status;
           showModal({text:'Document status updated'})
         }  
      },
      OpenLetterCard(e, x, letter, ref){        
        letter.show = e
        this.selectLetter(x,letter?.template || letter.url,ref)
      },  
        async selectLetter(x, url=null, ref=null){
            if(url === null){
              this.selectedLetterIndex = x            
              url = this.letters[x].template
            }
            
            try{          
                await this.loadPdf(url);
                
                    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

                    if(ref !==null){                      
                      await this.initiatOnePage(1, ref, 0.3);
                      return false
                    }

                    this.loadingStatus = 0; 
                    setTimeout(async ()=>{
                        for (let n = 0; n < this.pdfPages.length; n++) {
                            await this.initiatOnePage(n + 1, 'pagePrevCanvas_' + n, 0.3);

                            if (n === 0) {
                            await this.initiatOnePage(1, 'pageCanvasRef', this.pdfScale);
                            }
                            //update loading status                           
                            this.loadingStatus = Math.ceil(((n + 1) / this.pdfPages.length) * 100);
                            await new Promise(resolve => setTimeout(resolve, 100)); // 100ms delay
                        }
                    },1000)
                }catch(e){      
                  console.log(e) ;
                    showModal({text:'Invalid letter'})
                }   
        },
        async selectPage(pageIndex){
            if(this.selectedPageIndex != pageIndex){
                this.selectedPageIndex = pageIndex
                pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;        
                this.initiatOnePage(pageIndex+1, 'pageCanvasRef', this.pdfScale)
            }
        },
        async initiatOnePage(pageIndex, canvasRef, scale= 0.4){      
                                       
           await pdfDocument.getPage(pageIndex).then(async (page) => {               
                let canvas;
                if (canvasRef === 'pageCanvasRef') {
                    canvas = document.getElementById('pageCanvasRef'); // Corrected typo here
                } else {
                    canvas = this.$refs[canvasRef][0]; 
                }

                const context = canvas.getContext("2d");        
                const viewport = page.getViewport({ scale: scale });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({
                canvasContext: context,
                viewport,
                enableWebGL: false,
                });
                return true;
            })  
            return true;
        },
        async deleteLetter(index,letter) {
            // Remove the canvas element from the DOM
            let res = await post('delete_uploaded_file',{url:letter.template},'admin',false); 
            if(!res){
              return false;
            }

            const canvasRef = this.$refs['prevCanvas_' + index];
            if (canvasRef) {
                canvasRef[0].remove();
            }

            // Revoke the URL associated with the letter's canvas
            if (this.letters[index]?.template) {
                URL.revokeObjectURL(this.letters[index].template);
            }

            // Remove the letter from the array
            this.letters.splice(index, 1);
        },   
        async onChange() {        
          let name = await showModal({ 
            text:'Enter Letter Name',
            input:true,
            cancelText:"Cancel"
          });
          if(!name){
            return false
          }
          const formData = new FormData();
          formData.append("file", this.$refs.file.files[0]);
          formData.append("folder_name", "AdminUploadedLetters");
          let res = await postFormData('file_upload',formData, 'admin')           
          if(!res){
            showModal({
              text:'check your network and try again'
            })
            return false;            
          }
          this.pdfUrl = res 
          //this.pdfUrl = URL.createObjectURL(this.$refs.file.files[0]);             
            this.letters.push({
                name: name,
                url:null,                
                template:this.pdfUrl,                
                signs:[],
                pages:0,
            })
            this.$emit('updateLetter',this.letters)
            setTimeout(async()=>{          
                try{          
                    for(const index in this.letters){
                        const letter = this.letters[index];
                        await this.loadPdf(letter.template);
                        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;      
                        this.initiatOnePage(1,"prevCanvas_" + index)
                        this.letters[this.letters.length - 1].pages = this.pdfPages.length;
                    }                     
                    this.pdfUploadActive = true;
                }catch(e){                
                    showModal({text:'Agreement let yet to be sent'})
                }   
            }, 1000)                          
        },
        async loadPdf(url) {        
        
            try{
                 pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
                    const loadingTask = pdfjsLib.getDocument({
                    url:url,
                });
                
                pdfDocument = await loadingTask.promise;       
                this.pdfPages = Array.from(
                    { length: pdfDocument.numPages },
                    (_, pageIndex) => pdfDocument.getPage(pageIndex + 1)
                    );    
        }catch(e){
            console.log(e)
        }
      },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },    
    async handleCanvasClick(pageIndex) {
        this.selectedPage = pageIndex;      
        },
    
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          this.$refs.signature.src = URL.createObjectURL(file);
          this.sign.file = URL.createObjectURL(file);
          this.activateSign = true
          this.sign.w = 100;
          this.sign.h = 100;
        }
      },
      importSign(data) {
        this.$refs["signature"].src = data;
        this.sign.w = 100;
        this.sign.h = 100;
        this.sign.file = data;
        this.showModal = false;
        this.activateSign = true
      },
      closeModal() {
        this.showModal = false;
        this.doc = "";
      },
      
      async attachText() {
        //const canvas =  document.getElementById('pageCanvasRef');
        this.letters[this.selectedLetterIndex].signs.push({
            pos_x: this.sign.x,
            pos_y: this.sign.y,
            page:this.selectedPageIndex+1
        })        
        this.activateSign = false  
      },
      async attachSignature(){
        this.letters[this.selectedLetterIndex].signs.push({
            pos_x: this.sign.x,
            pos_y: this.sign.y,
            page:this.selectedPageIndex+1
        })     
        this.$emit('updateLetter',this.letters)   
        this.activateSign = false           
      },    
    },
  
    async created() {
      this.savedLetters = this.documents
    },
    mounted() {
      this.$nextTick(() => {
        $(this.$refs.draggableresizableimage).resizable({
          handles: {
            nw: ".tl",
            ne: ".tr",
            sw: ".bl",
            se: ".br",
            e: ".r",
            w: ".l",
          },
          minHeight: 70,
          minWidth: 100,
          autoHide: true,
          stop: (event, ui) => {
            // This function is called when dragging stops
            this.sign.w = ui.size.width;
            this.sign.h = ui.size.height;
          },
        });
       
        $(this.$refs.draggableresizableimage).draggable({
          stop: (event, ui) => {    
            const canvas =  document.getElementById('pageCanvasRef');
            const canvasOffset = $(canvas).position();
            const canvasLeft = canvasOffset.left;
            const canvasTop = canvasOffset.top;   
            this.sign.x = ui.position.left - canvasLeft +15;
            this.sign.y = ui.position.top - canvasTop+15;
          },
        });
       
      });
    },
    computed:{
        getSigns(){
            if(this.selectedLetterIndex != null){
                return this.letters[this.selectedLetterIndex]?.signs
            }
            return []
        }
    },
  
  };
  </script> 
    
  <style scoped>
  .pdf-container {
   
  }
  
  .pdf-viewer {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }
  
  .page-thumbnail {
    margin: 10px;
  }
  
  container {
    padding: 1em;
    border: 1px solid lightgrey;
    margin: 1em;
    border-radius: 4px;
  }
  
  input[type="button"], button {
    background-color: lightgray;
    border: 0;
    border-radius: 4px;
    padding: 0.5em 2em;
  }
  
  #myFileInput {
    display: none;
  }
  
  .label {
    display: block;
    margin-top: 0.5em;
  }
  
  .l {
    left: -4px;
    top: calc(50% - 10px);
    cursor: w-resize;
  }
  
  .r {
    right: -4px;
    top: calc(50% - 10px);
    cursor: e-resize;
  }
  
  .tl {
    left: -6px;
    top: -6px;
    cursor: nw-resize;
  }
  
  .tr {
    right: -6px;
    top: -6px;
    cursor: ne-resize;
  }
  
  .bl {
    left: -6px;
    bottom: -6px;
    cursor: sw-resize;
  }
  
  .br {
    right: -6px;
    bottom: -6px;
    cursor: se-resize;
  }
  
  .ui-resizable-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    background: rgb(255, 0, 106);
    border-width: 1.5px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 999px;
  }
  
  .resizable-wrapper {
    width: 8px;
    height: 16px;
  }
  
  .ui-widget-content {
    cursor: move;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 101;
  }
  
  .modal-custom {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 5px;
    z-index: 5000;
  }
  
  .modal-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-close {
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-body {
  height: 80vh;
    background: white;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  .modal-overlay::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(3px);
  }
  .progress-circle {
   font-size: 20px;
   margin: 20px;
   position: relative; 
   padding: 0;
   width: 5em;
   height: 5em;
   background-color: #F2E9E1; 
   border-radius: 50%;
   line-height: 5em;
  }
  
  .progress-circle:after{
    border: none;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 4.3em;
    height: 4.3em;
    background-color: white;
    content: " ";
  }
  
  .progress-circle span {
    position: absolute;
    line-height: 5em;
    width: 5em;
    text-align: center;
    display: block;
    color: #53777A;
    z-index: 2;
  }
  .left-half-clipper {    
   border-radius: 50%;
   width: 5em;
   height: 5em;
   position: absolute; 
   clip: rect(0, 5em, 5em, 2.5em); 
  }
  
  .progress-circle.over50 .left-half-clipper {
   clip: rect(auto,auto,auto,auto);
  }
  .value-bar {   
   position: absolute; 
   clip: rect(0, 2.5em, 5em, 0);
   width: 5em;
   height: 5em;
   border-radius: 50%;
   border: 0.45em solid #53777A; 
   box-sizing: border-box;
  
  }
  
  .progress-circle.over50 .first50-bar {
  
   position: absolute; 
   clip: rect(0em, 5em, 5em, 2.5em);
   background-color: #53777A;
   border-radius: 50%;
   width: 5em;
   height: 5em;
  }
  
  /* Progress bar rotation position */
  .progress-circle.p0 .value-bar { display: none !important; }
  </style>


  