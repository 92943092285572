<template>
  <header class="d-flex justify-content-between container">
    <h2>Promotion Insight</h2>
    <div class="d-flex gap-2">
      <div>
        <Calendar
          v-model="dateRange"
          placeholder="day/month/year"
          class="pl-5 w-75"
        />
      </div>
      <div>
        <Calendar
          v-model="dateRange2"
          placeholder="day/month/year"
          class="pl-5 w-75"
        />
      </div>
    </div>
  </header>
  <hr style="border: 1px solid #f79009" />
  <!-- heading card -->
  <div class="container mx-auto">
    <div class="row gx-5 my-5">
      <div v-for="insight in insights" :key="insight.id" class="col">
        <div
          class="px-3 py-2 border border-2 rounded bg-transparent"
          :class="
            insight.title === 'reach'
              ? 'border-primary'
              : insight.title === 'impression'
              ? 'border-success'
              : 'border-warning'
          "
        >
          <div class="d-flex justify-content-between mb-3 align-items-start">
            <h2 class="font-weight-bold h1">{{ insight.total || "--" }}</h2>
            <img
              :src="
                insight.title == 'reach'
                  ? `/img/arrow-1.365c3760.svg`
                  : insight.title == 'impression'
                  ? `/img/arrow-3.5be852b6.svg`
                  : `/img/arrow-2.45d62ee4.svg`
              "
              alt=""
            />
          </div>
          <small class="font-weight-bold">TOTAL</small>
          <p class="font-weight-bold text-capitalize h1 mt-1">
            {{ insight.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <hr style="border: 1px solid #f79009" />

  <!-- Graphs -->

  <div v-if="insights && insights.length > 0">
    <!-- Line Chart starts -->
    <div class="container mx-auto p-4 bg-white rounded shadow-lg my-5">
      <h6 class="my-4">Reach</h6>
      <InsightLineChart />
    </div>

    <!-- Bar chart starts -->
    <div class="container mx-auto p-4 bg-white rounded shadow-lg mt-5">
      <InsightBarChart />
    </div>
    <!-- Bar charts ends -->
  </div>
  <div v-else class="text-center mt-5">
    <img src="./../../assets/images/no-data-found.png" alt="" />
    <h3>No data found</h3>
    <p>It seems we can’t find any data based on your request.</p>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
import InsightBarChart from "@/components/InsightBarChart.vue";
import InsightLineChart from "@/components/InsightLineChart.vue";

export default {
  components: {
    InsightBarChart,
    InsightLineChart,
    Calendar,
  },
  data() {
    return {
      dateRange: null,
      dateRange2: null,
      insights: [
        {
          id: 1,
          title: "reach",
          total: 46,
        },
        {
          id: 2,
          title: "impression",
          total: 30,
        },
        {
          id: 3,
          title: "amount spent",
          total: 16,
        },
      ],
    };
  },
  // watch: {
  //   dateRange(newVal) {
  //     if (newVal.includes(null)) {
  //       return;
  //     }
  //     this.getInsight();
  //   },
  //   dateRange2(newVal) {
  //     if (newVal.includes(null)) {
  //       return;
  //     }
  //     this.getInsight();
  //   },
  // },
  methods: {
    getInsight() {
      console.log(this.dateRange);
    },
  },
};
</script>
