<template>
  <div class="accordion" id="accordionExample">
    <!-- First Item -->
    
    <div class="container px-0">
      <div class="my-5 mx-5">

        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Higher School Name</span>
          <span class="col text-truncate p-0">Istanbul Biglin High School</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Country</span>
          <span class="col text-truncate p-0">Turkey</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">City</span>
          <span class="col text-truncate p-0">Istanbul</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">High School Address</span>
          <span class="col text-truncate p-0">Akhkdh Halik sok No:14</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Study Language</span>
          <span class="col text-truncate p-0">Turkish</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Complete School year</span>
          <span class="col text-truncate p-0">2010</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Study Year Majors</span>
          <span class="col text-truncate p-0">Computer Science</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Student ID Number</span>
          <span class="col text-truncate p-0">134455</span>
        </h6>

      </div>
    </div>
  </div>
</template>
  
<script>
export default {

}
</script>
  
<style lang="scss">

</style>