<template>
  <button @mouseover="hovered = true" @mouseout="hovered = false"
  @click="clicked" :class="['btn-disabled view-track btn text-dark px-4 py-2 d-flex']">
    <img v-if="iconPath ===''" class="me-2" :src="getIconPath(icon)" alt="" />
    <span v-else v-html="iconPath"  :style="{ fill: hovered ? 'white' : 'black' }"> </span>
    <img class="me-2" :src="iconPath" alt="" />
    <span>{{ name }}</span>
  </button>
</template>   

<script>

export default {
  props: {
    name: String,
    data: {      
      default:''
    },
    icon: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#fac515'
    }
  },
  data(){
    return {
      hovered: false
    }
  },
  methods: {
    clicked() {      
      this.$emit('onclick', this.data)
    },
    getIconPath(icon) {

      try {
        return icon == '' ? require('/src/assets/images/d-board/student-application-black.svg') : require('/src/assets/images/d-board/' + icon)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style  scoped>
/* $primary:v-bind('color') ;
$warning: #fdb022;
$scrollbar-thumb-bg: #dc6803;
$scrollbar-color: #f79009;
$gray: #d0d5dd;
 */
.active,
.view-track:active {
  background-color: v-bind('color') !important;
}

.view-track {
  font-size: 14px !important;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0 v-bind('color') !important;
  -webkit-transition: ease-out 0.4s !important;
  -moz-transition: ease-out 0.4s !important;
  transition: ease-out 0.4s !important;

}

.view-track:hover {
  background-color: v-bind('color') !important;
  color: rgb(245, 245, 245) !important;
  transition: color 0.5s;
  box-shadow: inset 150px 0 0 0 v-bind('color') !important;
}
button.view-track:hover > span svg path {
    fill: white !important;
}
</style>