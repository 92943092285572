<template>
  <div
    style="width: 730px; margin: auto; margin-top: 0px"
    class="d-flex flex-column"
  >
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">Country</label>
      <DropDown
        v-model="selectedCountry"
        :options="countries"
        @change="loadStates()"
        optionLabel="name"
        placeholder="Select a Country"
        class="w-100"
        style=""
      >
        <template #option="slotProps">
          <div class="row">
            <div class="col-2">{{ slotProps.option.phone_code }}</div>
            <div class="col-3">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </DropDown>
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <div class="w-100 d-flex flex-column position-relative">
        <label for="phone" style="font-weight: 600 !important" class="mb-1"
          >Registered Phone Number</label
        >
        <span
          style="font-weight: 600; position: absolute; bottom: 10px; left: 7px"
          >+{{
            selectedCountry?.phone_code ? selectedCountry.phone_code : "00"
          }}</span
        >
        <input
          type="tel"
          pattern="[0-9]+"
          @input="checkInput"          
          v-model="contact.phone"
          placeholder="(999) 999-9999"
          style="padding-left: 60px"
          class="py-2 d-flex justify-content-center align-items-center"
        />
        <!-- <InputMask
          v-model="phone"
          date="phone"
          mask="            (999) 999-9999"
          placeholder="            (999) 999-9999"
        /> -->
      </div>
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">City</label>
      <DropDown          
        :options="states"
        optionLabel="name"
        optionValue="id"
        placeholder=""
        filter
        :loading="stateIsLoading"
        v-model="contact.state_id"
        class="w-100 required"
      /> 
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1"
        >High School Address</label
      >
      <textarea
        style="resize: none"
        col="15"
        rows="4"
        class="inputTypeText"
        type="text"
        v-model="contact.address"
        placeholder="Please Write High School Address"
      />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1"
        >Postal Code</label
      >
      <input
        class="inputTypeText"
        type="text"
        v-model="contact.postal_code"
        placeholder="Please Write Postal Code"
      />
    </div>
  </div>
</template>

<script>
import { useCreateHsProfileStore } from "../../stores/createHighSchoolProfile";
import { get } from "@/api/client";

export default { 
  name: "ContactDetailsTab",
  props: ["isSubmitBtnClicked", "contactProp","countries"],
  data() {
    return {      
      selectedCountry: "",
      contact:
      {
        postal_code: "",
        address: "",
        state_id: "",
        country_id: "",
        phone: ""
      },
      states:[]
    };
  },
  computed: {
    // phone() {
    //   return this.selectedCountry.dial_code;
    // },
  },
  watch: {
    contact: {
      deep: true, // Watch for nested changes within each block
      handler(newData) {        
        this.$emit('onUpdateContact', newData);
        // Handle changes to the blocks array        
      },
     
    },
  },
  methods: {
    setNumber(e) {
      this.phone = "";
      this.contact.phone = this.selectedCountry.phone_code + e.currentTarget.value;      
    },
    async loadStates(callback = function(){}){
      this.stateIsLoading = true
      this.contact.country_id = this.selectedCountry.id
      const response = await get(`state/${this.selectedCountry.id}`, 'api');               
      this.states = response;
      callback()
      this.stateIsLoading = false
    },
    checkInput(e) {
      const previousValue = e.currentTarget.value;
      // console.log(isNaN(Number(previousValue)));
      if (isNaN(Number(previousValue))) {
        previousValue.length == 0
          ? (e.currentTarget.value = "")
          : (e.currentTarget.value = previousValue.slice(
              0,
              previousValue.length - 1
            ));
        return false;
      }
      return true;
    },
    saveContactInfo() {
      const store = useCreateHsProfileStore();
      store.setContactDetails({
        country: JSON.stringify(this.selectedCountry),
        phone: this.phone?.trim(),
        city: this.city,
        email: this.email,
        address: this.address,
        postal_code: this.postal_code,
      });
    },
    setPreviousDetails() {
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));
      if (contactDetails) {
        this.selectedCountry = contactDetails.country;
        this.phone = contactDetails.city;
        this.email = contactDetails.email;
        this.address = contactDetails.address;
        this.postal_code = contactDetails.postal_code;
      }
    },
  },
  mounted() {
    this.setPreviousDetails();
  },
  updated() {
    if (this.isSubmitBtnClicked) {
      this.saveContactInfo();
    }
  },  
  async created(){
      
    if(this.contactProp){
      //this.contact.phone = this.contactProp.phone
      this.contact.postal_code = this.contactProp.postal_code
      this.contact.address = this.contactProp.address
      this.contact.country_id = Number(this.contactProp.country_id)
      let $this = this;
      this.selectedCountry = this.countries.filter((item)=>{
        return item.id === this.contact.country_id
      })[0]??{}
      this.loadStates(function(){        
        $this.contact.state_id = Number($this.contactProp.state_id);        
      })
            ///alert(Number(this.contactProp.employee?.phone))
      this.contact.phone = Number(this.contactProp.employee?.phone)
    }
  }
};
</script>

<style scoped>
.inputGroupContainer {
  margin-top: 32px;
}
.inputTypeText {
  width: 100%;
  padding: 10px 14px 10px 12px;
  border: 0.5px solid #000000;
  border-radius: 4px;
}
button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}
</style>
