export let termsAndCondition = {
  title: "Terms and conditions",
  textOne: `Applying to Estonian universities requires acceptance of the following
        conditions: I confirm that all statements I make in my application are correct,
        truthful and completeI am aware that in compliance with the General Data
        Protection Regulation, the university has the right to receive and process my
        personal data (e.g. name, date of birth, personal identification code,
        citizenship, previous learning and contact information) in order to perform
        tasks carried out in public interests and comply with legal obligations deriving
        from the Estonian legislation.I understand and agree that the university may
        disclose the data to other bodies outside of the university for the purposes of
        verifying my identity and qualification(s). Such bodies include but are not
        limited to:prior education institution(s) the applicant has attended or
        graduated from;
        `,
  textTwo: `Estonian ENIC/NARIC Centre (http://adm.archimedes.ee/enic/en/); Estonian
        Ministry of Foreign Affairs. I am informed that written materials provided in
        support of the application must be authentic and may be subject to review for
        the purpose of identifying plagiarism.I am aware that the university reserves
        the right to withdraw or amend any offer or revoke my matriculation as a student
        of the university, if it becomes evident that the application contains
        fraudulent information, my qualification does not provide access to the chosen
        study programme or I am found to have omitted key information from the
        application. Should such circumstances occur, the
        `,
};

export let privacyPolicy = {
  title: "Privacy Policy",
  textOne: ` Estonian universities requires acceptance of the following 
  conditions: I confirm that all statements I make in my application are correct, 
  truthful and completeI am aware that in compliance with the General Data Protection 
  Regulation, the university has the right to receive and process my personal data 
  (e.g. name, date of birth, personal identification code, citizenship, previous 
      learning and contact information) in order to perform tasks carried out in 
      public interests and comply with legal obligations deriving from the Estonian 
      legislation.I understand and agree that the university may disclose the data to 
      other bodies outside of the university for the purposes of verifying my identity
       and qualification(s). Such bodies include but are not limited to:prior education 
       institution(s) the applicant has attended or graduated from;
        `,
  textTwo: `Estonian ENIC/NARIC Centre (http://adm.archimedes.ee/enic/en/); Estonian
        Ministry of Foreign Affairs. I am informed that written materials provided in
        support of the application must be authentic and may be subject to review for
        the purpose of identifying plagiarism.I am aware that the university reserves
        the right to withdraw or amend any offer or revoke my matriculation as a student
        of the university, if it becomes evident that the application contains
        fraudulent information, my qualification does not provide access to the chosen
        study programme or I am found to have omitted key information from the
        application. Should such circumstances occur, the
        `,
};

export const Students = [
  {
    id: 1,
    fullName: "Ivar Lothbrok",
    email: "ivar@vikings.com",
    avatar: require("@/assets/images/d-board/avater-1.svg"),
    country: "Scotland",
    nationalID: "318735",
    status: "Got Admissions",
    details: [
      {
        university: "Warton Üniversitesi",
        major: "Battle",
        country: "Scotland",
        submittedDate: "23-12-22",
        staus: "Progress",
        isAccepted: "Progress",
        accUniversity: "Not Available",
      },
      {
        university: "Koc Üniversitesi",
        major: "Martial Arts",
        country: "Scotland",
        submittedDate: "Progress",
        staus: "Got Admissions",
        isAccepted: "admiited",
        accUniversity: "Not Available",
      },
      {
        university: "Vikings College",
        major: "Martial Arts",
        country: "Scotland",
        submittedDate: "23-10-22",
        staus: "progress",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Warton Üniversitesi",
        major: "Battle",
        country: "Scotland",
        submittedDate: "23-12-22",
        staus: "Progress",
        isAccepted: "Progress",
        accUniversity: "Not Available",
      },
      {
        university: "Nail Üniversitesi",
        major: "BSc Artificial Intelligence",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
  {
    id: 2,
    fullName: "Wajahat Samil",
    email: "wahajat@highschool.com",
    avatar: require("@/assets/images/d-board/john-doe.png"),
    country: "Turkey",
    nationalID: "3122735",
    status: "Nill",
    details: [
      {
        university: "Sabanci Üniversitesi",
        major: "BSc Cyber Security",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
  {
    id: 3,
    fullName: "Michal Malewicz",
    email: "helloMalewicz@outlook.com",
    avatar: require("@/assets/images/d-board/avater.svg"),
    country: "Azherbaijan",
    nationalID: "318735",
    status: "Applyings",
    details: [
      {
        university: "Sabanci Üniversitesi",
        major: "BSc Cyber Security",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Koc Üniversitesi",
        major: "HCI",
        country: "Turkey",
        submittedDate: "nill",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Vikings College",
        major: "Martial Arts",
        country: "Scotland",
        submittedDate: "23-10-22",
        staus: "progress",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Cambridge Üniversity",
        major: "BSc Cyber Security",
        country: "Uk",
        submittedDate: "23-10-23",
        staus: "Progress",
        isAccepted: "Progress",
        accUniversity: "Not Available",
      },
      {
        university: "Nail Üniversitesi",
        major: "BSc Artificial Intelligence",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },

  {
    id: 4,
    fullName: "Usi Anone",
    email: "usi@new.com",
    avatar: require("@/assets/images/d-board/avater-2.svg"),
    country: "Marvel",
    nationalID: "3132735",
    status: "Nill",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },

  {
    id: 5,
    fullName: "Azeez AMos",
    email: "azeez@email.com",
    avatar: require("@/assets/images/d-board/katemoris.png"),
    country: "Nigeria",
    nationalID: "213452",
    status: "No Apply",
    details: [
      {
        university: "Nail Üniversitesi",
        major: "BSc Artificial Intelligence",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
      {
        university: "Sabanci University",
        major: "BSc Cyber Security",
        country: "Turkey",
        submittedDate: "23-10-23",
        staus: "",
        isAccepted: "Not Available",
        accUniversity: "Not Available",
      },
    ],
    documents: [
      {
        id: 1,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
      {
        id: 2,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 3,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 4,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
    ],
  },
  {
    id: 6,
    avater: "avater.svg",
    fullName: "Ajnur Bakary",
    email: "anjurbekarys@gmail.com",
    avatar: require("@/assets/images/d-board/avater-2.svg"),
    country: "Turkey",
    nationalId: 435256,
    status: "Approved",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "0.20mb",
        date: "25/05/2022",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "4.00mb",
        date: "8/4/2022",
      },
      {
        id: 3,
        text: "Profile Credentials",
        size: "100kb",
        date: "6/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "5/05/2022",
      },
      {
        id: 5,
        text: "National ID Photo",
        size: "4.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 7,
    avater: "avater-2.svg",
    fullName: "Thomas kelvin",
    email: "thomass@gmail.com",
    avatar: require("@/assets/images/d-board/kamal.png"),
    country: "London",
    nationalId: 123456,
    status: "Waiting Review",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "1.00mb",
        date: "2/9/2021",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "0.50mb",
        date: "12/04/2021",
      },
      {
        id: 3,
        text: "Id Card",
        size: "500Kb",
        date: "9/07/2021",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "1/06/2021",
      },
      {
        id: 5,
        text: "Country ID Card",
        size: "1.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 8,
    avater: "avater.svg",
    fullName: "Okoli james",
    email: "okolithomas@gmail.com",
    avatar: require("@/assets/images/d-board/esma.png"),
    country: "Nigeria",
    nationalId: 935456,
    status: "Rejected",
    details: [],
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
];

export const StudentApplications = [
  {
    id: 1,
    avater: "avater.svg",
    fullName: "Ajnur Bakary",
    email: "anjurbekarys@gmail.com",
    avatar: require("@/assets/images/d-board/avater-2.svg"),
    country: "Turkey",
    nationalId: 435256,
    status: "Approved",
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "0.20mb",
        date: "25/05/2022",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "4.00mb",
        date: "8/4/2022",
      },
      {
        id: 3,
        text: "Profile Credentials",
        size: "100kb",
        date: "6/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "5/05/2022",
      },
      {
        id: 5,
        text: "National ID Photo",
        size: "4.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 2,
    avater: "avater-2.svg",
    fullName: "Thomas kelvin",
    email: "thomass@gmail.com",
    avatar: require("@/assets/images/d-board/avater-1.svg"),
    country: "London",
    nationalId: 123456,
    status: "Waiting Review",
    documents: [
      {
        id: 1,
        text: "High School Grade MarkSheet",
        size: "1.00mb",
        date: "2/9/2021",
      },
      {
        id: 2,
        text: "High School Certificates",
        size: "0.50mb",
        date: "12/04/2021",
      },
      {
        id: 3,
        text: "Id Card",
        size: "500Kb",
        date: "9/07/2021",
      },
      {
        id: 4,
        text: "Passport",
        size: "1.00mb",
        date: "1/06/2021",
      },
      {
        id: 5,
        text: "Country ID Card",
        size: "1.00mb",
        date: "25/05/2022",
      },
    ],
  },
  {
    id: 3,
    avater: "avater.svg",
    fullName: "Okoli james",
    email: "okolithomas@gmail.com",
    avatar: require("@/assets/images/d-board/avater.svg"),
    country: "Nigeria",
    nationalId: 935456,
    status: "Rejected",
    documents: [
      {
        id: 1,
        text: "High School Certificates",
        size: "0.2mb",
        date: "5/07/2022",
      },
      {
        id: 2,
        text: "High School Grade MarkSheet",
        size: "4.00mb",
        date: "25/05/2022",
      },
      {
        id: 3,
        text: "Id Card",
        size: "1.00mb",
        date: "2/01/2022",
      },
      {
        id: 4,
        text: "Passport",
        size: "4.00mb",
        date: "3/07/2022",
      },
    ],
  },
];

export const Messages = [
  {
    sender: {
      id: 1,
      username: "Kate Morison",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
    },
    receivedAt: "21/05/2022",
    isRead: true,
    body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 2,
      username: "Dowe John",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/john-doe.png"),
    },
    receivedAt: "21/05/2022",
    isRead: false,
    body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 3,
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
    },
    receivedAt: "21/05/2022",
    isRead: true,
    body: "Based on your requirements - Lorem Ipsum fhrc ckead Jdwijd veroiersdc awdaw e fsvoer sepe",
    attachments: ["screen-shot.jpg"],
  },
];

export const Mail = {
  inbox: [
    {
      id: 1,
      username: "Kate Morison",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
      receivedAt: "21/05/2022",
      isRead: true,
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 2,
      username: "Dowe John",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/john-doe.png"),
      receivedAt: "21/05/2022",
      isRead: false,
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 3,
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedAt: "21/05/2022",
      isRead: true,
      body: "Based on your requirements - Lorem Ipsum fhrc ckead Jdwijd veroiersdc awdaw e fsvoer sepe",
      attachments: ["screen-shot.jpg"],
    },
  ],
  draft: [],
  sent: [
    {
      id: 1,
      username: "Kate Morison",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
      sentAt: "21/05/2022",
      subject: "Pending Approval",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 2,
      username: "Dowe John",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/john-doe.png"),
      sentAt: "2/12/2022",
      subject: "Application accepted to Univerity of France",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 3,
      username: "Laravel Dell",
      email: "dell@example.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      sentAt: "3/12/2022",
      subject: "Application accepted to Univerity of France",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
    {
      id: 4,
      username: "Isaac Newton",
      email: "isaac@nentonnetwork.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedAt: "3/12/2022",
      subject: "Electricity Request",
      body: "Based on your requirements - the deadline for the final project submition is 7 Feb",
      attachments: ["screen-shot.jpg"],
    },
  ],
  trash: [],
};

export const FAQ = [
  {
    question: "I'm having problems logging in",
    active: false,
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  },
  {
    question: "I forget my password",
    active: false,
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  },
  {
    question: "How can I contact paramount student support team",
    active: false,
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  },
  {
    question: "How can I evaluate the student Document",
    active: false,
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  },
  {
    question: "How can I track students' activity",
    active: false,
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  },
  {
    question: "I'm having problems in Sign Up",
    active: false,
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  },
  {
    question: "What is paramount students contact support toll free number",
    active: false,
    answer:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores perspiciatis, quibusdam enim, optio quis at ex provident minus a sapiente placeat autem esse accusantium .Quisquam sequi totam recusandae maiores amet",
  },
];

export const OurProgrammes = [
  {
    programme: "Bachelor Degree",
    active: false,
    course: "Bachelor in computer science",
  },
  {
    programme: "Master Degree",
    active: false,
    course: "Master in computer science",
  },
  {
    programme: "PHD Degree",
    active: false,
    course: "Phd IN Computer Science",
  },
  {
    programme: "Diploma",
    active: false,
    course: "Diploma in Computer Science",
  },
];
export const Chats = [
  {
    sender: {
      id: 1,
      body: "hello how are u",
      username: "Seth Hallam",
      receivedDate: "21-05-2022",
      receivedTime: "12:23pm",
      imgURL: require("@/assets/images/d-board/katemoris.png"),
    },
  },
];

export const ChatMessages = [
  {
    sender: {
      id: 1,
      body: "please i wil like to know the status of my apllication this is my apllication number",
      username: "Kate Morison",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/avater-2.svg"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,

    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 2,
      body: "please i wil like to know the status of my apllication this is my apllication number",
      username: "Dowe John",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/kamal.png"),
      receivedtime: "2min ago",
    },
    isArchived: false,
    isFav: true,
    isRead: false,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 3,
      body: "please i wil like to know the status of my apllication this is my apllication number",
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,

    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 4,
      body: "please i wil like to know the status of my apllication this is my apllication number",
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater-1.svg"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: true,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 5,
      body: "please i wil like to know the status of my apllication this is my apllication number",
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedtime: "2min ago",
    },
    isArchived: false,
    isFav: true,
    isRead: false,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 6,
      body: "please i wil like to know the status of my apllication this is my apllication number",
      username: "Kamal Surya",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
];
export const UniversityChatMessages = [
  {
    sender: {
      id: 1,
      body: "We would like to update our student application waiver fee on our website",
      username: "Cankaya University",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/avater-2.svg"),
      receivedtime: "30 mins ago",
    },
    isArchived: true,
    isFav: false,
    isRead: false,

    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 2,
      body: "Staff can’t log into the university profile",
      username: "Ankara University",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/kamal.png"),
      receivedtime: "2min ago",
    },
    isArchived: false,
    isFav: true,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 3,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Bahçeşehir University (BAU)",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: false,

    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 4,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Hacettepe University",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater-1.svg"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: true,
    isRead: false,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 5,
      body: "Please ensure you are logging in with correct details",
      username: "METU Middle East Technical University",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedtime: "2min ago",
    },
    isArchived: false,
    isFav: true,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 6,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Istanbul Technical University",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 7,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Gazi Universityy",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 8,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Istanbul Aydin University",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 9,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Istanbul Bilgi University",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
];
export const users = [
  {
    id: 1,
    username: "Bersan01",
    email: "Bersan@paramountStudents.com",
    role: ["Live Chat Support", "Mails Support"],
    firstName: "Bersan",
    lastName: "Okkali",
    img: require("@/assets/images/users-and-roles/user1.svg"),
    isActive: true,
  },
  {
    id: 2,
    username: "David",
    email: "David@ParamountStudents.com",
    role: ["Super Admin"],
    firstName: "David",
    lastName: "Wilson",
    img: require("@/assets/images/users-and-roles/user2.svg"),
    isActive: true,
  },
  {
    id: 3,
    username: "Jade",
    email: "Jade@paramountStudents.com",
    role: ["Supports"],
    firstName: "Jade",
    lastName: "Wilson",
    img: require("@/assets/images/users-and-roles/user3.svg"),
    isActive: true,
  },
  {
    id: 4,
    username: "Muath",
    email: "Muath@paramountStudents.com",
    role: ["HR"],
    firstName: "Muath",
    lastName: "Wilson",
    img: require("@/assets/images/users-and-roles/user4.svg"),
    isActive: false,
    activities: [
      {
        id: 1,
        activity: "Replied to Ansur's mail",
        date: "12 min ago",
      },
      {
        id: 2,
        activity: "Generated weekly report",
        date: "45 min ago",
      },
      {
        id: 3,
        activity: "Documents uploaded",
        date: "5 day ago",
      },
      {
        id: 4,
        activity: "Replied to Ansur's mail",
        date: "5 day ago",
      },
    ],
  },
];

export const HighSchoolChatMessages = [
  {
    sender: {
      id: 1,
      body: "We would like to update our student application waiver fee on our website",
      username: "Atatürk High School",
      email: "katemor@gmail.com",
      imgURL: require("@/assets/images/d-board/avater-2.svg"),
      receivedtime: "30 mins ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,

    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 2,
      body: "Staff can’t log into the university profile",
      username: "Ankara University",
      email: "dowejohn@example.com",
      imgURL: require("@/assets/images/d-board/kamal.png"),
      receivedtime: "2min ago",
    },
    isArchived: false,
    isFav: true,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 3,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Bahçeşehir High School",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: false,

    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 4,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Ankara High School",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater-1.svg"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: true,
    isRead: false,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 5,
      body: "Please ensure you are logging in with correct details",
      username: "METU Middle East Technical High School",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/avater.svg"),
      receivedtime: "2min ago",
    },
    isArchived: false,
    isFav: true,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 6,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Istanbul Technical High School",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 7,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Gazi High School",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 8,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Istanbul High School",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: true,
    attachments: ["screen-shot.jpg"],
  },
  {
    sender: {
      id: 9,
      body: "Please I’d like to enquire about the status of my application, This is my student ID number 12345",
      username: "Antalya High School",
      email: "kamal@gmail.com",
      imgURL: require("@/assets/images/d-board/esma.png"),
      receivedtime: "2min ago",
    },
    isArchived: true,
    isFav: false,
    isRead: false,
    attachments: ["screen-shot.jpg"],
  },
];
export const Programs = [
  {
    id: 1,
    courseCode: "80164",
    studyLocation: "Instabul, Turkey",
    programType: "Bachelor Degree",
    programAward: "B.Sc",
    studyLanguage: "English",
    learningMode: "On Campus",
    status: "Published",
  },
  {
    id: 2,
    courseCode: "80164",
    studyLocation: "Ankara, Turkey",
    programType: "Master Degree",
    programAward: "M.Tech",
    studyLanguage: "English",
    learningMode: "On Campus",
    status: "Not Published",
  },
  {
    id: 3,
    courseCode: "80164",
    studyLocation: "Izmir, Turkey",
    programType: "PHD Degree",
    programAward: "PHD",
    studyLanguage: "English",
    learningMode: "On Campus",
    status: "Draft",
  },
  {
    id: 4,
    courseCode: "80164",
    studyLocation: "Ankara, Turkey",
    programType: "Diploma",
    programAward: "None",
    studyLanguage: "English",
    learningMode: "Online",
    status: "Published",
  },
  {
    id: 5,
    courseCode: "80164",
    studyLocation: "Antalya, Turkey",
    programType: "Bachelor Degree",
    programAward: "B.E",
    studyLanguage: "English",
    learningMode: "On Campus",
    status: "Published",
  },
];

export const highSchools = [
  {
    id: 80164,
    name: "Turkish Grammar High School",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Public High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Active",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80165,
    name: "Home science secondary school",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Active",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80166,
    name: "Sachel Secondary High school",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Pending",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80167,
    name: "Waterman junior High School",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Inactive",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80168,
    name: "Goju senior/junior High School",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Public High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Inactive",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80169,
    name: "Craig High School",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Pending",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80170,
    name: "High School Of Waterloo",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Public High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Pending",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80171,
    name: "High School Of Crandall",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private High School",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Active",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
];
export const highSchoolRecentLoginActivity = [
  {
    device: "Chrome v96.00987.98 (Windwos)	",
    ip: "78.123.543.98	",
    location: "United States	",
    recentActivity: "10, Jan 2022 20:07",
  },
  {
    device: "Chrome v96.00987.98 (Windwos)	",
    ip: "78.123.543.98	",
    location: "Germany	",
    recentActivity: "10, Feb 2020 20:07",
  },
  {
    device: "Chrome v96.00987.98 (Mac)	",
    ip: "13.133.883.128	",
    location: "UK",
    recentActivity: "10, May 2021 10:07",
  },
  {
    device: "Chrome v96.00987.98 (Windwos)	",
    ip: "192.168.1.1	",
    location: "Turkey",
    recentActivity: "03, Jan 2023 15:17",
  },
];
export const universities = [
  {
    id: 80164,
    name: "University Of Alberta",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Public University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Active",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80165,
    name: "University Of Toronto",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Active",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80166,
    name: "University Of Crandall",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Pending",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80167,
    name: "University Of Waterloo",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Inactive",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80168,
    name: "Western University Canda",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Public University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Inactive",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80169,
    name: "University Of Alberta",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Pending",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80170,
    name: "University Of Waterloo",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Public University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Pending",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
  {
    id: 80171,
    name: "University Of Crandall",
    registeredFirstName: "John",
    registeredLastName: "Doe",
    type: "Private University",
    country: "Canada",
    city: "Toronto",
    address: "123 Main St",
    postalCode: "123040",
    phoneNumber: "5389281230",
    status: "Active",
    lastLogin: {
      date: "2020-01-01",
      time: "03:36 pm",
    },
    registeredDate: "2020-01-01",
    registeredEmail: "efpyi@example.com",
  },
];
export const universityRecentLoginActivity = [
  {
    device: "Chrome v96.00987.98 (Windwos)	",
    ip: "78.123.543.98	",
    location: "United States	",
    recentActivity: "10, Jan 2022 20:07",
  },
  {
    device: "Chrome v96.00987.98 (Windwos)	",
    ip: "78.123.543.98	",
    location: "Germany	",
    recentActivity: "10, Feb 2020 20:07",
  },
  {
    device: "Chrome v96.00987.98 (Mac)	",
    ip: "13.133.883.128	",
    location: "UK",
    recentActivity: "10, May 2021 10:07",
  },
  {
    device: "Chrome v96.00987.98 (Windwos)	",
    ip: "192.168.1.1	",
    location: "Turkey",
    recentActivity: "03, Jan 2023 15:17",
  },
];
export const countries = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];
