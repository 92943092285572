<template>
  <div class="">
    <div>
     
      <div class="ps-5 mb-3" style="border-bottom: 1px solid black">
        <div class="ps-0 mb-3 d-flex align-items-center justify-content-between" style="width: 95%" >
          <!-- Page Header -->
          <div>
            <i @click="back()" class="pi pi-arrow-left m-4 ps-4 cursor-pointer me-2"></i>
            <h1 style="font-size: 30px"
              >{{program.program_name}}
              <span class="mt-2 d-flex" style="background-color: rgb(108, 211, 123);font-size: 10px;border-radius: 15px;padding: 8px;">
                <img :src="require(`/src/assets/images/d-board/megaphone.svg`)" alt="" class="icon-size" />promoted course</span>
            </h1>

          </div>
          <div class="p-1" style="background-color: red; color: white; border-radius: 5px">
            <i class="pi pi-trash text-white"
              ><span class="ml-5 mx-1">Delete</span></i
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="ps-5 w-100 d-flex align-items-center p-2"
      style="border-bottom: 1px solid black"
    >
      <div
        @click="changeTab(1)"
        style="
          color: rgb(29, 41, 57);
          cursor: pointer;
          border-bottom: 4px solid transparent;
        "
        class="me-5 p-3"
        :style="
          currentTab === 1 &&
          'background-color:  rgba(231, 197, 157, 0.365)a(255, 217, 0, 0.365)(255, 217, 0);  border-radius: 10px; border:1px solid yellow'
        "
      >
        Program Details
      </div>
      <div
        @click="changeTab(2)"
        style="
          cursor: pointer;
          border-bottom: 4px solid transparent;
          color: rgb(29, 41, 57);
        "
        :style="
          currentTab === 2 &&
          'background-color:  rgba(231, 197, 157, 0.365)a(255, 217, 0, 0.365)(255, 217, 0);  border-radius: 10px; border:1px solid yellow'
        "
        class="p-3"
      >
        Admission Details
      </div>

      <div
        @click="changeTab(3)"
        style="
          cursor: pointer;
          border-bottom: 4px transparent;
          color: rgb(29, 41, 57);
        "
        :style="
          currentTab === 3 &&
          'background-color:  rgba(231, 197, 157, 0.365)a(255, 217, 0, 0.365)(255, 217, 0);  border-radius: 10px; border:1px solid yellow'
        "
        class="p-3"
      >
        Tuition Fees
      </div>

      <div
        @click="changeTab(4)"
        style="
          cursor: pointer;
          border-bottom: 4px solid transparent;
          color: rgb(29, 41, 57);
        "
        :style="
          currentTab === 4 &&
          'background-color:  rgba(231, 197, 157, 0.365)a(255, 217, 0, 0.365)(255, 217, 0);  border-radius: 10px; border:1px solid yellow'
        "
        class="p-3"
      >
        Academic Requirements
      </div>
      <div
        @click="changeTab(5)"
        style="
          cursor: pointer;
          border-bottom: 4px solid transparent;
          color: rgb(29, 41, 57);
        "
        :style="
          currentTab === 5 &&
          'background-color:  rgba(231, 197, 157, 0.365)a(255, 217, 0, 0.365)(255, 217, 0);  border-radius: 10px; border:1px solid yellow'
        "
        class="p-3"
      >
        Application fees
      </div>
    </div>
    <div v-show="currentTab === 1">
      <ProgrammeDetails :program="program"  @on-change="changeData($event,'details')" />
    </div>
    <div v-show="currentTab === 2">
      <AdmissionDeadline :admission_deadlines="program?.admission_deadlines" @on-change="changeData($event,'deadlines')" />
    </div>
    <div v-show="currentTab === 3">
      <TuitionFee :tuition_fees="program?.tuition_fees"  @on-change="changeData($event,'tuitions')" />
    </div>
    <div v-show="currentTab === 4">
      <AcademicRequirement :requirements="program?.academic_description" @on-change="changeData($event,'requirements')"/>
    </div>
    <div v-show="currentTab === 5">
      <ApplicationFee  :application_fees="program?.application_fees" @on-change="changeData($event,'fees')" />
    </div>
    <div
      class="d-flex align-items-center justify-content-between w-80 m-auto mt-5"
    >
      <!-- Footer Buttons -->
      <button class="border py-2 px-5">Cancel</button>
      <button
        v-if="currentTab === 1"
        @click="changeTab(currentTab + 1)"
        class="bg-warning border py-2 px-5"
      >
        Next
      </button>

      <button
        v-if="currentTab === 2"
        @click="changeTab(currentTab + 1)"
        class="bg-warning border py-2 px-5"
      >
        Next
      </button>
      <button
        v-if="currentTab === 3"
        @click="changeTab(currentTab + 1)"
        class="bg-warning border py-2 px-5"
      >
        Next
      </button>
      <button
        v-if="currentTab === 4"
        @click="changeTab(currentTab + 1)"
        class="bg-warning border py-2 px-5"
      >
        Next
      </button>      
      <button
        @click="openConfirmModal"
        v-if="currentTab === 5"
        class="bg-warning border py-2 px-5"
      >
        publish
      </button>
    </div>
  </div>
  <ConfirmModal
    v-if="isConfirmModalOpen"
    @closeConfirmModal="closeConfirmModal"
    @createProfile="createProfile"
    message="Are you sure you want to create this program"
  />
</template>

<script>
import ProgrammeDetails from "@/components/Programme/ProgrammeDetails.vue";
import AdmissionDeadline from "@/components/Programme/AdmissionDeadline.vue";
import TuitionFee from "@/components/Programme/TuitionFee.vue";
import AcademicRequirement from "@/components/Programme/AcademicRequirement.vue";
import ApplicationFee from "@/components/Programme/ApplicationFee.vue";
import ConfirmModal from "@/components/Programme/ConfirmModal.vue";
import {showModal} from "@/plugins/modal";
import {post} from '@/api/client'
export default {
  props:[
    'program'
  ],
  name: "ViewProgrammes",
  data() {
    return {
      currentTab: 1,
      isConfirmModalOpen: false,
      degree: "",
      programDetails: {},
      admissionDeadlines: [],
      tuitionFees: [],
      academicRequirements: [],
      applicationFees: [],
    };
  },
  methods: {
    back(){
      this.$emit('back',true)
    },
    changeData(data, type){      
      if(type =='details'){
        this.programDetails = data
      }
      if(type =='deadlines'){
        this.admissionDeadlines = data
      }
      if(type =='tuitions'){
        this.tuitionFees = data
      }
      if(type =='requirements'){
        this.academicRequirements = data
      }
      if(type =='fees'){
        this.applicationFees = data
      }
    },
    showAlert(title,text='Fill required fields'){
      showModal({
            text:title +': ' + text            
          });
    },
    isEmpty(value) {
      if (typeof value === 'string') {
        return value.trim() === '';
      } else if (Array.isArray(value)) {
        return value.length === 0;
      } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      } else if (typeof value === 'number') {
        return value === 0;
      } else if (typeof value === 'boolean') {
        return !value;
      } else if(value instanceof File){
        return false ;
      } else {
        return value === null || value === undefined;
      }
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    openConfirmModal() {
      this.currentTab = 6;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.currentTab = 5;
      this.isConfirmModalOpen = false;
    },   
    createProfile() {
      if (this.publish()) {
        // window.alert("Profile Created");
        this.isConfirmModalOpen = false;
      } else {
        // window.alert("Profile Creation Error");
        this.isConfirmModalOpen = false;
      }
    },   
    async publish() {             
      if(this.ProgrammeDetailsValidated){
        this.showAlert('Program Details')  
      }
      else if(this.AdmissionDeadlineValidated){        
        this.showAlert('Admission Deadline')  
      }
      else if(this.TuitionFeeValidated){
        showModal({text:"Tuition Fee"});
      }
      else if(this.ApplicationFeeValidated){
        showModal({text:"Application Fee"});
      }else{  
        const  user = JSON.parse(localStorage.getItem('userInfo'));
        let payload = JSON.parse(JSON.stringify(this.programDetails));
        payload.admission_deadlines = this.admissionDeadlines,
        payload.tuition_fees = this.tuitionFees,
        payload.application_fees = this.applicationFees,        
        payload.academic_description = this.academicRequirements          
        let route ='creatue'
        if(this.program !== undefined){
          route = 'update'
          payload.id = this.program.id
        }

        let res = await post(
          "program/"+route,
          payload,'admin',route+'-program-'+user.id
        );  
        if(!res){
          return false
        }           
        if(Object.keys(typeof(res) == 'object'? res: {}).includes('university_id')){
          showModal({text:'Created successfully'});
        }else{
          if(res){
            showModal({text:res.data.responseBody});        
          }
        }
      }
    },
  },
  components: {
    ProgrammeDetails,
    AdmissionDeadline,
    TuitionFee,
    AcademicRequirement,
    ApplicationFee,
    ConfirmModal,
  },
  computed:{
    ProgrammeDetailsValidated() {
      const notRequired = ['comment','cover_photo','created_at','updated_at'];
      let required = false;
      Object.keys(this.programDetails).every((key) => {
        if (!notRequired.includes(key) && this.isEmpty(this.programDetails[key])) {
          required = key;
          return false;
        }
        return true;
      });
      return required;
    },
    AdmissionDeadlineValidated() {
      const notRequired = ['created_at','updated_at'];
      let required = false;
      Object.keys(this.admissionDeadlines[0]??[]).every((key) => {
        if (!notRequired.includes(key) && this.isEmpty(this.admissionDeadlines[0][key])) {
          required = key;
          return false; 
        }
        return true;
      });
      return required;
    },
    TuitionFeeValidated() {
      const notRequired = ['created_at','updated_at'];
      let required = false;
      Object.keys(this.tuitionFees[0]??[]).every((key) => {
        if (!notRequired.includes(key) && this.isEmpty(this.tuitionFees[0][key])) {
          required = key;
          return false;
        }
        return true;
      });
      return required;
    },
    ApplicationFeeValidated() {
      const notRequired = ['created_at','updated_at'];
      let required = false;
      Object.keys(this.applicationFees[0]??[]).every((key) => {
        if (!notRequired.includes(key) && this.isEmpty(this.applicationFees[0][key])) {
          required = key;
          return false;
        }
        return true;
      });
      return required;
    }

  },
};
</script>

<style scoped>
body {
  color: rgba(231, 197, 157, 0.365) a(255, 217, 0, 0.365) (255, 217, 0);
}
</style>
