<template lang="html">
  <div>    
      <swiper :modules="autoplay"  :slides-per-view="slidesPerView" :space-between="20" :loop="true">
            <swiper-slide  v-for="docCount in docFileCount" :key="docCount.id" style="display: inline-block;">    
              <div :class="docCount.class" class="w-100">
                <div :class="docCount.style" :style="docCount.borderColor" class="bg-white" style="min-height: 124px;">
                  <div class="fw-bold d-flex justify-content-between align-self-end">
                    <h1 class="">{{ docCount.count }}</h1>
                    <div class="d-flex align-items-start">
                      <img :src="require(`/src/assets/images/d-board/${docCount.img}`)" alt="" />
                    </div>
                  </div>
                  <small>{{ docCount.subTitle }}</small>
                  <h6 style="font-size: 0.8rem;white-space: nowrap;font-weight: 600;">{{ docCount.title }}</h6>
                  <h4>{{ docCount.excerpt }}</h4>
                </div>
              </div>                        
            </swiper-slide>
        </swiper>          
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
export default {
  name: "DashboardStat",
  components:{  Swiper,SwiperSlide },
  props: {
    docFileCount: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slidesPerView() {      
      const viewportWidth = window.innerWidth;
      if (viewportWidth >= 1024) {
        return 4;
      } else if (viewportWidth >= 768) {        
        return 3;
      } else {        
        return 1;
      }
    },
  },
  data() {
    return {
      modules: [Pagination],
      autoplay: [Autoplay],     
    };
  },
};
</script>

<style lang="scss" scoped>
$text-color: #1d2939;

.fs-14 {
  font-size: 14px !important;
}

@media screen and (max-width: 768px) {
  .w-md-18 {
    width: 170%;
  }
}

.p-02 {
  padding: 0.4em 0.4em !important;
}

.fs-6 {
  font-size: 0.8em !important;
}

.inboxImg {
  width: 39.92px;
  height: 40px;
}

.grey {
  color: #98a2b3;
}

.bg-white {
  background-color: #fff;
}

.student-app-heading {
  margin-top: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
  background-color: #fff;

  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0;
    line-height: 38px;
    color: $text-color;
  }
}

.student-app {
  .student-app-card {
    box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
    background-color: #fff;
  }
}

.student-track {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
  background-color: #fff;

  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 38px;
    color: $text-color;
    margin-bottom: 0;
  }
}

.p-header {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 38px;
  color: $text-color;
  margin-bottom: 0;
}

button {
  font-size: 0.8rem;
}
</style>
