<template>
    <div class="card my-2" style="height: 600px">
      
    <p class="px-5 py-2">User activities timeline</p>
    </div>
  </template>
  
  <script>
  
  
  
 
 
  export default {
    data() {
      return {
        
     
        student: [
          {
            status: "online",
          },
        ],
      };
    },
    components: {

    },
   
  };
  </script>
  
  <style lang="scss">
  $primary: #fac515;
  $warning: #fdb022;
  $scrollbar-thumb-bg: #dc6803;
  $scrollbar-color: #f79009;
  $gray: #d0d5dd;
  
  .texteditor {
    width: 95%;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  
  .trackform-input {
    input {
      padding-right: 40px;
    }
    .form-btna {
      position: absolute;
      right: 10px;
      z-index: 5;
      padding: 4px;
      border: 1px solid #98a2b3;
      border-radius: 100%;
      font-size: 10px;
      background-color: #98a2b3;
      color: white;
  
      &:hover {
        background-color: #c0c8d4;
      }
    }
  }
  .active {
    background-color: $primary !important;
  }
  
  .search-btn {
    background-color: $gray;
    padding: 8px 24px;
  }
  .search-btn {
    font-size: 14px !important;
  }
  
  .filter-btn {
    background-color: $gray;
  }
  .text-xs {
    font-size: 0.85rem !important;
  }
  </style>
  