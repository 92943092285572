<template>
  <div class="">
    <!-- Page Header -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">        
        <div class="position-relative me-4 mb-3">
          <img style="width: 90px; height: 90" alt="uni-img" :src="school.cover_photo_url!=''?school.cover_photo_url:'@/assets/images/highschools-list/highschoolImg.svg'"/>
          
          <span style=" width: 15px;
              height: 15px;
              border-radius: 100%;
              margin-right: 2px;
              margin-bottom: 1px;
              position: absolute;
              right: 5px;" :style="
              school.active == 1?
              'background-color:green'
              :school.active == 0 ?
              'background-color:rgba(249, 112, 102, 1)'
              :'background-color:red'"></span>
        </div>
        <div class="d-flex flex-column align-items-start">
          <h1>{{ school.name }}</h1>
          <p>
            Last Login: {{ lastTimeUpdated }} ,
            {{ lastDateUpdated }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center justify-content-center">
          <InputSwitch
            style=""
            @change="changeStatus($event)"
            v-model="isActiveHighSchool"
          />
          <p
            style="font-weight: 600; font-size: 12px !important"
            class="m-0 p-0 ms-1"
          >
            {{ school.active == 1 ? "Active" : "Inactive" }}
          </p>
        </div>
        <p>Date: 2022-05-2</p>
      </div>
    </div>
    <div class="mt-3 ps-5 border-bottom w-100 d-flex align-items-center">
      <!-- Tabs -->
      <swiper   :slides-per-view="slidesPerView" :space-between="20" :loop="true">
          <swiper-slide  style="display: inline-block;">                
          <div
            @click="changeTab(1)" style="color: rgb(29, 41, 57);cursor: pointer;border-bottom: 4px solid transparent;" class="me-5 p-3"
            :style="currentTab === 1 &&  'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600'">
            High School Information
          </div>
          </swiper-slide>
            <swiper-slide  style="display: inline-block;">    
          <div @click="changeTab(2)" style="cursor: pointer;border-bottom: 4px solid transparent;color: rgb(29, 41, 57);" :style="currentTab === 2 &&  'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600'" class="p-3 me-5" >
            Contact Details
          </div>
          </swiper-slide>
            <swiper-slide  style="display: inline-block;">    
          <div @click="changeTab(3)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 3 && 'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600' " class="p-3"> 
            Commission
          </div>
          </swiper-slide>
            <swiper-slide  style="display: inline-block;">    
          <div @click="changeTab(4)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 4 && 'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600' " class="p-3">
            Agreement
          </div>
          </swiper-slide>
            <swiper-slide  style="display: inline-block;">    
          <div @click="changeTab(5)" style="cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style=" currentTab === 5 &&   'border-bottom: 4px solid '+$globals.colorsHex.warning+'; font-weight:600' " class="p-3">
            General Settings
          </div>
          </swiper-slide>
        </swiper>
    </div>
    <HighSchoolInformationTab @onUpdateInfo="onUpdateInfoFunc($event)" :highSchoolProp="school" v-if="currentTab == 1" />
    <ContactDetailsTab :countries="countries" @onUpdateContact="onUpdateContactFunc($event)" :contactProp="school" v-if="currentTab === 2" />
    <div v-if="currentTab === 3">
      <CommissionTab :commission_targets="school?.commission_targets" :commission_type="school?.commission_type" :commission_value="school?.commission_value" @updateCommission="updateCommissionFunc($event)" />
    </div>
    <div v-if="currentTab === 4">
      <AgreementTab :documents="school?.agreement_letters" @updateLetter="updateLetterFunc($event)"  />
    </div>
    <div v-if="currentTab === 5">
      <GeneralSettingsTab :highSchool="school" />
    </div>
    <div  v-if="currentTab !== 5" class="d-flex align-items-center justify-content-between w-80 m-auto mt-5" >

      <!-- Footer Buttons -->
      <button class="border py-2 px-5">Cancel</button>
      <button  v-if="currentTab === 1" @click="changeTab(currentTab + 1)" class="bg-warning border py-2 px-5">Next</button>
      <button v-else @click="openConfirmModal" class="bg-warning border py-2 px-5 text-[0.8em] md:text-[0.97em]"> Save
        <span class="hidden md:inline"> Changes..... </span>
        </button>
    </div>
    <ConfirmModal
      v-if="isConfirmModalOpen"
      @closeConfirmModal="closeConfirmModal"
      @createProfile="createProfile"
      message="Are you sure you want to save changes?"
    />
  </div>
</template>

<script>
import HighSchoolInformationTab from "@/components/HighSchoolsList/HighSchoolInformationTab.vue";
import ContactDetailsTab from "@/components/HighSchoolsList/ContactDetailsTab.vue";
import GeneralSettingsTab from "@/components/HighSchoolsList/ViewHighSchoolDetails/GeneralSettingsTab.vue";
import ConfirmModal from "@/components/HighSchoolsList/ConfirmModal.vue";
import { useHsStore } from "@/stores/highSchools";
import { post } from '@/api/client';
import {showModal} from "@/plugins/modal";
import { Swiper, SwiperSlide } from "swiper/vue";
import CommissionTab from "@/components/UniversitiesList/CommissionTab.vue";
import AgreementTab from "@/components/UniversitiesList/AgreementTab.vue";
// import { highSchools } from "@/data";

export default {
  name: "HighSchoolDetail",  
  components: {
    GeneralSettingsTab,
    ContactDetailsTab,
    HighSchoolInformationTab,
    ConfirmModal,
    CommissionTab,
    AgreementTab,
    Swiper, SwiperSlide
    
  },
  computed: {
    slidesPerView() {      
      const viewportWidth = window.innerWidth;
      if (viewportWidth >= 1024) {
        return 5;
      } else if (viewportWidth >= 768) {        
        return 3;
      } else {        
        return 1;
      }
    },
  },
  props:{
    countries:Array,
    school:Object,
    index:Number,
    isDashboard:{
      type:Boolean,
      default:function(){
        return false
      }
    }
  },
  data() {
    return {
      isConfirmModalOpen: false,
      isActiveHighSchool: "",      
      currentTab: 1,
      lastTimeUpdated: "",
      lastDateUpdated: "",
      updateInfo:[],
      updateContact:[],
    };
  },
  methods: {
    changeStatus() {
      if (this.school.active == 1) {        
        this.updateHighSchool({id:this.school.id,active:-1} )
        this.$emit('onStatusChange',-1)        
      } else {       
        this.updateHighSchool({id:this.school.id,active:1})
        this.$emit('onStatusChange',1)
      } 
    },
    onUpdateInfoFunc(data){
      this.updateInfo = data
    },
    onUpdateContactFunc(data){
      this.updateContact = data
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
    setStatus() {      
      return this.school.active == 1;      
    },
    setHighSchool() {
      const store = useHsStore();
      return store.$state.highSchools.find(
        (school) => school.id == this.$route.params.highschoolname
      );
    },
    async updateHighSchool(obj){
      const res = await post('high_school/update',obj,'admin')                  
      if(res?.data){
        showModal({text:res.data});
      }
    },
    async publish() {        
      this.updateHighSchool({
            ...this.updateInfo,
            ...this.updateContact,
          })          
    },
    createProfile() {
      if (this.publish()) {
        // window.alert("Profile Created");
        this.isConfirmModalOpen = false;
      } else {
        // window.alert("Profile Creation Error");
        this.isConfirmModalOpen = false;
      }
    },
    setUpdatedTime() {
      const updatedTime = this.school.updated_at.split("T");
      console.log(updatedTime[1]);
      this.lastTimeUpdated = updatedTime[1].split(".")[0];
      this.lastDateUpdated = updatedTime[0];
    },
  },
  beforeMount() {
    this.isActiveHighSchool = this.setStatus();
 /*    this.school = this.setHighSchool();
    this.setUpdatedTime(); */
    // console.log(this.school);
    // const store = useUniversityStore()
    // console.log(this.$route.params.universityname)
  },
};
</script>

<style scoped>
:deep(.p-inputswitch-checked .p-inputswitch .p-inputswitch-slider) {
  background-color: var(--teal-500) !important;
}
</style>
