<template>
  <div class="">
    <!-- Page Header -->
    <button @click="back()" class="btn btn-light btn-sm ms-4">
      <i class="pi pi-arrow-left cursor-pointer"></i>
    </button>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div class="position-relative me-4 mb-3">
          <img style="width: 90px; border-radius: 12px; max-height: 90px;" alt="uni-img"
            :src="universityData.cover_photo_url != '' ? universityData.cover_photo_url : '@/assets/images/universities-list/universityDataImg.svg'" />
          <span style="
              width: 15px;
              height: 15px;
              border-radius: 100%;
              margin-right: 2px;
              margin-bottom: 1px;
              position: absolute;
              right: 5px;
            " :style="(universityData.status === 'Active' && 'background-color:green') ||
              (universityData.status === 'Pending' &&
                'background-color:rgba(249, 112, 102, 1)') ||
              (universityData.status === 'Inactive' && 'background-color:red')
              "></span>
        </div>

        <div class="d-flex flex-column align-items-start">
          <h1>{{ universityData.name }}</h1>
          <p>
            Last Login: {{ lastTimeUpdated }} ,
            {{ lastDateUpdated }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">

        <div class="d-flex align-items-center justify-content-center">
          <InputSwitch style="" @change="changeStatus" v-model="isActiveUniversity" />
          <p style="font-weight: 600; font-size: 12px !important" class="m-0 p-0 ms-1">
            {{ universityData.active == 1 ? "Active" : "Inactive" }}
          </p>
        </div>
        <p>Date: 2022-05-2</p>
      </div>
    </div>
    <div class="mt-3 ps-5 border-bottom w-100 d-flex align-items-center">
      <!-- Tabs -->
      <div @click="changeTab(1)" :class="{ 'me-5 p-3 Tabx': true, 'activeTab': (currentTab === 1) }">
        University Information
      </div>
      <div @click="changeTab(2)" :class="{ 'me-5 p-3 Tabx': true, 'activeTab': (currentTab === 2) }">
        Contact Details
      </div>
      <div @click="changeTab(3)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); "
        :style="currentTab === 3 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'"
        class="p-3">
        Commission
      </div>
      <div @click="changeTab(4)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); "
        :style="currentTab === 4 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'"
        class="p-3">
        Agreement
      </div>
      <div @click="changeTab(5)" :class="{ 'me-5 p-3 Tabx': true, 'activeTab': (currentTab === 3) }">
        Virtual Tour
      </div>
      <div @click="changeTab(6)" :class="{ 'me-5 p-3 Tabx': true, 'activeTab': (currentTab === 4) }">
        General Settings
      </div>
    </div>

    <div v-show="currentTab == 1">
      <UniversityInformationTab :university="universityData" @onchange="updateInfo($event)" />
    </div>
    <div v-show="currentTab === 2">
      <ContactDetailsTab :countries="countries" :contact="universityData" @onchange="updateContact($event)" />
    </div>
    <div v-show="currentTab === 3">
      <CommissionTab :commission_targets="universityData?.commission_targets"
        :commission_type="universityData?.commission_type" :commission_value="universityData?.commission_value"
        @updateCommission="updateCommissionFunc($event)" />
    </div>
    <div v-show="currentTab === 4">
      <AgreementTab :documents="universityData?.agreement_letters" @updateLetter="updateLetterFunc($event)" />
    </div>
    <div v-show="currentTab === 5">
      <VirtualTour :university_id="university.id" />
    </div>
    <div v-show="currentTab === 6">
      <GeneralSettingsTab :university="universityData" />
    </div>
    <div v-if="currentTab !== 5 && currentTab !== 6"
      class="d-flex align-items-center justify-content-between w-80 m-auto mt-5">
      <!-- Footer Buttons -->
      <button class="border py-2 px-5">Cancel</button>
      <button v-if="currentTab === 1" @click="changeTab(currentTab + 1)" class="bg-warning border py-2 px-5">
        Next
      </button>
      <button v-else @click="openConfirmModal" class="bg-warning border py-2 px-5">
        Save Changes
      </button>
    </div>
    <ConfirmModal v-if="isConfirmModalOpen" @closeConfirmModal="closeConfirmModal" @createProfile="createProfile" />
  </div>
</template>

<script>
import UniversityInformationTab from "@/components/UniversitiesList/UniversityInformationTab.vue";
import ContactDetailsTab from "@/components/UniversitiesList/ContactDetailsTab.vue";
import GeneralSettingsTab from "@/components/UniversitiesList/ViewUniversityDetails/GeneralSettingsTab.vue";
import ConfirmModal from "@/components/UniversitiesList/ConfirmModal.vue";
import VirtualTour from "@/components/UniversitiesList/VirtualTour.vue";
import { useUniversityStore } from "@/stores/universities";
import CommissionTab from "@/components/UniversitiesList/CommissionTab.vue";
import AgreementTab from "@/components/UniversitiesList/AgreementTab.vue";
// import { universities } from "@/data";
import { post } from '@/api/client';
import { showModal } from '@/plugins/modal';
export default {
  name: "UniversityDetail",
  props: ['university', "countries"],
  components: {
    GeneralSettingsTab,
    ContactDetailsTab,
    UniversityInformationTab,
    ConfirmModal,
    VirtualTour,
    CommissionTab,
    AgreementTab
  },
  data() {
    return {
      universityData: {},
      isConfirmModalOpen: false,
      isActiveUniversity: "",
      currentTab: 1,
      lastTimeUpdated: "",
      contact: {},
      info: {},
      lastDateUpdated: "",
      updateLetters: [],
      updateCommissions: {},
      tours: [
        {
          "id": 1,
          "name": "University Entrance",
          "description": "description here",
          "university_id": 1,
          "url": "https://paramountimages.s3.amazonaws.com/King's_University/2023_06/hl344xwNBfo2B73p1EqLxmSsV07eHNH38grnparR.jpg",
          "file_type": "image",
          "created_at": "2023-06-15T16:04:10.000000Z",
          "updated_at": "2023-06-15T16:04:10.000000Z",
          "hotspots": [
            {
              "id": 1,
              "scene_id": 1,
              "name": "School Bus Stop",
              "type": null,
              "position_x": 10,
              "position_y": 20,
              "created_at": "2023-06-15T16:09:41.000000Z",
              "updated_at": "2023-06-15T16:09:41.000000Z",
              "actions": [
                {
                  "id": 1,
                  "hotspot_id": 1,
                  "type": "DISPLAY_INFORMATION",
                  "data": "url",
                  "created_at": "2023-06-15T16:13:44.000000Z",
                  "updated_at": "2023-06-15T16:57:50.000000Z"
                },
                {
                  "id": 2,
                  "hotspot_id": 1,
                  "type": "NAVIGATE_TO_SCENE",
                  "data": "url",
                  "created_at": "2023-06-15T16:55:46.000000Z",
                  "updated_at": "2023-06-15T16:55:46.000000Z"
                }
              ]
            },
            {
              "id": 2,
              "scene_id": 1,
              "name": "School Bus Stop",
              "type": null,
              "position_x": 10,
              "position_y": 20,
              "created_at": "2023-06-15T16:10:23.000000Z",
              "updated_at": "2023-06-15T16:10:23.000000Z",
              "actions": []
            }
          ]
        },
        {
          "id": 2,
          "name": "Outside University Entrance2",
          "description": "description here",
          "university_id": 1,
          "url": "https://paramountimages.s3.amazonaws.com/King's_University/2023_06/pyVzOLNv5NFAixZfGqa9l2oauhIH6mp4s4TOObfh.jpg",
          "file_type": "image",
          "created_at": "2023-06-15T16:04:49.000000Z",
          "updated_at": "2023-06-15T16:06:02.000000Z",
          "hotspots": []
        }
      ]
    };
  },
  methods: {
    updateInfo(data) {
      this.info = data
    },
    updateContact(data) {
      this.contact = data
    },
    updateLetterFunc(e) {
      this.updateLetters = e;
    },
    updateCommissionFunc(e) {
      this.updateCommissions = e;
    },
    back() {
      this.$emit('back', false)
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
    setStatus() {
      const store = useUniversityStore();
      return (
        store.$state.universities.find(
          (universityData) => universityData.id == this.$route.params.universityDataname
        ).active === 1
      );
    },
    setUniversity() {
      const store = useUniversityStore();
      return store.$state.universities.find(
        (universityData) => universityData.id == this.$route.params.universityDataname
      );
    },
    setUpdatedTime() {
      const updatedTime = this.universityData.updated_at.split("T");
      console.log(updatedTime[1]);
      this.lastTimeUpdated = updatedTime[1].split(".")[0];
      this.lastDateUpdated = updatedTime[0];
    },
    changeStatus() {
      if (this.university.active == 1) {
        this.updateUniveristy({ id: this.university.id, active: 0 })
        this.$emit('onStatusChange', 0)
      } else {
        this.updateUniveristy({ id: this.university.id, active: 1 })
        this.$emit('onStatusChange', 1)
      }
    },
    async updateUniveristy(obj) {
      const res = await post('university/update_status', obj, 'admin')
      if (res) {
        showModal({ text: res.data.message });
      }
    },
    async publish() {
      const res = await post('university/update', {
        ...this.info,
        ...this.contact,
        letters: this.updateLetters,
        commission_type: this.updateCommissions.commission_type,
        commission_value: this.updateCommissions.commission_value,
        commission_targets: this.updateCommissions.blocks
      }, 'admin')
      if (res) {
        showModal({ text: res.data.message });
      }
    },
    createProfile() {
      if (this.publish()) {
        // window.alert("Profile Created");
        this.isConfirmModalOpen = false;
      } else {
        // window.alert("Profile Creation Error");
        this.isConfirmModalOpen = false;
      }
    },
  },
  created() {
    this.universityData = this.university
  },
};
</script>

<style scoped>
:deep(.p-inputswitch-checked .p-inputswitch .p-inputswitch-slider) {
  background-color: var(--teal-500) !important;
}

.activeTab {
  border-bottom: 4px solid red;
  font-weight: 600
}

.Tabx {
  cursor: pointer;
  border-bottom: 4px solid transparent;
  color: rgb(29, 41, 57);
}
</style>
