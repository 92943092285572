import { defineStore } from 'pinia'

import { useLocalStorage } from "@vueuse/core";
export const useChatsStore = defineStore({
    id: 'chat',
    state: () => ({        
        chats:useLocalStorage("userInfo", {}),
        drafts:[],
        sents:[],
        trashed:[],
        selectedChatInfo:{},
        selected:null
    }),
    getters : {
        getChats: (state) => {
            return state.chats
        },
        getDrafts: (state) => {
            return state.drafts
        },
        getSents: (state) => {
            return state.sents
        },
        getTrashed: (state) => {
            return state.trashed
        },  
        getSelectedChatInfo:(state)=>{
            return state.selectedChatInfo
        },
        getSelected:(state)=>{
            return state.selected
        }     
    },
    persist: {
        enabled: true
    },
    actions: {        
        setChats(chats) {
            this.chats = chats;
        },
        setDrafts(drafts) {
            this.drafts = drafts;
        },
        setSents(sents) {
            this.sents = sents;
        },
        setTrashed(trashed) {
            this.trashed = trashed;
        },
        setSelectedChatInfo(selectedChatInfo){
            this.selectedChatInfo = selectedChatInfo
        },
        setSelected(selected){
            this.selected = selected
        }
        
                  
    }
})
