<template>
  <div class="container h-auto px-0 py-3">
    <div class="applications-main">


      
      <div class="container">


        <div>

          <div class="row d-flex flex-row">
            <div class="col-lg-8">
              <div class="row d-flex flex-row   " v-for="doc in documents" :key="doc.id">
                <div class="col-1  m-6">
                  <div class="img-size  m-2">

                  </div>
                </div>
                <div class="col-6 m-2" style="padding-left: 60px">
                  <h6 class="m-1">{{doc.text}}</h6>
                  <p class="m-1 text-secondary text-xs">
                    {{doc.size}}
                  </p>
                  <p class="m-0 text-secondary text-xs">
                    {{doc.date}}
                  </p>
                </div>
                <div class="col-1" style="padding-left: 10px">
                  <button class="view-btn btn text-dark m-3 px-2 py-2 d-flex">
                    <img class="me-2" :src="require('/src/assets/images/d-board/view.svg')" alt="">
                    view</button>
                </div>
              </div>


            </div>
            
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>



export default {
  name: "StudentDocument",
  components: {  },
 
};
</script>>

<style scoped>
.container {
  margin-bottom: 60px;
}


.application-review div {
  margin-bottom: 10px;
}


.img-size {
  border-radius: 2px;
  background-color: gray;
  height: 80px;
  width: 70px;
}

.label-text {
  font-size: 15px;
}

.btn-container {
  padding: 2px;
}

.dropdown {

}

.view-btn {
  background-color: #EAECF0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .view-btn:hover {
    color: rgb(245, 245, 245) !important;
    box-shadow: inset 150px 0 0 0 #fac515 !important;
} */

.view-btn img {
  width: 20px;
}

.save-next-btn {
  border-radius: 4px;
  width: 100%;
  padding: 0.6rem;
  border: none;
  background-color: #FAC515;
}
</style>