<template>

    <div >        
      <div class="total-information-boxes d-flex align-items-center justify-content-between">
        <!-- Total Information Boxes -->
        <swiper :modules="autoplay"  :slides-per-view="slidesPerView" :space-between="20" :loop="true">
              <swiper-slide  style="display: inline-block;">    
                <div class="total-information-box w-100">
                  <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                  <h2>{{ associationCounter?.total_association || 0 }}</h2>
                  <p>Total High School Association</p>
                </div>
              </swiper-slide>
              <swiper-slide  style="display: inline-block;">    
                <div class="total-information-box w-100">
                  <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                  <h2>{{ associationCounter?.total_joined_high_school || 0 }}</h2>
                  <p>Total Joined High School</p>
                </div>
              </swiper-slide>
              <swiper-slide  style="display: inline-block;">    
                <div class="total-information-box w-100">
                  <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                  <h2>{{ associationCounter?.total_pending_request || 0 }}</h2>
                  <p>Total Pending Request</p>
                </div>
              </swiper-slide>
           <!--    <swiper-slide  style="display: inline-block;">    
                <div class="total-information-box">
                  <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
                  <h2>{{ associationCounter?.total_pending }}</h2>
                  <p>Pending High School Association</p>
                </div>
              </swiper-slide> -->
            </swiper>
      </div>
      <hr />
      <div class="d-flex justify-content-between">
        <FilterOptions :countries="countries" @onFilter="searchFilter($event)" v-if="isShowFilterOptions" />
        <div class="ms-auto">
          <!-- Filter Button -->
          <button @click="toggleFilterOptions" style="background-color: rgb(231, 230, 232)"
            class="border me-4 p-2 px-4 d-flex align-items-center justify-content-between"
            :class="isShowFilterOptions && 'bg-warning'">
            <img class="me-2" alt="" src="@/assets/images/universities-list/filter-icon.svg" />
            Filter
          </button>
        </div>
      </div>
      <hr />
      <div style="overflow-x:auto;height:1400px">
        <!-- University Association List Group -->
  
        <div class="sm:w-[1200px] md:w-[100%] ">
          <div   v-for="(assoc) in associationData?.data" :key="assoc.id">
            <MemberCard :member="assoc.high_school" />
          </div>
        </div>
        <PaginationData :paginationData="associationData" @onFetch="onFetchFunc($event)" />
      </div>              
    </div>
  </template>
  
  <script>  
  import FilterOptions from "@/components/AssociationFilterOptions.vue";
  import MemberCard from "@/components/Association/MemberCard.vue";
  import PaginationData from "@/components/Global/Pagination";
  import { post, get } from "@/api/client";
  import { showModal } from "@/plugins/modal";
  import { useUniversityStore } from "../../stores/universities";  
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Pagination, Autoplay } from "swiper/modules";
  export default {
    name: "AssociationsList",
    props: {
        association:{
            default:{}
        } 
    },
    data() {
      return {
        associationData:{data:[]},
        showModal: false,
        selectedAssociation: {},
        showViewAssociation: false,
        associations: {data:[]},
        isShowFilterOptions: false,        
        post: null,
        error: null,
        associationCounter: {},
        countries: [],
        modules: [Pagination],
        autoplay: [Autoplay],     
      };
    },
    computed: {
      slidesPerView() {      
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 1024) {
          return 3;
        } else if (viewportWidth >= 768) {        
          return 3;
        } else {        
          return 1;
        }
      },
    },
    methods: {
      openMore(assoc){
        assoc.isOpen = !assoc?.isOpen
      },
      closeModal() {
        this.showModal = false;  
        this.letterUrl = null   
      },
      async onFetchFunc(data) {
        this.associations = data;
      },
      showList() {
        (this.showViewAssociation = false), this.loadData();
      },
      async loadData() {
        const res = await post("get_associations",{}, "admin", false);      
        if(res?.message){
          this.associations = res.message;
          this.setAssociations(this.associations);
        }
  
      },
      selectAssociation(association) {
        this.selectedAssociation = association;
        this.showViewAssociation = true;
      },
      back(value) {
        this.showViewAssociation = value;
      },
      setAssociations(associations) {
        const store = useUniversityStore();
        store.$state.associations = associations;
      },
      toggleFilterOptions() {
        this.isShowFilterOptions = !this.isShowFilterOptions;
      },
      getAssociationsList(data) {
        if (!data && data !== undefined) {
          showModal({ text: "There was an error fetching the data!" });
        } else {
          this.setAssociations(data);
        }
      },
      setAssociationStore(data) {
        const store = useUniversityStore();
        store.setAssociations(data);
      },
    },
    beforeMount() {
      this.getAssociationsList();
    },
    mounted() {
      // this.fetchData();
    },
    async created() {      
      //this.loadData();
      if(this.association?.id){        
        const response2 = await get("get_association_dashboard2/"+this.association?.id, 'admin',false);
        if(response2?.message){
            this.associationCounter =response2.message
        }
        
        let res = await post('get_association_members', {
            filters:{association_id: this.association?.id}
        },'admin',false);
        console.log(res,3322)
        if(res?.message){
            this.associationData =res.message
        }
        }
      /* this.docFileCount.map(function(item){
             item.count = res2.responseBody[item.title.toLowerCase().replaceAll(' ','_')]??0
        }) */
    },
    components: { FilterOptions, PaginationData,Swiper,SwiperSlide,MemberCard  },
  };
  </script>
  
  <style scoped>
  .total-information-box {
    width: 245px;
    height: 152px;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: left;
    padding-left: 1rem;
  }
  
  .total-information-box h2 {
    font-size: 60px;
    margin-bottom: 0;
  }
  
  .total-information-box img {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  
  .total-information-box:nth-child(1) {
    background: linear-gradient(180deg,
        rgba(115, 117, 253, 0.2) 0%,
        rgba(115, 117, 253, 0) 100%);
    border: 2px solid rgba(115, 117, 253, 1);
  }
  
  .total-information-box:nth-child(2) {
    background: linear-gradient(180deg,
        rgba(50, 213, 131, 0.2) 0%,
        rgba(50, 213, 131, 0) 100%);
    border: 2px solid rgba(50, 213, 131, 1);
  }
  
  .total-information-box:nth-child(3) {
    background: linear-gradient(180deg,
        rgba(253, 176, 34, 0.2) 0%,
        rgba(253, 176, 34, 0) 100%);
    border: 2px solid rgba(253, 176, 34, 1);
  }
  
  .total-information-box:nth-child(4) {
    background: linear-gradient(180deg,
        rgba(249, 112, 102, 0.2) 0%,
        rgba(249, 112, 102, 0) 100%);
    border: 2px solid rgba(249, 112, 102, 1);
  }
  
  .universityListItem {
    font-size: 14px;
    box-shadow: 0px 4px 20px 0px rgba(16, 24, 40, 0.16);
    padding-top:7px;
    padding-bottom:7px;
    border-radius: 8px;    
    margin: 1rem 0.5rem;
  }
  
  .listItemTitle {
    font-weight: 600;
    color: rgb(102, 112, 133);
    margin-bottom: 12px;
  }
  
  button:hover:not(.p-calendar button) {
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.25s ease-in-out;
  }
  </style>
  