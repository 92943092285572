<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <!-- Header -->
        <h1>{{ `${user.first_name} ${user.last_name}` }}</h1>
        <p>{{ user.email }}</p>
      </div>
      <div>
        <button
          style="background-color: #dc3545; color: white; font-size: xx-large"
          class="border p-2 px-3"
          @click="$emit('closeView')"
        >
          Close
        </button>
      </div>
    </div>
    <hr style="opacity: 1 !important; border: 1px solid black" />
    <div>
      <div
        style="max-width: 730px"
        class="d-flex align-items-center justify-content-between"
      >
        <!-- Fırst Line Inputs -->
        <div class="inputGroup">
          <label for="firstName">First Name</label>
          <input disabled :value="user.first_name" id="firstName" type="text" />
        </div>
        <div class="inputGroup">
          <label for="lastName">Last Name</label>
          <input disabled :value="user.last_name" id="lastName" type="text" />
        </div>
      </div>
      <div
        style="max-width: 730px"
        class="d-flex align-items-center justify-content-between"
      >
        <!-- Second Lıne Inputs -->
        <div class="inputGroup">
          <label for="otherName">Other Name</label>
          <input disabled :value="user.unique_id" id="otherName" type="text" />
        </div>
        <div class="inputGroup">
          <label for="email">Email</label>
          <input disabled :value="user.email" id="email" type="text" />
        </div>
      </div>
      <div
        style="max-width: 730px"
        class="d-flex align-items-center justify-content-between"
      >
        <!-- Third Lıne Inputs -->
        <div class="inputGroup">
          <label for="phone">Phone Number</label>
          <input disabled :value="user.phone" id="phone" type="text" />
        </div>
        <div class="inputGroup">
          <label for="uniqueID">Unique ID</label>
          <input disabled :value="user.unique_id" id="uniqueID" type="text" />
        </div>
      </div>
      <div style="margin-top: 2rem">
        <!-- Upload Photo -->
        <label>Profile Photo</label>
        <div
          style="padding: 24px; border: 1px dashed #4d5056; border-radius: 12px"
          class="d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <img
              :src="
                user.profile_photo_url
                  ? user.profile_photo_url
                  : require('@/assets/images/users-and-roles/uploadImg.svg')
              "
              width="200"
              height="200"
              alt="upload"
            />
          </div>
          <div>
          </div>
        </div>
      </div>

      <div style="margin-top: 2rem">
        <!-- Role Select -->
        <label>Assigned Roles and Permissions</label>
        <p>The user have access to the following roles and permissions.</p>

        <div class="d-flex flex-column">
          <label>Roles</label>
          <div>
            <span v-for="(role, i) in user.roles" :key="i" class="roleBadge">{{
              role.name
            }}</span>
          </div>
        </div>
        <div class="d-flex flex-column mt-2">
          <label>Permissions</label>
          <div>
            <span
              v-for="(permission, i) in user.permissions"
              :key="i"
              class="roleBadge"
              >{{ permission.name }}</span
            >
          </div>
        </div>
      </div>
      <div style="margin-top: 2rem">
        <h4
          style="
            margin-left: 10%;
            display: inline;
            padding: 8px;
            border-bottom: 4px solid rgba(117, 117, 117, 1);
          "
          class=""
        >
          Activity
        </h4>
        <hr class="mt-2" />
        <div id="activitiesContainer">
          <!-- Activities -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img src="@/assets/images/users-and-roles/dotIcon.svg" />
              <p class="m-0 ms-3">Replied to Ansur’s mail</p>
            </div>
            <div>
              <p>12 min ago</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewUser",
  props: ["user"],
};
</script>

<style scoped>
.inputGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1.5rem;
}

.inputGroup:nth-of-type(2) {
  margin-left: 2rem;
}

label {
  font-weight: 600;
  margin-bottom: 4px;
}

input {
  border: 0.5px solid #000000;
  border-radius: 4px;
  height: 40px;
  padding-left: 0.5rem;
}

input[disabled] {
  background-color: rgba(77, 80, 86, 0.4);
}

.roleBadge {
  background-color: rgba(225, 227, 232, 1);
  border-radius: 16px;
  font-weight: 500;
  color: rgba(37, 48, 65, 1);
  padding: 5px 10px;
  display: inline-block;
  font-size: 12px;
  width: fit-content;
  margin: 5px;
}

.roleBadge:first-of-type {
  margin-left: 0;
}

.roleBadge:last-of-type {
  margin-right: 0;
}

#activitiesContainer {
  box-shadow: 0px 2px 10px rgba(58, 53, 65, 0.1);
  border-radius: 6px;
  padding: 20px;
  width: 90% !important;
  margin: auto;
}
</style>
