<template>
     <div :class="{'d-flex':loadingStatus == 100,'d-none':loadingStatus < 100}" class="w-100">                        
        <div style="padding: 10px;height: 100%;width: 20%;">
            <div style="height: 80%;width: 100%;overflow-y: auto;">
                <div v-for="(page, pageIndex) in pdfPages" @click="selectPage(pageIndex)"  :key="pageIndex" :style="{
                        background: pageIndex === selectedPageIndex ? $globals.colorsHex.warningLight : '',padding: '10px',border: '1px solid ' + $globals.colorsHex.warning,width: '100%',margin: '20px 0px',cursor:'pointer'}">
                    <canvas :ref="'pagePrevCanvas_1010' + pageIndex" style="width:100%;" height="200" ></canvas>
                </div>                                                         
            </div>            
        </div>
        <div  style="width:95%;border-left: 1px solid #aaa;">     
            <br/><br/>                       
            <div  style="width:90%;overflow-x: auto;margin-left:auto;margin-right:auto;line-hti">     
                <div class="p-0 position-relative mx-auto" style="width:700px" >
                    <canvas id="pageCanvasRef-1010" style="width:100%;"></canvas>                           
                </div>
            </div>
        </div>
        </div>
        <div v-if="loadingStatus<100" class="justify-content-center align-items-center w-100" style="height:100%;" :class="{'d-flex':loadingStatus < 100,'d-none':loadingStatus >= 100}">
            <CircleProgress  :value="loadingStatus" ></CircleProgress>
        </div>
</template>

<script>

import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";  

import "jquery-ui-dist/jquery-ui";
import { showModal } from "@/plugins/modal";
import CircleProgress from '@/components/Global/CircleProgress.vue'
let pdfDocument = null;

export default {
    props:['url'],
    components:{
        CircleProgress,
    },
    data() {
        return {
            loadingStatus:true,
            pdfPages:null,
            selectedPageIndex:0,            
            getSigns:'',            
            letters:'',
            attachSignature:'',
            pdfScale:3
        }       
    },
    created(){
        this.selectLetter(this.url)
    },
    methods:{
        async selectPage(pageIndex){
            if(this.selectedPageIndex != pageIndex){
                this.selectedPageIndex = pageIndex
                pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;        
                this.initiatOnePage(pageIndex+1, 'pageCanvasRef-1010', this.pdfScale)
            }
        },
        async initiatOnePage(pageIndex, canvasRef, scale= 0.4){      
                                       
            await pdfDocument.getPage(pageIndex).then(async (page) => {               
                let canvas;
                if (canvasRef === 'pageCanvasRef-1010') {
                    canvas = document.getElementById('pageCanvasRef-1010'); // Corrected typo here
                } else {
                    canvas = this.$refs[canvasRef][0]; 
                }

                const context = canvas.getContext("2d");        
                const viewport = page.getViewport({ scale: scale });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({
                canvasContext: context,
                viewport,
                enableWebGL: false,
                });
                return true;
            })  
            return true;
        },
        async selectLetter(url=null){
       
            
            try{          
                await this.loadPdf(url);
                
                    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;                  

                    this.loadingStatus = 0; 
                    setTimeout(async ()=>{
                        for (let n = 0; n < this.pdfPages.length; n++) {
                            await this.initiatOnePage(n + 1, 'pagePrevCanvas_1010' + n, 0.3);

                            if (n === 0) {
                                await this.initiatOnePage(1, 'pageCanvasRef-1010', this.pdfScale);
                            }
                            //update loading status                           
                            this.loadingStatus = Math.ceil(((n + 1) / this.pdfPages.length) * 100);
                            await new Promise(resolve => setTimeout(resolve, 100)); // 100ms delay
                        }
                    },1000)
                }catch(e){                        
                    showModal({text:'Invalid letter'})
                }   
        },
        async loadPdf(url) {        
                
                try{
                    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
                        const loadingTask = pdfjsLib.getDocument({
                        url:url,
                    });
                    
                    pdfDocument = await loadingTask.promise;       
                    this.pdfPages = Array.from(
                        { length: pdfDocument.numPages },
                        (_, pageIndex) => pdfDocument.getPage(pageIndex + 1)
                        );    
            }catch(e){
                console.log(e)
            }
        },
    }
}
</script>
