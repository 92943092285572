// import Vue from 'vue'
// import VueRouter from 'vue-router'

import { createRouter, createWebHistory } from "vue-router";
import IndexView from "../views/IndexVue";

import HelpAndSupport from "../views/HelpAndSupport.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import TermsPrivacy from "../views/terms/Terms-privacy.vue";

import Notification from "../views/notification/Notification.vue";

import TechnicalSupport from "../views/terms/Technical-Support.vue";
import StudentTracking from "@/views/tracking/StudentTracking.vue";

import ChatContactView from "../views/chats/ChatContactView.vue";
//import HighschoolChat from "../views/chats/HighschoolChat.vue";
import UniversitiesList from "../views/UniversitiesList/UniversitiesList.vue";
import HighSchoolsList from "../views/HighSchoolsList/HighSchoolsList.vue";
import HighSchoolDetail from "../views/HighSchoolsList/HighSchoolDetail/HighSchoolDetail.vue";
import CreateHighSchoolProfile from "../views/HighSchoolsList/CreateHighSchoolProfile.vue";

import CreateUniversityProfile from "../views/UniversitiesList/CreateUniversityProfile.vue";
import viewChats from "../views/chats/ChatDetails/viewChats.vue";


import StudentCourse from "@/views/studentcourses/StudentApplication.vue";
import StudentSidebar from "@/views/studentcourses/StudentSidebar.vue";

import HighSchoolSidebar from "../views/highschoolstudent/HighSchoolSidebar.vue";
import HighSchool from "@/views/highschoolstudent/HighSchool.vue";

import FavouriteMessage from "../views/chats/FavouriteMessage.vue";
import UnreadMessage from "../views/chats/UnreadMessage.vue";


import Programmes from "../views/programmes/Programmes.vue";
import CreateProgrammes from "../views/programmes/CreateProgram.vue";

import Promotions from "../views/promotions/Promotions.vue";
import PromotionInsight from "../views/promotions/PromotionInsight.vue";
import CreatePromotions from "../views/promotions/CreatePromotion.vue";

import UsersAndRoles from "../views/UsersAndRoles/UsersAndRoles.vue";
import CreateUser from "../views/UsersAndRoles/CreateUser.vue";
import ViewUser from "../views/UsersAndRoles/ViewUser.vue";
import SignIn from "../views/signIn/SignIn";
import EmailOTP from "../views/otp/EmailOTP";
import middleware from "@/middleware/auth.js";
import SignUp from "../views/signup/SignUp";
import CountryPage from "../views/CountryPage.vue";
import LettersPage from "../views/LettersPage.vue";


import CreateHighschoolAssociation from "../views/HighschoolAssociation/CreateHighschoolAssociation.vue";
import HighschoolAssociation from "../views/HighschoolAssociation/HighschoolAssociationList.vue";
// Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "IndexView",
    component: IndexView,
    // meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "Home",
        redirect: "/dashboard",
      },

      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/universities-list",
        name: "UniversitiesList",
        component: UniversitiesList,
      },    

      {
        path: "/universities-list/create-profile",
        name: "createUniversityProfile",
        component: CreateUniversityProfile,
      },
      {
        path: "/high-schools-list",
        name: "HighSchoolsList",
        component: HighSchoolsList,
      },
      {
        path: "/high-school/:highschoolname",
        name: "HighschoolDetail",
        component: HighSchoolDetail,
      },

      {
        path: "/high-schools-list/create-profile",
        name: "createHighSchoolProfile",
        component: CreateHighSchoolProfile,
      }, 
      {
        path: "/chat/university/:chatOptions?",
        name: "UniversityChat",
        component: ChatContactView,
      },
      {
        path: "/chat/view-chats/:id",
        name: "UniversityChatDetails",
        component: viewChats,
      },
      {
        path: "/chat/high-school/:chatOptions?",
        name: "HighschoolChat",
        component: ChatContactView,
      },     
      {
        path: "/favouritemessage",
        name: "Favouritemessage",
        component: FavouriteMessage,
      },
      {
        path: "/unreadmessage",
        name: "UnreadMessage",
        component: UnreadMessage,
      },
      {
        path: "/help-and-support",
        name: "HelpAndSupport",
        component: HelpAndSupport,
      },

      {
        path: "/student-tracking",
        name: "StudentTracking",
        component: StudentTracking,
      },

      {
        path: "/student-course/:id/profile",
        name: "StudentSidebar",
        component: StudentSidebar,
      },

      {
        path: "/student/course",
        name: "StudentCourse",
        component: StudentCourse,
      },

      {
        path: "/high-school-application",
        name: "HighSchool ",
        component: HighSchool,
      },
      {
        path: "/student-application/:id/profile",
        name: "HighSchoolSidebar",
        component: HighSchoolSidebar,
      },
      {
        path: "/promotions",
        name: "Promotions",
        component: Promotions,
      },
      {
        path: "/promotions/insights/:id",
        name: "PromotionInsight",
        component: PromotionInsight,
      },
      {
        path: "/create-promotions",
        name: "CreatePromotions",
        component: CreatePromotions,
      },
      {
        path: "/programs",
        name: "Programs",
        component: Programmes,
      },
      {
        path: "/create/programs",
        name: "CreatePrograms",
        component: CreateProgrammes,
      },     
      {
        path: "/users-and-roles",
        name: "UsersAndRoles",
        component: UsersAndRoles,
      },
      {
        path: "/users-and-roles/create-user",
        name: "CreateUser",
        component: CreateUser,
      },
      {
        path: "/users-and-roles/user/:userId",
        name: "ViewUser",
        component: ViewUser,
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notification,
      },
      {
        path: "/countries",
        name: "Country",
        component: CountryPage,
      },      
      {
        path: "/letters",
        name: "Letter",
        component: LettersPage,
      },
      {
        path: "/highschool-associations",
        name: "HighschoolAssociationList",
        component: HighschoolAssociation,
      },
      {
        path: "/highschool-associations/create-association",
        name: "CreateHighschoolAssociation",
        component: CreateHighschoolAssociation,
      },
    ],
  },

  {
    path: "/terms-privacy",
    name: "TermsPrivacy",
    component: TermsPrivacy,
  },
  {
    path: "/technical-support",
    name: "TechnicalSupport",
    component: TechnicalSupport,
  },
  {
    path: "/login",
    name: "LogIn",
    component: SignIn,
    meta: { skipAuth: true },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    meta: { skipAuth: true },
  },
  {
    path: "/email-verification",
    name: "EmailOTP",
    component: EmailOTP,
    meta: { skipAuth: true },
  },
];
// const isAthenticated = localStorage.getItem("user");
const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top btw routes
    return { top: 0 };
  },
});
router.beforeEach(middleware);
// router.beforeEach( (to, from, next) => {
//   if (
//     // make sure the user is authenticated
//     isAthenticated && to.path !== '/login'
//   ) {
//     // redirect the user to the login page
//     //return { name: 'LogoIn' }
//     alert('not loggedin')
//     next();

//   }
//   router.push({ name: 'LogIn' });
//     // return '/login'

// })

// router.beforeEach(async (to, _, next) => {
//   if (to.matched.some(routeRecord => routeRecord.meta.requiresAuth)) {
//     if (!isAthenticated) {
//       //next({ name: 'LogIn' })
//       //router.push({ name: 'LogIn' })
//       window.location.replace('/login')
//     }
//   } else {
//     next()
//   }
// })

// router.beforeEach((to, from, next) => {
//   if (
//       // 'requiresAuth' in to.meta &&
//       to.meta.requiresAuth &&
//       !isAthenticated
//   ) {
//       next('/login');
//       console.log('pls login')
//   } else if (
//       // 'auth' in to.meta &&
//       to.meta.requiresAuth &&
//       isAthenticated
//   ) {
//       next('/dashboard');
//       console.log('dashboard')
//   }
//   // else {
//   //     next();
//   // }
// });
//buchi uncomment

// router.beforeEach((to, _, next) => {
//   const privatePages = [
//     "/dashboard",
//     "/help-and-support",
//     "/inbox",
//     "/student-application",
//     "/student-tracking",
//   ];
//   const isAthenticated = localStorage.getItem("user");
//   // redirect the user to the login page if they arent authenticated
//   if (!isAthenticated && privatePages.includes(to.path)) {
//     //return next({ name: 'LogIn' })
//     //return router.push({ name: 'LogIn' })
//     //return window.location.replace("/login");
//     return next('/login')
//   } else if (isAthenticated && to.path === "/login") {
//     // make sure the user is authenticated
//     //router.push('/')
//     //return next({ name: 'Dashboard'})
//     //return (window.location.href = "/dashboard");
//     return next('/dashboard')
//   } else {
//     next();
//   }
// });

// router.beforeEach((to, _, next) => {
//   const privatePages = [
//     "/dashboard",
//     "/help-and-support",
//     "/inbox",
//     "/student-application",
//     "/student-tracking",
//   ];
//   const isAthenticated = localStorage.getItem("user");
//   // redirect the user to the login page if they arent authenticated
//   if ( !isAthenticated && privatePages.includes(to.path) ) {
//     //return next({ name: 'LogIn' })
//     //return router.push({ name: 'LogIn' })
//     //return window.location.replace("/login");
//     console.log(isAthenticated)
//     return next('/login')
//   }  else {
//     next();
//   }
// });

// router.beforeEach((to) => {
//   const publicPages = ['/login'];
//   const authRequired = !publicPages.includes(to.path);
//   if (authRequired && !isAthenticated) {
//     // console.log(authRequired)
//     return '/login'
//   }
// })

export default router;
