//import { useUserStore } from '@/stores/userStore'
import { useAuthStore } from "@/stores/auth";
export default (to, from, next) => {
  if (
    to.fullPath !== "/terms-privacy" &&
    to.fullPath !== "/technical-support"
  ) {
    console.log(to.fullPath);
    const token = useAuthStore().userToken;
    if (token) {
      return to.fullPath == "/login" ? next("/dashboard") : next();
    }

    if (to?.meta?.skipAuth) {
      next();
    }
    console.log("reached here", token);
    return token ? next() : next("/login");
  }
  return next();
};
