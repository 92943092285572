import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useCreateHsProfileStore = defineStore({
  id: "createHsProfileStore",
  state: () => ({
    highSchoolInfo: useLocalStorage("highSchoolInfo", {}),
    contactDetails: useLocalStorage("contactDetails", {}),
  }),
  persist: true,
  actions: {
    setHighSchoolInfo(details) {
      this.highSchoolInfo = details;
    },
    setContactDetails(details) {
      this.contactDetails = details;
    },
  },
});
