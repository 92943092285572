<template>
  <div>
    <div class="my-5 mx-5"> </div>

    <div class="conatainer px-0">
      <div class="my-5 mx-5">
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0"
            >First Name
          </span>
          <span class="col text-truncate p-0">{{ bio.first_name }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0"
            >Fathers Name
          </span>
          <span class="col text-truncate p-0">{{ details.last_name }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0"
            >Mothers Name
          </span>
          <span class="col text-truncate p-0">{{ details?.mother_name }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0"
            >Date Of Birth
          </span>
          <span class="col text-truncate p-0">{{ details.dob }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0">Gender</span>
          <span class="col text-truncate p-0">{{ details.gender }}</span>
        </h6>
        <h6 class="card-title text-dark px-2 py-3 row mb-0">
          <span class="text-secondary col-5 text-truncate p-0"
            >Email Address</span
          >
          <span class="col text-truncate p-0">{{ details.email }}</span>
        </h6>
        <div class="col-lg-4">
          <div class="application-review p-3">
            <h6 class="text-center my-3">Application Review</h6>
            <div class="form-group sub-input sub-input-large">
              <div class="form-group dropdown">
                <Dropdown
                  style="border-radius: 10px"
                  v-model="selectedReview"
                  :options="AppliactionReview"
                  optionLabel=""
                  placeholder="Waiting  Review"
                  class="w-100"                  
                />
              </div>

              <div class="form-group">
                <label class="label-text">Comments</label>
                <Textarea
                  v-model="comment"
                  class="form-control"
                  placeholder="Enter a comment..."
                  :autoResize="true"
                  rows="4"
                  cols="17"
                ></Textarea>
              </div>

              <div class="btn-container">
                <button class="save-next-btn ripple text-dark">
                  Save and Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import { useStudentApplicationStore } from "@/stores/studentApplications";
export default {
  components: { Dropdown },
  props: ["id"],
  data() {
    return {
      stuForm: false,
      selectedReview: null,
      comment:"sddds",
      AppliactionReview: ["waiting review", "approved", "rejected"],
      bio: [],
      details: {},

      documents: [
        {
          id: 1,
          text: "High School Grade MarkSheet",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 2,
          text: "High School Certificates",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 3,
          text: "Formal Photo",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 4,
          text: "Passport font",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 5,
          text: "National ID Photo",
          size: "4.00mb",
          date: "25/05/2022",
        },
      ],
    };
  },
  created(){
    
  },
  methods: {
    setDetails(bio) {
      this.details.firstName = bio.full_name.split(" ")[0];
      this.details.lastName = bio.full_name.split(" ")[1];
      this.details.fatherName = bio.father_name;
      this.details.motherName = bio.mother_name;
      this.details.dob = bio.dob;
      this.details.gender = bio.gender;
      this.details.email = bio.email;
    },
    setBio() {    
    },
  },
  beforeMount() {
    const store = useStudentApplicationStore();
    this.details = store.$state.studentApplications[this.id];
    this.selectedReview = this.details?.application_status
    this.comment = this.details?.comment
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 60px;
}

.application-review div {
  margin-bottom: 10px;
}

.img-size {
  border-radius: 2px;
  background-color: gray;
  height: 80px;
  width: 70px;
}

.label-text {
  font-size: 15px;
}

.btn-container {
  padding: 2px;
}

.dropdown {
}

.view-btn {
  background-color: #eaecf0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .view-btn:hover {
      color: rgb(245, 245, 245) !important;
      box-shadow: inset 150px 0 0 0 #fac515 !important;
  } */

.view-btn img {
  width: 20px;
}

.save-next-btn {
  border-radius: 4px;
  width: 100%;
  padding: 0.6rem;
  border: none;
  background-color: #fac515;
}
</style>
