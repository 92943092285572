<template lang="html">
  <div>
    <div class="d-md-lg-screens">
      <LangModel />
      <OffCanvas />
      
      <div class="px-0 bg-light shadow-sm position-fixed sidenav col-18 vh-100">
        <SideBar />
      </div>
      <div class="d-flex flex-lg-column justify-content-center">
        <main class="row overflow-hidden">
          <NavBar />
          <Transition  name="scale" mode="out-in">
            <div :key="$route.path" class="col mt-5 py-5 container-fluid outer">
              <router-view></router-view>
            </div>
          </Transition>
          <!-- <div class="col mt-5 pt-5 container-fluid">
            
          </div> -->
        </main>
      </div>
    </div>    
  </div>
</template>
<script>
import NavBar from '@/components/NavBar';
import OffCanvas from '@/components/OffCanvas';
import LangModel from '@/components/LangModel';
import SideBar from '@/components/SideBar.vue';
import { get } from '@/api/client';
//import pusher from '@/plugins/pusher.js';

//import Pusher from 'pusher-js';

export default {
  components: { NavBar, OffCanvas, LangModel, SideBar },
  watch: {
    '$globals.reloadNotification'() {
      this.fetchNotifications()
    }
  },
  methods:{
    async fetchNotifications(){
      let res = await get('notifications/admin','admin',false);      
      if(res?.message){                    
        this.$globals.notification = res
      }

      res = await get('chat/admin_new_chat','admin',false);      
      if(res?.message){                            
        this.$globals.chats = res.message
      }
    }
  },
  async created() { 
    this.fetchNotifications()
  },
  mounted(){ 
    const $this = this;
          window.myChannel = window.Echo.private('chat.admin');          
          window.myChannel
            .listen(".new-message", function(data){                          
              if(data.user_type =='highSchoolEmployee'){
                $this.$globals.chats.highschool.push(data);
              }else{
                $this.$globals.chats.university.push(data);
              }
            });
          window.Echo.channel('notification').listen('.NewNotification', (data) => {                       
              this.$globals.notification.total_unread +=1
              this.$globals.notification.message.data.unshift(data)              
          });        
          window.Echo.connector.pusher.connection.bind('connected', () => {
            console.log('Laravel Echo connected');  
          });     
  }
};
</script>
<style scoped>
.mt-6 {
  margin-top: 6rem;
}
</style>
<style>
@media only screen and (max-width: 600px) {
.notification-text{
  font-size: 0.85em;
}
.notification li:first-child{
  display:flex;
  flex-direction: column;
  align-items: center;
}
.notification li:nth-child(2){
  margin:0px auto !important;
}
}

@media screen and (max-width: 992px) {
  .sidenav {
    display: none !important;
  }

  main {
    width: 100%;
  }

  .w-81-4 {
    width: 100%;
  }

  .menu-toggle-btn {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .col-18 {
    width: 18.7% !important;
    top: 0;
    left: 0;
  }

  .sidenav {
    display: block !important;
  }

  main {
    margin-left: 18.7% !important;
    width: calc(100% - 18.7%);
  }

  .w-81-4 {
    width: 81.4%;
  }

  .menu-toggle-btn {
    display: none;
  }
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

</style>
