import { get } from "@/api/client";

export default async (callback) => {
  try {
    let promo = await get("allPromotions", "api");
    // promo = prom;
    callback(promo);
    return promo;
  } catch (err) {
    callback(false);
    console.log(err);
    return false;
  }
};
