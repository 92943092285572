<template lang="html">
    <!-- Create FAQ form -->
    <div class="position-relative w-80 mx-auto">
        <h2 class="text-center mb-5">Frequently Asked Questions</h2>
        <button @click="showCreateFaq = !showCreateFaq"
            class="navbar-x-toggle dropdown-toggle btn menuBtn position-absolute text-warning"
            style="right:5px; top: 10px;font-size: 1.2em;"></button>
        <div class="row w-100" v-show="showCreateFaq">
            <div class="col-12">
                <button @click="{ faq.platform = ''; faq.answer = 'Answer', faq.question = '', delete faq.id }"
                    v-if="faq?.id" class="btn btn-light py-1 px-3">New</button>
            </div>
            <div class="col-md-6 mb-3">
                <input v-model="faq.question" type="text" class="w-100 required form-control" placeholder="Question" />
            </div>

            <div class="col-md-6 mb-3">
                <DropDown :options="['university', 'highschool', 'student']" v-model="faq.platform" placeholder=""
                    class="w-100 required" />
            </div>
            <div class="col-12 mb-3">
                <textarea cols="8" class="form-control" v-model="faq.answer"></textarea>
            </div>
            <div class="col-12 mb-3">
                <button @click="saveFaq" class="btn btn-warning" v-html="faq?.id ? 'Update FAQ' : 'Add FAQ'"> </button>
            </div>
        </div>
    </div>
    <!-- End Create FAQ from -->
    <div class="faq-container mx-auto w-80 mb-3">
        <SkeletalLoader v-show="faqsLoading" />
        <div v-for="(faq, index) in faqs?.data" :key="index" class="position-relative ">
            <h3 class="d-flex justify-content-between inactiveFAQ" @click="openFaq($event)">
                <span>{{ faq?.question }}</span>
                <div>
                    <span>{{ faq.platform }} </span>
                    <button @click="openFaqMenu($event)" class="dropdown-toggle  faqMenuBtn btn p-0 text-warning"></button>
                </div>
            </h3>
            <nav class="navbar-x">
                <ul class="navbar-x-nav">
                    <li class="nav-item">
                        <a class="nav-link" @click="editFaq(faq, index)" href="#">Edit</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="deleteFaq(faq, index)" href="#">Delete</a>
                    </li>
                </ul>
            </nav>
            <p class="p-3 inactiveFAQ" v-html="convertYouTubeLinksToEmbedded(faq?.answer)">
            </p>

        </div>
        <div>
            <PaginationData :paginationData="faqs" @onFetch="onFetchFunc($event, 'faqs')" class="mx-auto mt-2" style="width: max-content;"/>
        </div>
        <hr>
        <!-- Begin Guides Content -->
        <div class="help-videos-container position-relative">
            <h2 class="text-center mb-5 mb-4">Paramount Students help guide in videos</h2>
            <button @click="showCreateGuide = !showCreateGuide"
                class="navbar-x-toggle dropdown-toggle btn menuBtn position-absolute text-warning"
                style="right:5px; top: 10px;font-size: 1.2em;"></button>
            <div class="row w-100" v-show="showCreateGuide">
                <div class="col-12">
                    <button @click="{ guide.title = ''; guide.link = '', guide.platform = '', delete guide.id }"
                        v-if="guide?.id" class="btn btn-light py-1 px-3">New</button>
                </div>
                <div class="col-md-4 mb-3">
                    <input v-model="guide.title" type="text" class="w-100 required form-control" placeholder="Title" />
                </div>

                <div class="col-md-4 mb-3">
                    <input v-model="guide.link" type="text" class="w-100 required form-control" placeholder="https://" />
                </div> 
                <div class="col-md-4 mb-3">
                <DropDown :options="['university', 'highschool', 'student']" v-model="guide.platform" placeholder="Platform"
                    class="w-100 required" />
                </div>         
                <div class="col-4 mb-3">
                    <button @click="saveGuide" class="btn btn-warning" v-html="guide?.id ? 'Update Guide' : 'Add Guide'">
                    </button>
                </div>
            </div>
            <div class="help-videos mb-3">
                <SkeletalLoader v-show="guidesLoading" />
                <div v-for="(guide, index) in guides" :key="index" class="d-flex align-items-center flex-column position-relative">
                    <button @click="openGuideMenu($event)" class="dropdown-toggle  faqMenuBtn btn p-0 text-warning" style="float:right;"></button>
                    <nav class="navbar-x">
                        <ul class="navbar-x-nav">
                            <li class="nav-item">
                                <a class="nav-link" @click="editGuide(guide, index)" href="#">Edit</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="deleteGuide(guide, index)" href="#">Delete</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="videoContainer help-video" v-if="isVideoUrl(guide.link)">                        
                        <video :src="guide.link" width="250" height="130" controls></video>
                    </div>
                    <div class="videoContainer help-video" v-else-if="isYouTubeUrl(guide.link)">                        
                        <iframe width="250" height="130" :src="embedYouTubeUrl(guide.link)" frameborder="0"
                            allowfullscreen></iframe>
                    </div>
                    <div class="videoContainer help-video" v-else-if="isImageUrl(guide.link)">                        
                        <img :src="guide.link" width="250" height="130" />
                    </div>
                    <h3 class="mt-1">{{ guide.title }}</h3>
                </div>

            </div>
        </div>

        <div class="tech-form-container">
            <h3 class="text-center">Paramount Students Technical Service Center</h3>
            <p class="text-center pt-4">
                You can reach us anytime via
                <a href="mailto: support@paramountstudents.com">
                    support@paramountstudents.com</a>
            </p>
            <div class="ps-5 border-bottom w-100 d-flex align-items-center">
                <div @click="currentTab = 2" :class="{ 'tabs p-3': true, activeTab: currentTab === 2 }">
                    Opened Tickets
                </div>
                <div @click="currentTab = 3" :class="{ 'tabs p-3': true, activeTab: currentTab === 3 }">
                    Closed Tickets
                </div>
            </div>
            <div v-show="currentTab === 2" style="height: 500px" class="accordion mx-auto overflow-auto"
                id="accordionOpenTicket">
                <h4 v-if="openTickets?.data?.length == 0" class="mx-auto mt-4" style="width: fit-content">
                    No any pending tickets
                </h4>
                <div v-else v-for="ticket in openTickets?.data" :key="ticket.id" class="accordion-item">
                    <h2 class="accordion-header" :id="'headingOne_' + ticket.id">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#collapseOne_' + ticket.id" aria-expanded="true" aria-controls="collapseOne">
                            Subject:: {{ ticket.subject }}
                        </button>
                    </h2>
                    <div :id="'collapseOne_' + ticket.id" class="accordion-collapse collapse"
                        :aria-labelledby="'headingOne_' + ticket.id" data-bs-parent="#accordionOpenTicket">
                        <div class="accordion-body">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    <b class="mt-2">Issue:</b>
                                    <p class="mb-3">{{ ticket.description }}</p>
                                </div>
                                <button type="button" @click="updateTicketStatus(ticket.id, 'closed')"
                                    class="btn btn-success align-self-start" style="background-color: #1d2939">
                                    Resolved
                                </button>
                            </div>
                            <ReplyTicket :ticket_id="ticket.id" :replies="ticket.replies" showReplyButton="visible" :key="openTicketsReplyKey"/>
                        </div>
                    </div>
                </div>
                <div>
                <PaginationData :paginationData="openTickets" @onFetch="onFetchFunc($event, 'openTickets')" class="mx-auto mt-3" style="width: max-content;"/>
            </div>
            </div>
            <div v-show="currentTab === 3" style="height: 500px" class="accordion mx-auto overflow-auto"
                id="accordionClosedTicket">
                <div class="accordion mx-auto" id="accordionExample3">
                    <h4 v-if="closedTickets?.data?.length == 0" class="mx-auto mt-4" style="width: fit-content">
                        No closed tickets.
                    </h4>
                    <div v-else v-for="ticket in closedTickets?.data" :key="ticket.id" class="accordion-item">
                        <h2 class="accordion-header" :id="'headingOne_' + ticket.id">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#collapseOne_' + ticket.id" aria-expanded="true"
                                :aria-controls="'collapseOne' + ticket.id">
                                Subject:: {{ ticket.subject }}
                            </button>
                        </h2>
                        <div :id="'collapseOne_' + ticket.id" class="accordion-collapse collapse"
                            :aria-labelledby="'headingOne_' + ticket.id" data-bs-parent="#accordionClosedTicket">
                            <div class="accordion-body">
                                <div class="d-flex flex-row justify-content-between">
                                    <div>
                                        <b class="mt-2">Issue:</b>
                                        <p class="mb-3">{{ ticket.description }}</p>
                                    </div>
                                    <button type="button" @click="updateTicketStatus(ticket.id, 'open')"
                                        class="btn btn-success align-self-start" style="background-color: #1d2939">
                                        Reopen
                                    </button>
                                </div>
                                <ReplyTicket :ticket_id="ticket.id" :replies="ticket.replies" showReplyButton="hidden" :key="openTicketsReplyKey"/>
                            </div>
                        </div>
                    </div>
                    <div>
                <PaginationData :paginationData="closedTickets" @onFetch="onFetchFunc($event, 'closedTickets')" class="mx-auto mt-3" style="width: max-content;" />

            </div>
                </div>
            </div>
            
        </div>
        <div class="live-chat-container text-end">
            <button id="live-chat">
                <img :src="require(`/src/assets/images/message-square.png`)" alt="" />
                Live Chat With Us
            </button>
        </div>
        <ResendModal :head="modalProps.heading" :subText="modalProps.response" :img="modalProps.image" />
    </div>
</template>
<script>
import ReplyTicket from "@/components/ReplyTicket.vue";
import ResendModal from "@/components/Global/ResendModal.vue";
import { Modal } from "bootstrap";
import { useAuthStore } from "@/stores/auth";
import { get, post } from "@/api/client";
import PaginationData from "@/components/Global/Pagination";
import SkeletalLoader from "@/components/Global/SkeletalLoader.vue";
import { showModal } from "@/plugins/modal";
import jquery from "jquery";
const $ = jquery;
export default {
    name: "HelpAndSupport",
    data() {
        return {
            faqsLoading: true,
            currentId: 1,
            animate: true,
            attachedFileName: "",
            submitting: false,
            submittingStyle: {},
            modalProps: {
                response:
                    "We'll get back to you within 24 hours. Thank you so much for getting in touch with us",
                image: "sent-success-icon.svg",
                heading: "request sent",
            },
            form: {
                subject: "",
                description: "",
                ticket_category_id: "",
            },
            options: [],
            openTickets: [],
            closedTickets: [],
            currentTab: 2,
            faqs: [],
            faq: {
                question: "",
                answer: "",
                platform: "",
            },
            showCreateFaq: false,
            faqIndex: null,
            guides: [],
            guide: {
                title: "",
                link: "",
                platform: "",
            },
            guideIndex: null,
            showCreateGuide: false,
            guidesLoading: true,
            fetchInterval: null,
            openTicketsReplyKey:0
        };
    },
    methods: {
        convertYouTubeLinksToEmbedded(text) {
            const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w\\-]+)(?:\S+)?/g;
            return text.replace(youtubeRegex, (match, videoId) => {
                return `<div style="display: flex; justify-content: center;"><iframe width="260" height="155" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe></div>`;
            });
        },
        isYouTubeUrl(url) {
            const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
            return youtubeRegex.test(url);
        },
        embedYouTubeUrl(url) {
            const videoId = this.getYouTubeVideoId(url);
            return `https://www.youtube.com/embed/${videoId}`;
        },
        getYouTubeVideoId(url) {
            const youtubeRegex =
                /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/;
            const match = url.match(youtubeRegex);
            if (match && match[3]) {
                return match[3];
            }
            return "";
        },
        isVideoUrl(url) {
            const videoExtensions = [".mp4", ".avi", ".mov", ".wmv"];
            const extension = url.substr(url.lastIndexOf(".")).toLowerCase();
            return videoExtensions.includes(extension);
        },
        isImageUrl(url) {
            const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
            const extension = url.substr(url.lastIndexOf(".")).toLowerCase();
            return imageExtensions.includes(extension);
        },
        editGuide(guide,index){
            this.guide = JSON.parse(JSON.stringify(guide));
            this.guideIndex = index;
            this.showCreateGuide = true;
        },
        async deleteGuide(guide, index) {
            let res = await showModal({
                text: "Are you sure you want to delete",
                confirmText: "Yes",
                cancelText: "No",
            });
            if (res) {
                let a = await post("help_guides/delete/" + guide.id, {}, "admin", false);
                if (a?.message) {
                    this.guides.splice(index, 1);
                    showModal({ text: a?.message });
                }
            }
        },   
        async saveGuide() {
            let a = null;
            if (this.guide?.id) {
                a = await post("help_guides/update/" + this.guide.id, this.guide, "admin", false);
                if (a?.message) {
                    this.guides[this.guideIndex] = JSON.parse(JSON.stringify(this.guide));
                }
            } else {
                a = await post("help_guides", this.guide, "admin", false);
                if (a) {
                    this.guides.unshift(a.message);
                    a.message = "guide created";
                }
            }

            if (a?.message) {
                showModal({ text: a.message });
            }
        },  
        openGuideMenu(e) {
            let elt = $(e.target);
            elt.next().toggle();
        },
        openFaqMenu(e) {
            let elt = $(e.target);
            elt.parent().parent().next().toggle();
        },
        editFaq(faq, index) {
            this.faq = JSON.parse(JSON.stringify(faq));
            this.faqIndex = index;
            this.showCreateFaq = true;
        },
        async deleteFaq(faq, index) {
            let res = await showModal({
                text: "Are you sure you want to delete",
                confirmText: "Yes",
                cancelText: "No",
            });
            if (res) {
                let a = await post("faqs/delete/" + faq.id, {}, "admin", false);
                if (a?.message) {
                    this.faqs.data.splice(index, 1);
                    showModal({ text: a?.message });
                }
            }
        },
        async saveFaq() {
            let a = null;
            if (this.faq?.id) {
                a = await post("faqs/update/" + this.faq.id, this.faq, "admin", false);
                if (a) {
                    this.faqs.data[this.faqIndex] = JSON.parse(JSON.stringify(this.faq));
                }
            } else {
                a = await post("faqs", this.faq, "admin", false);
                if (a) {
                    this.faqs.data.push(a.message);
                    a.message = "FAQ created";
                }
            }

            if (a?.message) {
                showModal({ text: a.message });
            }
        },
        onFetchFunc(data, type) {
            if (type == "faqs") {
                this.faqs = data;
                this.faqsLoading = false;
            }
            if (type == "openTickets") {
                this.openTickets = data;
                // this.openTicketsLOading = false;
            }
            if (type == "closedTickets") {
                this.closedTickets = data;
                // this.closedTicketsLoading = false;
            }
        },
        openFaq(e) {
            let elt = $(e.target);
            elt.next().next().slideToggle();
            elt.next().next().toggleClass(" inactiveFAQ ");
            elt.toggleClass(" activeFAQ ");
            elt.toggleClass(" inactiveFAQ ");
        },
        async submitForm() {
            const authStore = useAuthStore();
            if (!this.submitting) {
                let params = {
                    platform: "university",
                    ticket_category_id: `${this.form.ticket_category_id}`,
                    subject: this.form.subject,
                    description: this.form.description,
                    status: "open",
                    email: authStore.$state.userInfo.email,
                    full_name: `${authStore.$state.userInfo.first_name} ${authStore.$state.userInfo.last_name}`,
                    phone_number: authStore.$state.userInfo.phone,
                    user_id: `${authStore.$state.userInfo.id}`,
                };
                await post("tickets", params);

                this.submitting = true;
                this.submittingStyle = { opacity: 0.5, cursor: "no-drop" };
                // eslint-disable-next-line no-undef
                var myModal = new Modal(document.getElementById("staticBackdrop"), {
                    keyboard: false,
                });

                this.form.description = "";
                this.form.ticket_category_id = "";
                this.form.subject = "";

                myModal.show();

                this.openTickets = await this.fetchTickets("open");

                setTimeout(() => {
                    this.submitting = false;
                    this.submittingStyle = {};
                }, 3000);
            }
        },
        async updateTicketStatus(ticket_id, status) {
            await post("tickets/update_status", { ticket_id, status }, "admin");
            this.openTickets = await this.fetchTickets("open");
            this.closedTickets = await this.fetchTickets("closed");
        },
        async fetchTickets(status) {
            let a = await get(
                `tickets?status=${status}`,
                "admin"
            );
            return a.message;
        },
        attachFile(e) {
            let attachedFile = e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(attachedFile);
            /* reader.onload = (e) => {
                                          e;
                                      }; */
            this.attachedFileName = attachedFile.name;
        },
        showAnswer(index) {
            this.questions.find(function (question, questionIndex) {
                if (index == questionIndex) {
                    question.active = !question.active;
                }
            });
        },
        home() {
            this.$router.push("/");
        },
        displayText(id, termsText) {
            this.currentId = id;
            this.termsDisplay = termsText;
            let mainBar = document.querySelector(".main-bar");
            if (mainBar.classList.contains("fadeIn") === false) {
                mainBar.classList.add("fadeIn");
            }
            setTimeout(() => {
                mainBar.classList.remove("fadeIn");
            }, 1000);
        },
        async getFaqs() {
            let a = await get("faqs", "admin");
            this.faqs = a.message;
            this.faqsLoading = false;
        },
        async getGuides() { 
            let a = await get("help_guides", "admin",false);            
            this.guides = a?.message;
            this.guidesLoading = false;
            
        },
    },
    components: { ResendModal, ReplyTicket, PaginationData, SkeletalLoader },
    computed: {
        disableSubmit() {
            if (
                !this.form.subject ||
                !this.form.ticket_category_id ||
                !this.form.description
            )
                return true;
            return false;
        },
    },
    async beforeCreate() {
        let a = await get("tickets_categories", "api");
        this.options = a.message;
        this.openTickets = await this.fetchTickets("open");
        this.closedTickets = await this.fetchTickets("closed");
        this.getFaqs();
        this.getGuides()
    },
    async mounted() {
        this.$nextTick(() => {
            $(document).on("click", function (event) {
                if (!$(event.target).closest(".faqMenuBtn")?.length) {
                    $(".navbar-x").hide();
                }
            });
        });

        this.fetchInterval = setInterval(async () => {
             this.openTickets = await this.fetchTickets("open");
             this.closedTickets = await this.fetchTickets("closed");
             this.openTicketsReplyKey++
        }, 60000);
    },
    beforeUnmount(){
        clearInterval(this.fetchInterval)
    }
    
};
</script>
<style lang="scss">
$text-color: #1d2939;

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $text-color;
}

// sass mixins
@mixin button-style {
    padding: 0.6rem 0rem;
    border-radius: 0.5rem;
    border: none;
    font-weight: bold;
    width: 200px;
    //width: 274px;
}

.videoContainer {
    position: relative;
    text-align: center;
    color: white;
}

.videoContainer>img:first-child {
    opacity: 0.6;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform 2s, filter 1.5s ease-in-out;
    transform: translate(-50%, -50%) scale(0.9);
}

.hoverable:hover {
    filter: grayscale(0);
    transform: translate(-50%, -50%) scale(1.1);
    cursor: pointer;
}

.timer {
    position: absolute;
    bottom: 0px;
    right: 16px;
    font-size: 12px !important;
    color: rgb(32, 32, 32);
}

.videoContainer {
    position: relative;
    text-align: center;
    color: white;
}

.videoContainer>img:first-child {
    opacity: 0.6;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform 2s, filter 1.5s ease-in-out;
    transform: translate(-50%, -50%) scale(0.9);
}

.hoverable:hover {
    filter: grayscale(0);
    transform: translate(-50%, -50%) scale(1.1);
    cursor: pointer;
}

.timer {
    position: absolute;
    bottom: 0px;
    right: 16px;
    font-size: 12px !important;
    color: rgb(32, 32, 32);
}

// conditional classes
.activeArrow {
    transform: rotate(-90deg);
}

.activeFAQ {
    opacity: 1;
    font-weight: 600;
    border: 1px solid #1d2939;
    border-left: 3px solid black;
}

.inactiveFAQ {
    display: none;
    border: solid gray;
    border-width: 1px 1px 0 1px;
}

div.faq-container {
    & h3 {
        // border: 1px solid gray;
        cursor: pointer;
        font-size: 1rem;
        padding: 1.2rem;
        margin: 0%;
        opacity: 0.75;
        color: #1d2939;

        &:hover {
            background-color: #fff;
            font-weight: bold;
        }

        span {
            transition: background-color 2s, transform 2s;
            display: inline-block;

            img {
                transition: transform 1s;
            }
        }
    }

    //apply borther width to the last element
    & div:last-child {
        h3 {
            border-width: 1px;
        }
    }
}

div.help-videos-container {
    padding: 0 0 0 2rem;

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-bottom: 1px solid #f79009;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #f79009;
        border: 1px solid #f79009;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #f79009;
    }

    h3 {
        margin: 5rem 0rem 0rem;
    }

    .help-videos {
        width: 100%;
        display: flex;
        overflow-y: auto;
        flex-wrap: nowrap;
        margin-top: 2rem;
        margin-bottom: 1rem;

        &>div {
            // border: 1px solid rgb(214, 207, 207);
            // padding: 1rem;
            margin: 2rem 1rem;
            flex-grow: 2;

            div {
                border: 1px solid gray;
                padding: 1rem;
                width: 281px;
                height: 181px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #c4c4c4;
            }

            h3 {
                font-size: 1.3rem;
                font-weight: 500;
                margin-top: 1rem;
                color: #101828;
            }
        }
    }
}

div.tech-form-container {
    padding: 4rem 2rem 2rem;

    form {
        ::placeholder {
            color: #98a2b3;
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #98a2b3;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #98a2b3;
        }

        .label-txt {
            color: #1d2939;
            font-size: 14px;
        }

        &>div {
            margin: 1rem 0rem;

            label {
                padding: 0.5rem 0rem;
            }

            .remove-file {
                padding: 0.25rem;
                border: 1px solid red;
                border-radius: 0.5rem;
                font-size: 0.8rem;

                &:hover {
                    cursor: pointer;
                    background-color: red;
                    color: white;
                }
            }

            #subject,
            #issue-description {
                padding: 0.7rem;
                border: 1px solid #d0d5dd;
            }

            button {
                @include button-style();

                &#attach-file {
                    background-color: #eaecf0;
                    border: 1px solid#C3B5FD;

                    &:hover {
                        background-color: #a89bdb;
                    }

                    img {
                        padding-left: 0.5rem;
                    }
                }

                &#submit {
                    background-color: #fac515;
                    font-weight: 500;

                    &:hover {
                        background-color: #c49a10;
                    }
                }
            }
        }
    }
}

div.live-chat-container {
    padding: 2rem;

    button {
        @include button-style();
        background: #85e13a;

        &:hover {
            background-color: #5a9727;
        }
    }
}

.reply-input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    outline: none;
    border: 2px solid grey;
    border-image: linear-gradient(to right, #fac415 0%, #fac415 100%) 1;
    border-width: 0px 0px 2px 0px;
}

.tabs {
    cursor: pointer;
    border-bottom: 4px solid transparent;
    color: rgb(29, 41, 57);
    transition: all 0.5s;
}

.tabs:hover {
    color: rgba(99, 65, 2, 0.874);
}

.activeTab {
    background-color: rgba(231, 197, 157, 0.365) a(255, 217, 0, 0.365) (255, 217, 0);
    border-bottom: 4px solid orange;
    color: orange;
}

.navbar-x {
    position: absolute;
    right: 0px;
    background-color: white;
    box-shadow: #0006 -4px 4px 8px -4px;
    border-radius: 4px;
    display: none;
    width: 90px;
    z-index: 5;
}

.navbar-x-toggle {
    font-size: 1.4em;
    color: #ee930a;
    padding: 0px;
    margin-right: 10px;
    align-self: end;
    margin-top: 5px;
}

.nav-item {
    padding: 7px 14px;
    border-bottom: 1px solid #888;
    display: block;
}

.nav-link {
    color: #333;
    text-decoration: none;
    padding: 5px 10px;
}

.nav-item:hover {
    background-color: #e9ecef;
}

.menuBtn {
    transition: all 0.05s;
}

.menuBtn:focus {
    border: 1px solid transparent !important;
    transform: scale(0.6);
}
</style>
  