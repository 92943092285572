<template>
  <div
    style="width: 730px; margin: auto; margin-top: 41px"
    class="d-flex flex-column"
  >
    <div
      class="inputGroupContainer d-flex flex-column align-items-start position-relative"
    >
      <!-- High School ID -->
      <label style="font-weight: 600 !important" class="mb-1"
        >High School Id</label
      >
      <i
        style="
          position: absolute;
          bottom: 7px;
          right: 5px;
          font-size: 2rem;
          opacity: 0.5;
        "
        class="pi pi-lock"
      ></i>

      <input
        disabled
        class="inputTypeText"
        type="text"
        :value="highSchool.id"
        placeholder="Please Write High School Name"
      />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1"
        >High School Name</label
      >
      <input
        class="inputTypeText"
        type="text"
        :value="highSchool.name"
        placeholder="Please Write High School Name"
      />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!--High School Type -->
      <label style="font-weight: 600 !important" class="mb-1"
        >High School Type</label
      >
      <DropDown
        v-model="selectedHighSchoolType"
        :options="highSchoolTypes"
        placeholder="Please Select High School Type"
        class="w-100"
      />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- Registered Date -->
      <label style="font-weight: 600 !important" class="mb-1"
        >Registered Date</label
      >

      <div
        style="border-radius: 8px; overflow: hidden; width: 50%"
        class="position-relative"
      >
        <CalendarComponent
          disabled
          style="width: 100%"
          v-model="registeredDate"
        />
        <i
          style="
            background-color: rgb(253, 176, 34);
            position: absolute;
            height: 100%;
            right: 0;
            display: flex;
            align-items: center;
            font-size: 1.75rem;
            top: 0;
            bottom: 0;
            padding: 5px;
            color: black;
            opacity: 0.5;
          "
          class="pi pi-lock"
        ></i>
      </div>
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- Registered Email Address -->
      <label style="font-weight: 600 !important" class="mb-1"
        >Registered Email Address</label
      >
      <input
        class="inputTypeText"
        type="email"
        v-model="registeredEmail"
        placeholder="Please Write Email Address"
      />
    </div>
    <div class="d-flex justify-content-between">
      <div
        class="inputGroupContainer d-flex flex-column align-items-start me-3 w-100"
      >
        <!-- First Name -->
        <label style="font-weight: 600 !important" class="mb-1"
          >First Name</label
        >
        <input
          class="inputTypeText"
          type="email"
          v-model="registeredFirstName"
          placeholder="Please Write First Name"
        />
      </div>
      <div
        class="inputGroupContainer d-flex flex-column align-items-start w-100 ms-3"
      >
        <!-- Last Name -->
        <label style="font-weight: 600 !important" class="mb-1"
          >Last Name</label
        >
        <input
          class="inputTypeText"
          type="email"
          v-model="registeredLastName"
          placeholder="Please Write Last Name"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HighSchoolInformationTab",
  components: ["CalendarComponent"],
  props: ["highSchool"],
  data() {
    return {
      registeredDate: this.highSchool.registered_date,
      registeredEmail: "",
      registeredFirstName: this.highSchool.employee?.first_name,
      registeredLastName: this.highSchool.employee?.last_name,
      autoGeneratedId: this.highSchool.id,
      autoGeneratedPassword: "123123",
      selectedHighSchoolType: this.highSchool.type,
      highSchoolTypes: ["Public High School", "Private High School"],
    };
  },
  methods: {
    generateNumber(eventtype) {
      if (eventtype == "id") {
        this.autoGeneratedId = Math.floor(Math.random() * 1000);
      } else if (eventtype == "password") {
        this.autoGeneratedPassword = Math.floor(Math.random() * 10000000);
      }
    },
    setEmail() {
      console.log("Got Here");
      this.highSchool.employee
        ? (this.registeredEmail = this.highSchool.employee.email)
        : (this.registeredEmail = this.highSchool.email);
      console.log(this.registeredEmail);
    },
    setType() {
      if (this.highSchool.type.split("")[0] == "Private") {
        this.selectedHighSchoolType = this.highSchoolTypes[1];
      } else {
        this.selectedHighSchoolType = this.highSchoolTypes[0];
      }
    },
    setDetails() {
      (this.registeredDate = this.highSchool.registered_date),
        (this.registeredFirstName = this.highSchool.employee?.first_name),
        (this.registeredLastName = this.highSchool.employee?.last_name);
    },
  },
  mounted() {
    this.setEmail();
    this.setDetails();
    // console.log(this.highSchool.type);
  },
};
</script>

<style scoped>
.inputGroupContainer {
  margin-top: 32px;
}
.inputTypeText {
  width: 100%;
  padding: 10px 14px 10px 12px;
  border: 0.5px solid #000000;
  border-radius: 4px;
}
button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}
</style>
