<template>
  <div class="row">
    <div class="col-sm-6">
      <form @submit.prevent="makePayment()">
        <h2 style="font-size: 100%">Student Applications</h2>
        <div class="card">
          <div class="application-review py-3">
            <div style="width: 90%; margin: auto">
              <h6 class="font-weight-bold pt-2">Programme to promote</h6>
              <h6 class="font-weight-bold pt-2 mt-3">
                Choose a programme code
              </h6>
              <Dropdown
                style="border-radius: 10px"
                v-model="selectedReview"
                :options="AppliactionReview"
                optionLabel=""
                placeholder="Waiting  Review"
                class="w-100"
                :default="AppliactionReview[0]"
              />
            </div>
          </div>
        </div>

        <div class="card pt-3 mt-4">
          <div style="width: 90%; margin: auto">
            <h6 class="font-weight-bold pt-2">Audience</h6>
            <p class="text-dark pt-2" style="font-size: 13px !important">
              Who should see your ads
            </p>
            <div class="mt-3">
              <div class="row">
                <label class="col-2" for="location">Location</label>
                <Dropdown
                  v-model="selectedCountry"
                  editable
                  :options="countries"
                  optionLabel="name"
                  placeholder="Select a Location"
                  class="w-full md:w-14rem col-10"
                />
              </div>
              <div class="row my-4">
                <label for="location" class="col-2">City</label>
                <MultiSelect
                  v-model="selectedCities"
                  :options="cities"
                  optionLabel="name"
                  placeholder="Select Cities"
                  :maxSelectedLabels="3"
                  class="w-full md:w-20rem col-10"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card pt-3 mt-4 px-3">
          <div class="py-4 px-4">
            <h6 class="text-dark pt-2 font-weight-bold">Duration</h6>

            <div class="row gx-3 my-3">
              <div class="col">
                <div
                  class="border border-2 d-flex align-items-center justify-content-between px-3 py-1"
                  style="border-color: #191c1c"
                >
                  <div>
                    <p>Days</p>
                    <p>{{ 7 }}</p>
                  </div>
                  <div class="row">
                    <img
                      src="./../../assets/images/minus.png"
                      alt=""
                      class="col"
                    />
                    <img
                      src="./../../assets/images/plus.png"
                      alt=""
                      class="col"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="border border-2 d-flex align-items-center justify-content-between px-3 py-1"
                  style="border-color: #191c1c"
                >
                  <div>
                    <p>Days</p>
                    <p>Nov 21, 2023</p>
                  </div>
                  <div class="row">
                    <img
                      src="./../../assets/images/calendar.png"
                      alt=""
                      class="col"
                    />
                  </div>
                </div>
              </div>
            </div>
            <h6 class="font-weight-bold pt-1">Total Budget</h6>
            <p class="text pt-1" style="font-size: 13px !important">
              your payment will be processed n USD($)
            </p>
            <div>
              <h6 class="font-weight-bold pt-1">Daily Budget</h6>
              <p
                class="text-dark text-center pt-2"
                style="font-size: 13px !important"
              >
                Estimate 7k-9k reached per day
              </p>
              <div
                class="border border-1 border-warning d-block rounded text-center"
                style="
                  background-color: #fdedb6;
                  width: 5rem;
                  margin: 1rem auto;
                "
              >
                <p class="m-0">${{ estimatedPrice }}</p>
              </div>
              <Slider
                v-model="estimatedPrice"
                class="w-14rem"
                :home="0"
                :end="5000"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex my-4">
            <input type="checkbox" class="mr-2" name="terms" id="terms" />
            <label
              for="terms"
              class="ml-1 font-size-sm"
              style="font-size: 13px; margin-left: 8px"
            >
              By clicking make payment now, you agree to Paramount Students'
              Terms & conditions
            </label>
          </div>
          <div class="text-center my-4">
            <button class="w-100 rounded btn btn-warning">
              Proceed to make payment
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-6 mt-4">
      <div class="card shadow">
        <div class="application-review py-4">
          <div style="width: 90%; margin: auto">
            <h6 class="text-dark pt-2">Estimate daily Result</h6>

            <h6 class="card-title text-truncate px-3 py-2 row mb-0">
              <span class="text-secondary col-3 text-dark text-truncate p-0"
                >People Recievced:</span
              >
              <span class="col text-truncate">7k-10k</span>
            </h6>
            <hr style="border: 1px solid #f79009" />
            <h6 class="text-dark pt-2">Payment summary</h6>
            <h6 class="card-title text-truncate px-3 py-3 row mb-0">
              <span class="text-secondary col-6 text-dark text-truncate p-0"
                >Your Promotion will run for 7 days:</span
              >
              <span class="col text-truncate">7k-10k</span>
            </h6>
            <h6 class="card-title text-truncate px-3 py-2 row mb-0">
              <span class="text-secondary col-3 text-dark text-truncate p-0"
                >Total Budget:</span
              >
              <span class="col text-truncate">$10000</span>
            </h6>
            <h6 class="card-title text-truncate px-3 py-2 row mb-0">
              <span class="text-secondary col-3 text-dark text-truncate p-0"
                >Total amount:</span
              >
              <span class="col text-truncate">$10000</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
   <script>
import Dropdown from "primevue/dropdown";
// import PromotionAudience from "./PromotionAudience.vue";
import MultiSelect from "primevue/multiselect";
import Slider from "primevue/slider";
export default {
  // components: { Dropdown,  },
  components: { Dropdown, MultiSelect, Slider },
  data() {
    return {
      stuForm: false,
      selectedReview: null,
      AppliactionReview: ["Approved", "Rejected", "Got Admissions"],
      estimatedPrice: 0,
      selectedCities: null,
      selectedCountry: null,
      countries: [
        { name: "Australia", code: "AU" },
        { name: "Brazil", code: "BR" },
        { name: "China", code: "CN" },
        { name: "Egypt", code: "EG" },
        { name: "France", code: "FR" },
        { name: "Germany", code: "DE" },
        { name: "India", code: "IN" },
        { name: "Japan", code: "JP" },
        { name: "Spain", code: "ES" },
        { name: "United States", code: "US" },
      ],
      cities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],
      documents: [
        {
          id: 1,
          text: "High School Grade MarkSheet",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 2,
          text: "High School Certificates",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 3,
          text: "Formal Photo",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 4,
          text: "Passport font",
          size: "4.00mb",
          date: "25/05/2022",
        },
        {
          id: 5,
          text: "National ID Photo",
          size: "4.00mb",
          date: "25/05/2022",
        },
      ],
    };
  },
  methods: {
    makePayment() {
      this.$emit("makePayment", {amount: this.estimatedPrice});
    },
  },
};
</script>>
   
   <style scoped>
.container {
  margin-bottom: 60px;
}

.application-review div {
  margin-bottom: 10px;
}

.label-text {
  margin: 10px;
}
.profile-image {
  background-color: #fceca6;
  width: 40%;
  height: 60px;
  padding-top: 2px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-radius: 6px;
}
.profile-image > h6 {
  font-size: 90%;
}

.label-text {
  font-size: 15px;
}
.textarea {
  width: 95%;
  margin-left: 10px;
}
.btn-container {
  padding: 2px;
}

.card-title {
  font-size: 12px;
}
.tab-container {
  height: 107px;
  border-bottom: 1px solid rgb(235, 233, 233);
}
.view-btn {
  background-color: #eaecf0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .view-btn:hover {
     color: rgb(245, 245, 245) !important;
     box-shadow: inset 150px 0 0 0 #fac515 !important;
  } */

.view-btn img {
  width: 20px;
}

.save-next-btn {
  border-radius: 4px;
  width: 95%;
  padding: 0.6rem;
  border: none;
  margin-left: 8px;

  background-color: gray;
}

.nav-link {
  color: grey;
}
.active {
  color: rgb(12, 11, 11) !important;
  font-weight: 600;
  background-color: transparent !important;
  border-radius: 0px !important;
  border-bottom: 2px solid rgb(134, 132, 132) !important;
}
@media (max-width: 1024px) {
  .profile-image {
    width: 60%;
  }
  .profile-image > h6 {
    font-size: 13px;
  }
  .profile-image > p {
    font-size: 1px;
  }
}
@media (max-width: 664px) {
  .profile-image {
    width: 70%;
  }
  .profile-image > h6 {
    font-size: 12px;
  }
}
.nav-link > button {
  color: red;
}
.nav-link.active {
  background-color: #f9fafb;
  color: black;
}
.img-size {
  border-radius: 2px;
  background-color: gray;
  height: 80px;
  width: 70px;
}
</style>
  
  
  