<template>
  <div
    style="width: 730px; margin: auto; margin-top: 0px"
    class="d-flex flex-column"
  >
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">Country</label>
      <DropDown
        v-model="selectedCountry"
        :options="countries"
        optionLabel="name"
        placeholder="Select a Country"
        class="w-100"
        style=""
      >
        <template #option="slotProps">
          <div class="row">
            <div class="col-2">{{ slotProps.option.dial_code }}</div>
            <div class="col-3">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </DropDown>
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <div class="w-100 d-flex flex-column position-relative">
        <label for="phone" style="font-weight: 600 !important" class="mb-1"
          >Registerd Phone Number</label
        >
        <span
          style="font-weight: 600; position: absolute; bottom: 8px; left: 7px"
          >{{
            selectedCountry.dial_code ? selectedCountry.dial_code : "+00"
          }}</span
        >
        <InputMask
          v-model="maskValue"
          date="phone"
          mask="            (999) 999-9999"
          placeholder="            (999) 999-9999"
        />
      </div>
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">City</label>
      <input
        class="inputTypeText"
        type="text"
        v-model="selectedCity"
        placeholder="Please Write City"
      />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1"
        >High School Address</label
      >
      <textarea
        style="resize: none"
        col="15"
        v-model="selectedAddress"
        rows="4"
        class="inputTypeText"
        type="text"
        placeholder="Please Write High School Address"
      />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1"
        >Postal Code</label
      >
      <input
        class="inputTypeText"
        type="text"
        v-model="selectedPostalCode"
        placeholder="Please Write Postal Code"
      />
    </div>
  </div>
</template>

<script>
import { countries } from "@/data";

export default {
  name: "ContactDetailsTab",
  props: ["highSchool"],
  data() {
    return {
      countries: countries,
      selectedCountry: countries.find(
        (country) => country.name === this.highSchool.country
      ),
      selectedCity: this.highSchool.city,
      selectedAddress: this.highSchool.address,
      selectedPostalCode: this.highSchool.postal_code,
    };
  },
  computed: {
    maskValue() {
      return this.highSchool.phone ? this.highSchool.phone : "";
    },
  },
};
</script>

<style scoped>
.inputGroupContainer {
  margin-top: 32px;
}
.inputTypeText {
  width: 100%;
  padding: 10px 14px 10px 12px;
  border: 0.5px solid #000000;
  border-radius: 4px;
}
button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}
</style>
