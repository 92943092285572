<template>
  <div class="">
    <!-- Page Header -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">        
        <div class="position-relative me-4 mb-3">
          <img style="width: 90px; height: 90" alt="uni-img" :src="association.cover_photo_url!=''?association.cover_photo_url:'@/assets/images/highschools-list/highschoolImg.svg'"/>
          
          <span style=" width: 15px;
              height: 15px;
              border-radius: 100%;
              margin-right: 2px;
              margin-bottom: 1px;
              position: absolute;
              right: 5px;" :style="
              association.active == 1?
              'background-color:green'
              :association.active == 0 ?
              'background-color:rgba(249, 112, 102, 1)'
              :'background-color:red'"></span>
        </div>
        <div class="d-flex flex-column align-items-start">
          <h1>{{ association.name }}</h1>
          <p>
            Last Login: {{ lastTimeUpdated }} ,
            {{ lastDateUpdated }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center justify-content-center">
          <InputSwitch
            style=""
            @change="changeStatus($event)"
            v-model="isActiveHighSchool"
          />
          <p
            style="font-weight: 600; font-size: 12px !important"
            class="m-0 p-0 ms-1"
          >
            {{ association.active == 1 ? "Active" : "Inactive" }}
          </p>
        </div>
        <p>Date: 2022-05-2</p>
      </div>
    </div>
    <div class="ps-5 border-bottom w-100 d-flex align-items-center">
      <div @click="changeTab(1)" style="
          color: rgb(29, 41, 57);
          cursor: pointer;
          border-bottom: 4px solid transparent;
        " class="me-5 p-3" :style="currentTab === 1 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'">
        Association Information
      </div>
      <div @click="changeTab(2)" style=" cursor: pointer;border-bottom: 4px solid transparent;color: rgb(29, 41, 57);" :style="currentTab === 2 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'" class="p-3">
        Contact Details
      </div>
      <div @click="changeTab(3)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 3 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'" class="p-3">
        Commission
      </div>
      <div @click="changeTab(4)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 4 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'" class="p-3">
        Members
      </div>
      <div @click="changeTab(5)" style=" cursor: pointer; border-bottom: 4px solid transparent; color: rgb(29, 41, 57); " :style="currentTab === 5 && 'border-bottom: 4px solid ' + $globals.colorsHex.warning + '; font-weight:600'" class="p-3">
        General Settings
      </div>
    </div>
    <div style="margin-top: 41px" class="md:ml-10 md:w-[650px] ">
      <AssociationInformationTab @onUpdateInfo="onUpdateInfoFunc($event)" :highSchoolProp="association" v-if="currentTab == 1" />
      <ContactDetailsTab :countries="countries" @onUpdateContact="onUpdateContactFunc($event)" :contactProp="association" v-if="currentTab === 2" />
      <div v-if="currentTab === 3">
        <CommissionTab :type="false" :commission_targets="association?.commission_targets" :commission_type="association?.commission_type" :commission_value="association?.commission_value" @updateCommission="updateCommissionFunc($event)" />
      </div>
      <div v-if="currentTab === 5">
        <GeneralSettingsTab :highSchool="association" />
      </div>
      <div  v-if="currentTab !== 5 && currentTab !== 4 " class="d-flex align-items-center justify-content-between w-80 m-auto mt-5" >
  
        <!-- Footer Buttons -->
        <button class="border py-2 px-5">Cancel</button>
        <button  v-if="currentTab === 1" @click="changeTab(currentTab + 1)" class="bg-warning border py-2 px-5">Next</button>
        <button v-else @click="openConfirmModal" class="bg-warning border py-2 px-5"> Save Changes.....</button>
      </div>
    </div>
    <div v-show="currentTab === 4" class="px-4">
      <MemberTab :association="association" @updateLetter="updateLetterFunc($event)" />
    </div>

    <ConfirmModal
      v-if="isConfirmModalOpen"
      @closeConfirmModal="closeConfirmModal"
      @createProfile="createProfile"
      message="Are you sure you want to save changes?"
    />
  </div>
</template>

<script>
import AssociationInformationTab from "@/components/Association/AssociationInformationTab.vue";
import ContactDetailsTab from "@/components/Association/ContactDetailsTab.vue";
import GeneralSettingsTab from "@/components/Association/ViewHighSchoolDetails/GeneralSettingsTab.vue";
import ConfirmModal from "@/components/Association/ConfirmModal.vue";
import { useHsStore } from "@/stores/highSchools";
import { post } from '@/api/client';
import {showModal} from "@/plugins/modal";
import MemberTab from "@/components/Association/MemberTab.vue";
import CommissionTab from "@/components/UniversitiesList/CommissionTab.vue";
// import { highSchools } from "@/data";

export default {
  name: "HighSchoolDetail",  
  components: {
    GeneralSettingsTab,
    ContactDetailsTab,
    AssociationInformationTab,
    ConfirmModal,
    CommissionTab,
    MemberTab
    
  },
  props:{
    countries:Array,
    association:Object,
    index:Number,
    isDashboard:{
      type:Boolean,
      default:function(){
        return false
      }
    }
  },
  data() {
    return {
      isConfirmModalOpen: false,
      isActiveHighSchool: "",      
      currentTab: 1,
      lastTimeUpdated: "",
      lastDateUpdated: "",
      updateInfo:[],
      updateContact:[],
    };
  },
  methods: {
    changeStatus() {
      if (this.association.active == 1) {        
        this.updateHighSchool({id:this.association.id,active:-1} )
        this.$emit('onStatusChange',-1)        
      } else {       
        this.updateHighSchool({id:this.association.id,active:1})
        this.$emit('onStatusChange',1)
      } 
    },
    onUpdateInfoFunc(data){
      this.updateInfo = data
    },
    onUpdateContactFunc(data){
      this.updateContact = data
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
    setStatus() {      
      return this.association.active == 1;      
    },
    setHighSchool() {
      const store = useHsStore();
      return store.$state.highSchools.find(
        (association) => association.id == this.$route.params.highschoolname
      );
    },
    async updateHighSchool(obj){
      const res = await post('high_school/update',obj,'admin')                  
      if(res?.data){
        showModal({text:res.data});
      }
    },
    async publish() {        
      this.updateHighSchool({        
          ...this.updateInfo,
          ...this.updateContact,         
          commission_type: this.updateCommissions.commission_type,
          commission_value: this.updateCommissions.commission_value
          })          
    },
    createProfile() {
      if (this.publish()) {
        // window.alert("Profile Created");
        this.isConfirmModalOpen = false;
      } else {
        // window.alert("Profile Creation Error");
        this.isConfirmModalOpen = false;
      }
    },
    setUpdatedTime() {
      const updatedTime = this.association.updated_at.split("T");
      console.log(updatedTime[1]);
      this.lastTimeUpdated = updatedTime[1].split(".")[0];
      this.lastDateUpdated = updatedTime[0];
    },
  },
  beforeMount() {
    this.isActiveHighSchool = this.setStatus();
 /*    this.association = this.setHighSchool();
    this.setUpdatedTime(); */
    // console.log(this.association);
    // const store = useUniversityStore()
    // console.log(this.$route.params.universityname)
  },
};
</script>

<style scoped>
:deep(.p-inputswitch-checked .p-inputswitch .p-inputswitch-slider) {
  background-color: var(--teal-500) !important;
}
</style>
