<template lang="html">
  <div>
    <h3 class="mx-5 fw-bold">Dashboard</h3>
    <hr style="background: #f79009" />
      <div  class="px-3 w-100">
        <SkeletalLoader v-if="statsLoading" w="200px" h="100px" :col="5" ></SkeletalLoader>    
        <DashboardStat v-else :doc-file-count="docFileCount" />
      </div>
    

    <hr class="mt-3" />
    <div class="mx-3 p-3 student-app-heading">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="d-flex">
          <img class="px-2" :src="require('/src/assets/images/d-board/student-application-black.svg')
            " alt="" height="24" />
          Student Applications
        </h4>
        <button class="btn btn-large bg-yellow px-3 fw-500 ripple h-100"
          @click="() => this.$router.push('/high-school-application')">
          View All
        </button>
      </div>
    </div>
    <div class="student-app">
      <div class="table-responsive mx-3 " tabIndex="1">
        <div v-for="(student, index) in students?.data" :key="index">
          <div v-if="index < 4">
            <StudentApplication :student="student" :index="index" @onClick="selectStudent(student)" :isDashboard="true" />
          </div>
        </div>
      </div>
    </div>

    <div class="student-track p-3 mx-3">
      <div class="d-flex justify-content-between align-items-center mx-3">
        <h4 class="d-flex">
          <img class="px-2" :src="require('/src/assets/images/d-board/student-tracking-black.svg')
            " alt="" height="24" />
          Programs
        </h4>
        <button class="btn btn-large bg-yellow  fw-500 ripple h-100" @click="() => this.$router.push('/programs')">
          View All
        </button>
      </div>
    </div>
    <div class="mb-5 mx-auto position-relative">
      <div v-for="(program, index) in programs.data" :key="index">
        <div v-if="index < 4" class="mx-3 table-responsive">
          <Program :program="program" :index="index" :isDashboard="true" />
        </div>
      </div>
    </div>

    <div class="row p-3">
      <div class="col-lg-6 mt-3 mb-4 mr-4">
        <div class="shadow p-3 d-flex justify-content-between align-items-center bg-white">
          <h4 class="d-flex mb-0 align-items-center p-header">
            <img class="px-2" :src="require('/src/assets/images/d-board/inbox-black.svg')" alt="" height="24" />Chat
          </h4>
          <button class="btn btn-large bg-yellow px-3 fw-500 ripple h-100" @click="() => this.$router.push('/inbox')">
            View All
          </button>
        </div>
        <hr class="mt-5 grey" />
      </div>
      <div class="col-lg-6 mt-3 mb-4">
        <div class="shadow p-3 bg-white">
          <h4 class="p-header">
            <img class="px-2" :src="require('/src/assets/images/d-board/help-support-black.svg')
              " alt="" height="24" />Help Support
          </h4>
        </div>
        <div class="help-videos-container border-0">
          <div class="help-videos mb-3">
            <div v-for="num of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="num" class="border-0 mt-0 ms-0 ps-0 pt-0">
              <div class="help-video m">
                <img :src="require(`/src/assets/images/play_icon.png`)" alt="" />
              </div>
              <h3>Paramount Student's Sign In tutorial</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardStat from "@/components/DashboardStat.vue";
import SkeletalLoader from "@/components/Global/SkeletalLoader.vue";
import Program from "@/components/Programs.vue";
import { get } from "@/api/client";
import StudentApplication from "@/components/highschool/StudentApplication.vue";

import "swiper/css/pagination";
export default {
  name: "UserDashboard",
  components: { DashboardStat, Program, StudentApplication,SkeletalLoader},
  data() {
    return {      
      students: [],
      programs: [],
      studentApp: [],
      programDetails: [],
      admissionDetails: [],
      statsLoading:true,
      awardCount: {
        bachelorsDegree: 0,
        mastersDegree: 0,
        diplomaDegree: 0,
        doctorateDegree: 0,
      },
      docFileCount: [],
    };
  },
  computed: {
    applicationSummary() {
      return this.studentApplication.slice(0, 5);
    },
  },
  methods: {
    selectStudent(student) {
      localStorage.setItem("selected_student", JSON.stringify(student));
      this.$router.push("/high-school-application");
    },
    setAwardCount(obj) {
      // console.log(obj);
      if (obj.program_award == "Bs.C" || obj.program_award == "B.Sc") {
        const count = this.awardCount.bachelorsDegree;
        this.awardCount.bachelorsDegree = count + 1;
        console.log(this.awardCount.bachelorsDegree, "1");
      } else if (obj.program_award == "M.Sc") {
        let count = this.awardCount.mastersDegree;
        this.awardCount.mastersDegree = count + 1;
      } else if (obj.program_award == "Dip") {
        let count = this.awardCount.diplomaDegree;
        this.awardCount.diplomaDegree = count + 1;
      } else if (obj.program_award == "Ph.D") {
        let count = this.awardCount.doctorateDegree;
        this.awardCount.doctorateDegree = count + 1;
      }
    },
  },
  async created() {
    get("students/applications/", "admin").then((res)=>{
      this.students = res.responseBody;
    })

    get("programs", "admin", false).then((res)=>{
      this.statsLoading = false
      this.programs = res;
    });
    get("program_dashboard", "admin", false).then((res2)=>{
      res2 = res2.responseBody;
      this.docFileCount = [
        {
          id: 0,
          style: "border border-primary px-lg-3 p-2  rounded border-2",
          count: res2?.total,
          img: "arrow-2.svg",
          subTitle: "Total",
          title: "Program Types",
          class: "",
        },
      ];
  
      res2?.programs?.every((item, i) => {
        this.docFileCount.push({
          id: i,
          style: `border border-${this.$globals.colors[i]} px-lg-3 p-2  rounded border-2`,
          count: item?.total,
          img: "arrow-2.svg",
          subTitle: "Total",
          title: item.program_type,
          class: "",
        });
        return true;
      });

    });
  },
};
</script>

<style lang="scss" scoped>
$text-color: #1d2939;

.fs-14 {
  font-size: 14px !important;
}

@media screen and (max-width: 768px) {
  .w-md-18 {
    width: 170%;
  }
}

.p-02 {
  padding: 0.4em 0.4em !important;
}

.fs-6 {
  font-size: 0.8em !important;
}

.inboxImg {
  width: 39.92px;
  height: 40px;
}

.grey {
  color: #98a2b3;
}

.bg-white {
  background-color: #fff;
}

.student-app-heading {
  margin-top: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
  background-color: #fff;

  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0;
    line-height: 38px;
    color: $text-color;
  }
}

.student-app {
  .student-app-card {
    box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
    background-color: #fff;
  }
}

.student-track {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 16px rgba(16, 24, 40, 0.1);
  background-color: #fff;

  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 38px;
    color: $text-color;
    margin-bottom: 0;
  }
}

.p-header {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 38px;
  color: $text-color;
  margin-bottom: 0;
}

button {
  font-size: 0.8rem;
}
</style>
