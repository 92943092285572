import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useCreateUniversityProfile = defineStore({
  id: "createUniversityProfile",
  state: () => ({
    universityInfo: useLocalStorage("universityInfo", {}),
    uniContactDetails: useLocalStorage("uniContactDetails", {}),
  }),
  persist: true,
  actions: {
    setUniInfo(details) {
      this.universityInfo = details;
    },
    setUniContactDetails(details) {
      this.uniContactDetails = details;
    },
  },
});
