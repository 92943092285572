<template>
  <div class="d-flex justify-content-between my-4">
    <h6>City Distribution</h6>
    <div>
      <Calendar
        v-model="dateRange"
        selectionMode="range"
        placeholder="day/month/year - day/month/year"
        @change="getChart()"
        class="w-100"
      />
    </div>
  </div>
  <div style="height: 24rem">
    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      style="height: 100%"
    />
  </div>
</template>

<script>
import Chart from "primevue/chart";
import Calendar from "primevue/calendar";
export default {
  components: {
    Chart,
    Calendar,
  },
  data() {
    return {
      dateRange: null,
      chartData: {
        labels: [1, 2, 3, 4, 5, 6, 7],
        datasets: [
          {
            label: "Instabul",
            backgroundColor: "#56CA00",
            borderColor: "#56CA00",
            data: [142, 180, 120, 120, 185, 0, 120],
          },
          {
            label: "Ankara",
            backgroundColor: "#16B1FF",
            borderColor: "#16B1FF",
            data: [38, 128, 92, 70, 18, 76, 70],
          },
        ],
      },
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.2,
        plugins: {
          legend: {
            labels: {
              fontColor: "#000",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#000",
              font: {
                weight: 600,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: "#000",
            },
            grid: {
              color: "#aaa",
              drawBorder: false,
            },
          },
        },
      },
    };
  },
  watch: {
    dateRange(newval) {
      if (newval.includes(null)) {
        return;
      }
      this.getChart();
    },
  },

  methods: {
    getChart() {
      console.log(this.dateRange);
    },
  },
};
</script>