<template>
  <div class="grid grid-cols-2">   
    <div class="my-3 sm:col-span-2 md:pr-2 md:col-span-1 d-flex flex-column align-items-start">
      <!-- High School Name -->
      <div class="w-100 d-flex flex-column position-relative">
        <label for="phone" style="font-weight: 600 !important" class="mb-1" >Registered Phone Number *</label
        >
        <span style="font-weight: 600; position: absolute; bottom: 10px; left: 7px">+{{ selectedCountry?.phone_code ? selectedCountry.phone_code : "00"}}</span>
        <input type="tel" pattern="[0-9]+" @input="checkInput" v-model="contact.phone" placeholder="(999) 999-9999" style="padding-left: 60px" class="py-2 d-flex justify-content-center align-items-center"/>      
        <p v-if="missingFields?.includes('phone')" class="text-danger pt-2 mb-0">Required Field</p>
      </div>
    </div>
    <div class="my-3 sm:col-span-2 md:pl-2 md:col-span-1 d-flex flex-column align-items-start">
      <!-- High School Name -->
      <div class="w-100 d-flex flex-column position-relative">
        <label for="site_url" style="font-weight: 600 !important" class="mb-1" >Association Website URL *</label
        >        
        <input type="text" v-model="contact.site_url" placeholder="https://" style="padding-left: 60px" class="py-2 d-flex justify-content-center align-items-center"/>      
        <p v-if="missingFields?.includes('site_url')" class="text-danger pt-2 mb-0">Required Field</p>
      </div> 
    </div>
    <div class="my-3 sm:col-span-2 md:pr-2 md:col-span-1 d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">Country</label>
      <DropDown v-model="selectedCountry" :options="countries" @change="loadStates()" optionLabel="name" placeholder="Select a Country" class="w-100" style="">
        <template #option="slotProps">
          <div class="row">
            <div class="col-2">{{ slotProps.option.phone_code }}</div>
            <div class="col-3">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </DropDown>
      <p v-if="missingFields?.includes('country')" class="text-danger pt-2 mb-0">Required Field</p>
    </div>
    <div class="my-3 sm:col-span-2 md:pl-2 md:col-span-1 d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">City</label>
      <DropDown :options="states" optionLabel="name" optionValue="id" placeholder="" filter :loading="stateIsLoading" v-model="contact.state_id" class="w-100 required"/> 
    </div>
    <div class="my-3 sm:col-span-2 md:pr-2 md:col-span-1 d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1"
        >Association Address</label
      >
      <input style="resize: none" col="15" rows="4" class="inputTypeText" type="text" v-model="contact.address" placeholder="Please Write High School Address" />
      <p v-if="missingFields?.includes('address')" class="text-danger pt-2 mb-0">Required Field</p>
    </div>
    <div class="my-3 sm:col-span-2 md:pl-2 md:col-span-1 d-flex flex-column align-items-start">
      <!-- High School Name -->
      <label style="font-weight: 600 !important" class="mb-1">Postal Code</label > 
      <input class="inputTypeText" type="text" v-model="contact.postal_code" placeholder="Please Write Postal Code"/>
      <p v-if="missingFields.includes('postal_code')" class="text-danger pt-2 mb-0">Required Field</p>
    </div>
  </div>
</template>

<script>
//import { useCreateHsProfileStore } from "../../stores/createHighSchoolProfile";
import { get } from "@/api/client";

export default { 
  name: "ContactDetailsTab",
  props: ["isSubmitBtnClicked", "contactProp","countries"],
  data() {
    return {      
      selectedCountry: "",
      contact:
      {
        postal_code: "",
        address: "",
        state_id: "",
        country_id: "",
        phone: ""
      },
      states:[]
    };
  },
  computed: {
    // phone() {
    //   return this.selectedCountry.dial_code;
    // },
  },
  watch: {
    contact: {
      deep: true, // Watch for nested changes within each block
      handler(newData) {        
        this.$emit('onUpdateContact', newData);
        // Handle changes to the blocks array        
      },
     
    },
  },
  methods: {
    setNumber(e) {
      this.phone = "";
      this.contact.phone = this.selectedCountry.phone_code + e.currentTarget.value;      
    },
    async loadStates(callback = function(){}){
      this.stateIsLoading = true
      this.contact.country_id = this.selectedCountry.id
      const response = await get(`state/${this.selectedCountry.id}`, 'api');               
      this.states = response;
      callback()
      this.stateIsLoading = false
    },
    checkInput(e) {
      const previousValue = e.currentTarget.value;
      // console.log(isNaN(Number(previousValue)));
      if (isNaN(Number(previousValue))) {
        previousValue.length == 0
          ? (e.currentTarget.value = "")
          : (e.currentTarget.value = previousValue.slice(
              0,
              previousValue.length - 1
            ));
        return false;
      }
      return true;
    },  
    setPreviousDetails() {
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));
      if (contactDetails) {
        this.selectedCountry = contactDetails.country;
        this.phone = contactDetails.city;
        this.email = contactDetails.email;
        this.address = contactDetails.address;
        this.postal_code = contactDetails.postal_code;
      }
    },
  },
  mounted() {
    this.setPreviousDetails();
  },  
  async created(){
      
    if(this.contactProp){
      //this.contact.phone = this.contactProp.phone
      this.contact.postal_code = this.contactProp.postal_code
      this.contact.address = this.contactProp.address
      this.contact.country_id = Number(this.contactProp.country_id)
      let $this = this;
      this.selectedCountry = this.countries.filter((item)=>{
        return item.id === this.contact.country_id
      })[0]??{}
      this.loadStates(function(){        
        $this.contact.state_id = Number($this.contactProp.state_id);        
      })
            ///alert(Number(this.contactProp.employee?.phone))
      this.contact.phone = Number(this.contactProp.employee?.phone)
    }
  }
};
</script>

<style scoped>
.my-3 sm:col-span-2 md:pl-2 md:col-span-1 {
  margin-top: 32px;
}
.inputTypeText {
  width: 100%;
  padding: 10px 14px 10px 12px;
  border: 0.5px solid #000000;
  border-radius: 4px;
}
button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}
</style>
