<template>
  <div style="height: 24rem">
    <Chart
      type="line"
      :data="chartData"
      :options="chartOptions"
      style="height: 100%"
    />
  </div>
</template>

<script>
import Chart from "primevue/chart";
export default {
  components: {
    Chart,
  },
  data() {
    return {
      chartData: {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        datasets: [
          {
            label: "reach",
            fill: false,
            tension: 0.4,
            borderColor: "#16B1FF",
            data: [340, 128, 40, 40, 18, 530, 600, 400, 230, 600],
          },
        ],
      },
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.2,
        plugins: {
          legend: {
            labels: {
              fontColor: "#000",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#000",
              font: {
                weight: 600,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
              color: "#aaa",
            },
          },
          y: {
            ticks: {
              color: "#000",
            },
            grid: {
              color: "#aaa",
              drawBorder: false,
            },
          },
        },
      },
    };
  },
};
</script>