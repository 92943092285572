<template>
  <div style="width: 730px; margin: auto; margin-top: 20px" class="d-flex flex-column">
    <div v-if="university" class="inputGroupContainer mb-3 d-flex flex-column align-items-start position-relative">
      <label style="font-weight: 600 !important" class="mb-1">University Id</label>
      <i style="
          position: absolute;
          bottom: 7px;
          right: 5px;
          font-size: 2rem;
          opacity: 0.5;
        " class="pi pi-lock"></i>
      <input disabled class="inputTypeText" type="text" :value="university.unique_id"
        placeholder="Please Write University Name" />
    </div>
      <div style="margin: auto !important; width: 100%" class="w-100">
        <!-- Upload Cover Photo -->
        <label class="fw-bold mb-1">Upload Cover Photo <span class="text-danger"> * </span></label>
        <div style="
            height: 128px !important;
            border: 1px dashed #4d5056;

            border-radius: 12px;
          " class="px-3 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div>
              <img class="me-3" style="width: 80px; border-radius: 12px; max-height: 80px;"  :src="uniData.cover_photo_url != ''
                ? uniData.cover_photo_url
                : require('@/assets/images/universities-list/upload-svg.svg')
                " alt="" id="upload-img" />
            </div>
            <div class="d-flex m-auto flex-column align-items-start">
              <p style="color: rgb(77, 80, 86) !important">
                960px by 960px . Max 5MB
              </p>
              <p style="margin: 0; color: rgb(77, 80, 86) !important">
                High-resolution images (png, jpg)
              </p>
            </div>
          </div>
          <div>
            <form className="">
              <label className="custom-file-upload" id="file">
                <input accept=".jpg, .jpeg, .png" @change="showFile($event, 'cover')" type="file" ref="file-input" />
                Browse
              </label>
            </form>
            <button class="border py-2 px-5" @click="$refs['file-input'].click()">Browse</button>
          </div>
        </div>
      </div>
      <div style=" width: 100%" class="w-100 mt-4">
        <!-- Upload Cover Photo -->
        <label class="fw-bold mb-1">Upload Logo <span class="text-danger"> * </span></label>
        <div style="
            height: 128px !important;
            border: 1px dashed #4d5056;

            border-radius: 12px;
          " class="px-3 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div>
              <img class="me-3" style="width: 80px; border-radius: 12px" :src="uniData?.logo != ''
                ? uniData?.logo
                : require('@/assets/images/universities-list/upload-svg.svg')
                " alt="" id="upload-img" />
            </div>
            <div class="d-flex m-auto flex-column align-items-start">
              <p style="color: rgb(77, 80, 86) !important">
              200px by 200px . Max 200kb
              </p>
              <p style="margin: 0; color: rgb(77, 80, 86) !important">
                High-resolution images (png, jpg)
              </p>
            </div>
          </div>
          <div>
            <form className="">
              <label className="custom-file-upload" id="file">
                <input accept=".jpg, .jpeg, .png" @change="showFile($event, 'logo')" type="file" ref="file-input2" />
                Browse
              </label>
            </form>
            <button class="border py-2 px-5" @click="$refs['file-input2'].click()">Browse</button>
          </div>
        </div>
      </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- University Name -->
      <label style="font-weight: 600 !important" class="mb-1">University Name</label>
      <input class="inputTypeText" type="text" v-model="uniData.name" placeholder="Please Write University Name" />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!--University Type -->
      <label style="font-weight: 600 !important" class="mb-1">University Type</label>
      <DropDown v-model="uniData.type" :options="universityTypes" placeholder="Please Select University Type"
        class="w-100" />
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- Registered Date -->
      <label style="font-weight: 600 !important" class="mb-1">Registered Date</label>

      <div style="border-radius: 8px; overflow: hidden; width: 50%" class="position-relative">
        <CalendarComponent style="width: 100%" v-model="uniData.registered_date" />
        <i style="
            background-color: rgb(253, 176, 34);
            position: absolute;
            height: 100%;
            right: 0;
            display: flex;
            align-items: center;
            font-size: 1.75rem;
            top: 0;
            bottom: 0;
            padding: 5px;
            color: black;
          " class="pi pi-calendar"></i>
      </div>
    </div>
    <div class="inputGroupContainer d-flex flex-column align-items-start">
      <!-- Registered Email Address -->
      <label style="font-weight: 600 !important" class="mb-1">Registered Email Address</label>
      <input class="inputTypeText" type="email" v-model="uniData.email" @focusout="validateEmail"
        placeholder="Please Enter Your Email Address" />
      <p v-if="showWarning" class="text-danger pt-2 mb-0">Please Enter A Valid Email</p>
      <p v-if="showIsValid" class="text-success pt-2 mb-0">Valid Email</p>
    </div>
    <div class="d-flex justify-content-between">
      <div class="inputGroupContainer d-flex flex-column align-items-start me-3 w-100">
        <!-- First Name -->
        <label style="font-weight: 600 !important" class="mb-1">First Name</label>
        <input class="inputTypeText" type="text" v-model="uniData.first_name" placeholder="Please Write First Name" />
      </div>
      <div class="inputGroupContainer d-flex flex-column align-items-start w-100 ms-3">
        <!-- Last Name -->
        <label style="font-weight: 600 !important" class="mb-1">Last Name</label>
        <input class="inputTypeText" type="text" v-model="uniData.last_name" placeholder="Please Write Last Name" />
      </div>
    </div>
  </div>
</template>

<script>
import { postFormData } from "@/api/client";
import { showModal } from '@/plugins/modal';

export default {
  name: "UniversityInformationTab",
  props: ["university"],
  components: ["CalendarComponent"],
  data() {
    return {
      registeredDate: "01/01/2023",
      autoGeneratedId: "12345",
      autoGeneratedPassword: "123123",
      selectedUniversityType: "",
      universityTypes: ["Public", "Private"],
      email: "",
      coverPhoto: "",
      universityName: "",
      firstName: "",
      lastName: "",
      showWarning: false,
      showIsValid: false,
      isEmailValid: false,

      uniData: {
        unique_id: "",
        name: "",
        type: "",
        registered_date: "",
        email: "",
        first_name: "",
        last_name: "",
        cover_photo_url: "",
        password: "",
        logo:"",
      },
    };
  },
  watch: {
    uniData: {
      deep: true, // Watch for nested changes within each block
      handler(newData) {
        this.$emit("onUpdateInfo", newData);
        this.$emit("onchange", newData);
        // Handle changes to the blocks array
      },
    },
  },
  methods: {
    generateNumber(eventType) {
      if (eventType == "id") {
        this.uniData.unique_id = Math.floor(Math.random() * 7000000);
      } else if (eventType == "password") {
        this.uniData.password = Math.floor(Math.random() * 10000000);
      }
    },
    openFileInput(id) {
      document.getElementById(id).click();
    },
    readFile(e) {
      var files;
      if (e.target.files) {
        files = e.target.files;
      } else {
        files = e.dataTransfer.files;
      }
      if (files.length == 0) {
        showModal({ text: "What you dropped is not a file." });
        return;
      }
      var file = files[0];
      document.getElementById(
        "file"
      ).textContent = `${file.name}| ${file.type}`;
      const reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById("file-input").value = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    getTheFile(e) {
      e.preventDefault();
      this.readFile(e);
    },
    dragOver(e) {
      e.currentTarget.classList.add("blue-bg");
      e.currentTarget.style.color = "white";
      e.stopPropagation();
      e.preventDefault();
    },
    dragLeave(e) {
      e.currentTarget.classList.remove("blue-bg");
    },
    async showFile(e, type) {
      const file = e.currentTarget.files;
      if (file) {
          const formData = new FormData();
          formData.append("file", file[0]);
          formData.append("folder_name", "universityProfile"); // Corrected "universtyProfile" to "universityProfile"
          const res = await postFormData("file_upload", formData, "admin");

          if (type === 'cover') {
              this.uniData.cover_photo_url = res;
          } else {
              this.uniData.logo = res;
          }

          // Clear the input field by setting its value to an empty string          
      }
  },
    validateEmail(e) {
      var email = e.currentTarget.value;
      var regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.trim() == "" || !regex.test(email)) {
        this.showWarning = true;
        setTimeout(() => {
          this.showWarning = false;
        }, 1500);
      } else {
        this.showIsValid = true;
        this.isEmailValid = true;
        this.$emit("validate-email", this.isEmailValid);
        setTimeout(() => {
          this.showIsValid = false;
        }, 1500);
      }
    },
    setPreviousDetails() {
      const uniInfo = JSON.parse(localStorage.getItem("universityInfo"));
      if (uniInfo) {
        this.uniData = uniInfo;
      }
    },
  },
  created() {
    if (this.university) {
      let uniData_obj = { ...this.university, ...this.university.employee };
      Object.keys(this.uniData).forEach((key) => {
        this.uniData[key] = uniData_obj[key];
      });
      this.uniData.id = uniData_obj.id;
    }
  },
};
</script>

<style scoped>
.inputGroupContainer {
  margin-top: 32px;
}

.inputTypeText {
  width: 100%;
  padding: 10px 14px 10px 12px;
  border: 0.5px solid #000000;
  border-radius: 4px;
}

button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: none !important;
  text-decoration: underline;
  color: #0017ff;
  cursor: pointer;
}
</style>
