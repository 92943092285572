<template>
  <button @click="showList()" v-show="showViewUniversity" class="btn btn-secondary">
    Show List
  </button>
  <div v-show="!showViewUniversity">
    <div class="d-flex align-items-center justify-content-between">
      <!-- Page Header -->

      <h1 style="font-size: 36px">University Profile List</h1>
      <router-link style="color: unset" to="/universities-list/create-profile"
        class="border p-2 px-4 bg-warning d-flex align-items-center justify-content-between">
        <img src="@/assets/images/universities-list/create-university-profile.svg" alt="" class="me-3" />
        Create University Profile
      </router-link>
    </div>
    <hr />
    <div class="total-information-boxes d-flex align-items-center justify-content-between">
      <!-- Total Information Boxes -->
      <div class="total-information-box">
        <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
        <h2>{{ universityCounter?.total }}</h2>
        <p>Total Universities</p>
      </div>
      <div class="total-information-box">
        <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
        <h2>{{ universityCounter?.total_active }}</h2>
        <p>Active Universities</p>
      </div>
      <div class="total-information-box">
        <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
        <h2>{{ universityCounter?.total_inactive }}</h2>
        <p>Inactive Universities</p>
      </div>
      <div class="total-information-box">
        <img alt="" src="@/assets/images/universities-list/new-tab-icon.svg" />
        <h2>{{ universityCounter?.total_pending }}</h2>
        <p>Pending Universities</p>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-content-between">
      <FilterOptions :countries="countries" @onFilter="searchFilter($event)" v-if="isShowFilterOptions" />
      <div class="ms-auto">
        <!-- Filter Button -->
        <button @click="toggleFilterOptions" style="background-color: rgb(231, 230, 232)"
          class="border me-4 p-2 px-4 d-flex align-items-center justify-content-between"
          :class="isShowFilterOptions && 'bg-warning'">
          <img class="me-2" alt="" src="@/assets/images/universities-list/filter-icon.svg" />
          Filter
        </button>
      </div>
    </div>
    <hr />
    <div style="overflow:auto">
      <!-- University List Group -->

      <ul style="min-width:1200px;">
        <li class="universityListItem d-flex align-items-center justify-content-between p-0"
          v-for="university in universities?.data" :key="university.id">
          <div style="width: 95%" class="row p-0 m-0 d-flex align-items-center justify-content-center">
            <div style="width: fit-content; height: fit-content" class="col-1 m-auto position-relative">
              <span style="
                  width: 15px;
                  height: 15px;
                  border-radius: 100%;
                  margin-right: 2px;
                  margin-bottom: 1px;
                  position: absolute;
                  right: 5px;
                " :style="(university.status === 'Active' &&
                    'background-color:green') ||
                  (university.status === 'Pending' &&
                    'background-color:rgba(249, 112, 102, 1)') ||
                  (university.status === 'Inactive' && 'background-color:red')
                  "></span>
              <img style="border-radius: 100%; width: 50px !important; height: 50px;" alt="uni-img"
                :src="university.cover_photo_url" />
            </div>
            <div class="col-2 d-flex flex-column align-items-start">
              <span class="listItemTitle">University ID</span>
              <span>{{
                university.employee
                ? university.employee.unique_id
                : university.id
              }}</span>
            </div>
            <div class="col-3 d-flex flex-column align-items-start">
              <span class="listItemTitle">University Name</span>
              <span>{{ university.name }}</span>
            </div>
            <div class="col-2 d-flex flex-column align-items-start">
              <span class="listItemTitle">University Type</span>
              <span>{{ university.type }}</span>
            </div>
            <div class="col-1 d-flex flex-column align-items-start">
              <span class="listItemTitle">Country</span>
              <span>{{ university.country }}</span>
            </div>
            <div class="col-1 d-flex flex-column align-items-start">
              <span class="listItemTitle">Status</span>
              <span class="d-flex align-items-center">
                <span style="
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    margin-right: 2px;
                    margin-bottom: 1px;
                  " :style="(university.status === 'Active' &&
                      'background-color:green') ||
                    (university.status === 'Pending' &&
                      'background-color:rgba(249, 112, 102, 1)') ||
                    (university.status === 'Inactive' && 'background-color:red')
                    "></span>
                {{ university.active == 1 ? "active" : "inactive" }}</span>
            </div>
            <div class="col-1 m-auto d-flex">
              <button @click="selectUniversity(university)" style="font-size: 14px"
                class="border me-0 p-2 px-4 d-flex align-items-center justify-content-between">
                <img class="pe-2" alt="" src="@/assets/images/d-board/studentCourses.svg" />
                View
              </button>
              <button :class="{'bg-success':university.agreement_letters[0]?.url||false}"  title="view agreement letter" @click="viewLetter(university)" style="font-size: 14px"
                class="border ms-2 p-2 d-flex align-items-center justify-content-between">
                <img style="min-width:25px" v-if="!university.agreement_letters[0]?.url||false" alt="" src="@/assets/images/d-board/studentCourses.svg" /> 
                <img style="min-width:25px" v-else alt="" src="@/assets/images/d-board/studentCoursesWhite.svg" /> 
              </button>
            </div>
          </div>
        </li>
      </ul>
      <PaginationData :paginationData="universities" @onFetch="onFetchFunc($event)" />
    </div>

    <div v-if="showModal">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-custom">
        <div class="modal-header p-2">
          
          <button class="modal-close" @click="closeModal">&times;</button>
        </div>
        <div class="modal-body p-0">
          <div v-if="letters.length>0" style="height:inherit">
            <div style="height:inherit" v-for="(letter,i) in letters" :key="i">
              <button title="Resend agreement letter" @click="sendLetter(letter.id)" style="font-size: 14px"
                  class="border ms-2 p-2 btn btn-sm btn-danger  d-flex align-items-center justify-content-between">
                  Request change
              </button>
            <embed  :src="letter?.url"  style="width: 100%; height: 100%;" :class="i>0?'mb-3':''">
            </div>
          </div>
          <div v-else style="font-size:2em; font-weight:bold;color:#aaa" class="pt-5 text-center">No file found</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showViewUniversity">
    <UniversityDetail :countries="countries" :university="selectedUniversity" @back="back($event)" />
  </div> 
</template>

<script>
import UniversityDetail from "@/components/UniversitiesList/UniversityDetail.vue";
import FilterOptions from "@/components/UniversitiesList/FilterOptions.vue";
import PaginationData from "@/components/Global/Pagination";
import { get, post } from "@/api/client";
import { showModal, showToast } from "@/plugins/modal";
import { useUniversityStore } from "../../stores/universities";
import { useHsStore } from "@/stores/highSchools";
/* import getUniversities from "../../utilities/mixins/getUniversities"; */
// import { async } from 'vue-phone-number-input/dist/vue-phone-number-input.common';

export default {
  name: "UniversitiesList",
  // components: {  },
  data() {
    return {
      showModal: false,
      letterUrl: null,
      letters:[],
      university:{},
      selectedUniversity: {},
      showViewUniversity: false,
      universities: [],
      isShowFilterOptions: false,
      post: null,
      error: null,
      universityCounter: {},
      countries: [],

    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.showModal = false;  
      this.letterUrl = null   
    },
    async onFetchFunc(data) {
      this.universities = data;
    },
    viewLetter(university){
      this.university = university
      this.letters = university.agreement_letters
      this.showModal = true
    },
    async sendLetter(university) {
      showModal({
        title: 'Are you sure you want to re-send Letter',
        text: "as this will allow them to re-sign their agreement letter",
        confirmText: "Send",
        cancelText: "Cancel",
      }).then(async (result) => {
        window.modalOpened = false;
        if (result) {
          let res = await post('send_agreement_letter', { platform: 'university', id: university.id }, 'admin', false) 
          if (res?.message) {
            showToast('sent')
          }
        }
      });
    },
    showList() {
      (this.showViewUniversity = false), this.loadData();
    },
    async searchFilter(search) {
      const res = await get(`get_university${search}`, "admin");
      this.universities = res.message;
    },
    async loadData() {
      const res = await get("get_university/", "admin");
      this.universities = res.message;
      this.setUniversities(this.universities);
      const res2 = await get("university_dashboard", "admin", false);
      this.universityCounter = res2;
    },
    selectUniversity(university) {
      this.selectedUniversity = university;
      this.showViewUniversity = true;
    },
    back(value) {
      this.showViewUniversity = value;
    },
    setUniversities(students) {
      const store = useUniversityStore();
      store.$state.universities = students;
    },
    toggleFilterOptions() {
      this.isShowFilterOptions = !this.isShowFilterOptions;
    },
    getUniversitiesList(data) {
      if (!data && data !== undefined) {
        showModal({ text: "There was an error fetching the data!" });
      } else {
        this.setUniversities(data);
      }
    },
    setUniversityStore(data) {
      const store = useUniversityStore();
      store.setUniversities(data);
    },
  },
  beforeMount() {
    this.getUniversitiesList();
  },
  mounted() {
    // this.fetchData();
  },
  async created() {
    const response = await get("countries", 'api');
    const store = useHsStore()
    store.countries = response
    this.countries = response;
    this.loadData();
    /* this.docFileCount.map(function(item){
           item.count = res2.responseBody[item.title.toLowerCase().replaceAll(' ','_')]??0
      }) */
  },
  components: { FilterOptions, UniversityDetail, PaginationData },
};
</script>

<style scoped>
.total-information-box {
  width: 245px;
  height: 152px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  padding-left: 1rem;
}

.total-information-box h2 {
  font-size: 60px;
  margin-bottom: 0;
}

.total-information-box img {
  position: absolute;
  right: 5px;
  top: 5px;
}

.total-information-box:nth-child(1) {
  background: linear-gradient(180deg,
      rgba(115, 117, 253, 0.2) 0%,
      rgba(115, 117, 253, 0) 100%);
  border: 2px solid rgba(115, 117, 253, 1);
}

.total-information-box:nth-child(2) {
  background: linear-gradient(180deg,
      rgba(50, 213, 131, 0.2) 0%,
      rgba(50, 213, 131, 0) 100%);
  border: 2px solid rgba(50, 213, 131, 1);
}

.total-information-box:nth-child(3) {
  background: linear-gradient(180deg,
      rgba(253, 176, 34, 0.2) 0%,
      rgba(253, 176, 34, 0) 100%);
  border: 2px solid rgba(253, 176, 34, 1);
}

.total-information-box:nth-child(4) {
  background: linear-gradient(180deg,
      rgba(249, 112, 102, 0.2) 0%,
      rgba(249, 112, 102, 0) 100%);
  border: 2px solid rgba(249, 112, 102, 1);
}

.universityListItem {
  font-size: 14px;
  box-shadow: 0px 4px 20px 0px rgba(16, 24, 40, 0.16);
  border-radius: 8px;
  height: 100px;
  margin: 1rem 0.5rem;
}

.listItemTitle {
  font-weight: 600;
  color: rgb(102, 112, 133);
  margin-bottom: 12px;
}

button:hover:not(.p-calendar button) {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}

</style>
