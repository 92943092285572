<template>
  <div class="container h-auto px-0 py-3">
    <div class="" style="background-color: white !important">
      <div class="">
        <StudentProfile :id="id" />
      </div>
    </div>
  </div>
</template>

<script>
// import StudentContact from '@/components/StudentContact.vue';
// import StudentEducation from '@/components/StudentEducation.vue';
import StudentProfile from "./StudentProfile.vue";
// import StudentDocument from './StudentDocument.vue';

export default {
  name: "StudentBio",
  props: ["id"],
  components: { StudentProfile },
  data() {
    return {
      stuForm: false,
      selectedReview: null,
      AppliactionReview: ["No Apply yet", "Applying", "Got Admissions"],
    };
  },
  mounted() {
    console.log(this.id);
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 60px;
}
</style>
