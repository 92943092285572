<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-show="!showViewProgram" class="row w-90 mx-auto">
    <div class="px-4 py-3">
      <div class="d-flex justify-content-between">
        <div>
          <h3 class="mx-5 fw-bold mb-0 mt-1">Programs</h3>
        </div>
        <div class="mx-5">
          <button
            @click="createProgram"
            class="backgroundImage bg-success text-white"
          >           
            Create Program
          </button>
        </div>
      </div>
      <hr style="border: 1px solid #f79009" />
      <DashboardCard :page="true" :doc-file-count="docFileCount" />

      <hr style="border: 1px solid #f79009" />
      <div class="col-12 d-flex justify-content-end">
        <button
          @click="showForm(!programForm)"
          :class="{ active: programForm }"
          class="filter-btn btn text-dark px-5 py-2 d-flex"
        >
          <img :src="require('/src/assets/images/filter.svg')" />
          Filter
        </button>
      </div>
    </div>
    <div v-if="programForm" class="px-4">      
        <div class="row mb-3 mb-3">
          <div class="col-md-3">
            <label for="programCode">Program Code</label>
            <div class="input-group d-flex align-items-center trackform-input">
              <input
                v-model="programCode"
                type="text"
                class="form-control"
                id="programCode"
                name="programCode"
              />
              <i
                v-if="programCode.length > 0"
                class="pi pi-times form-btna"
                type="button"
                @click="() => (programCode = '')"
              ></i>
            </div>
          </div>
          <div class="col-md-3">
            <label for="programAward">Program Award</label>
            <Dropdown
              v-model="selectedAward"
              id="inputStatus"
              :options="programAward"
              optionLabel=""
              placeholder=""
              class="w-100"
              :default="programAward[0]"
            />
          </div>
          <div class="col-md-3">
            <label for="programType">Program Type</label>
            <Dropdown
              v-model="selectedType"
              id="inputStatus"
              :options="programType"
              optionLabel=""
              placeholder=""
              class="w-100"
              :default="programType[0]"
            />
          </div>
          <div class="col-md-3 d-flex align-self-end flex-row-reverse">
            <div>
              <div>
                <button @click="ClearForm()" class="btn btn-disabled py-2 px-4">
                  Clear All
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3 d-flex justify-content-between">        
          <div class="col-md-3">
            <label for="inputStatus">Status</label>
            <Dropdown
              v-model="selectedStatus"
              id="inputStatus"
              :options="programStatus"
              optionLabel=""
              placeholder=""
              class="w-100"
              :default="programStatus[0]"
            />
          </div>
          <div class="col-md-3 d-flex align-self-end flex-row-reverse p-0">
            <div>
              <button
                @click="search"                
                class="btn search-btn btn-disabled">Search</button>
            </div>
          </div>
          <div class="col-md-auto d-flex align-self-end flex-row-reverse">
            <div></div>
          </div>
        </div>      
    </div>

    <hr style="border: 1px solid #f79009" />
    <div class="student-app px-4 mb-5 mx-auto position-relative">
      <div v-for="(program, index) in programs.data" :key="index">
        <Program :program="program" :index="index" @onClick="selecteProgram($event)" />
      </div>
      <PaginationData :paginationData="programs" @onFetch="onFetchFuc($event)" />
    </div>
  </div>
  <div v-if="showViewProgram">
    <ViewProgramme :program="selectedProgram" @back="switchPage($value)" />
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Program from "../../components/Programs.vue";
import ViewProgramme from "@/components/Programme/ViewProgramme.vue";
import DashboardCard from "@/components/DashboardStat.vue";
import { useProgrammeStore } from "@/stores/programme";
import PaginationData from '@/components/Pagination.vue';
import {get} from '@/api/client';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Programs",
  components: { Dropdown, Program, DashboardCard,PaginationData,ViewProgramme },
  data() {
    return {
      programForm: false,
      programStatus: ["Publish", "Not Published", "Draft"],
      programType: ["Bachelor", "Master", "Doctorate"],
      programAward: ["B.Sc", "M.Tech", "PHD", "BE", "None"],      
      selectedType: null,
      selectedStatus: null,
      selectedAward: null,
      programCode: "",
      programName: "",
      Program: [],
      programs:[],
      awardCount: {},
      totalPrograms: 0,
      docFileCount: [],
      showViewProgram:false,
      selectedProgram:{}
    };
  },
  methods: {
    selecteProgram(index){      
      this.selectedProgram = this.programs.data[index]
      this.showViewProgram = true
    },
    switchPage(value){
      this.showViewProgram = value
    },
    showForm(form) {
      this.programForm = form;
    },

    ClearForm() {
      this.programCode = "";
      this.programName = "";
      this.selectedStatus = null;
      this.selectedType = null;
      this.selectedAward = null;
    },
    checkProgram(program) {
      if (
        program.status?.toLowerCase() == this.selectedStatus?.toLowerCase() ||
        program.programName?.toLowerCase() == this.programName.toLowerCase() ||
        program.programType?.toLowerCase() ==
          this.selectedType?.toLowerCase() ||
        program.programAward?.toLowerCase() ==
          this.selectedAward?.toLowerCase() ||
        program.programCode?.toLowerCase() == this.programCode.toLowerCase()
      ) {
        return true;
      }
      return false;
    },
    searchProgram() {
      this.Program = this.Program.filter((program) => {
        if (this.checkProgram(program)) return program;
      });
    },
    createProgram(slug) {
      this.$router.push({ name: "CreatePrograms", params: { slug: slug } });
    },
    getPrograms() {
      const store = useProgrammeStore();
      const programs = store.$state.programmes;
      // console.log(programs);
      this.totalPrograms = programs.length;
      this.setProgramCount(store);
      console.log(this.totalPrograms);
      this.Program = programs;
    },
    setProgramCount() {
    
    },
    setProgramme(programs){
      const store = useProgrammeStore();
      store.setProgramme(programs)
    },
    async search(){                    
        const params = `status =${this.selectedStatus}&program_type =${this.selectedType}&program_award =${this.selectedAward}&program_code =${this.programCode}`;
        const res = await get('programs?'+params,'admin',false);         
        this.programs = res
        this.setStudentApplication(this.programs?.data)
      },
    updateIfFromDashboard() {
      const program = JSON.parse(localStorage.getItem('selected_program'));
      if (program) {        
        this.selectedProgram = program;
        this.showViewProgram = true;
      }
      localStorage.removeItem('selected_program')
    }, 
  },
  mounted() {
    this.$nextTick(function(){
      this.updateIfFromDashboard();
    })
  },
  async created(){
      const res = await get('programs','admin',false);            
      this.programs = res
      this.setProgramme(this.programs.data)      
      let res2 = await get('program_dashboard','admin',false);      
      res2 = res2.responseBody;       
      this.docFileCount = [
        {
              id: 0,
              style: "border border-warning px-lg-3 p-3 py-4 rounded border-2",
              count: res2.total,
              img: "arrow-2.svg",
              subTitle: "Total",
              title: 'Program Courses',
              class: "col-lg-4 col-md-6 col-xl-3 mb-3 bg-white",
            }
      ]
      
      res2.programs?.every((item,i)=>{
        this.docFileCount.push( {
            id: i,
            style: "border border-warning px-lg-3 p-3 py-4 rounded border-2",
            count: item.total,
            img: "arrow-2.svg",
            subTitle: "Total",
            title: item.program_type,
            class: "col-lg-4 col-md-6 col-xl-3 mb-3 bg-white",
          });
      })      
    },
};
</script>

<style lang="scss" scoped>
$primary: #fac515;
$warning: #fdb022;
$scrollbar-thumb-bg: #dc6803;
$scrollbar-color: #f79009;
$gray: #d0d5dd;
::v-deep(#inputStatus.p-dropdown) {
  .p-dropdown-items {
    background-color: red !important;
    border-bottom: 1px solid #fac515;
  }
  .p-dropdown-panel .p-dropdown-items .p-highlight {
    color: #495057;
    background: #f70909;
  }
  // .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  //   color: #5de40f;
  //   background: #e70c30;
  // }
}
/** input field hack to ensure the clear btn within the input fields are always circular **/
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}
.trackform-input {
  input {
    padding-right: 40px;
  }
  .form-btna {
    position: absolute;
    right: 10px;
    z-index: 5;
    padding: 4px;
    border: 1px solid #98a2b3;
    border-radius: 100%;
    font-size: 10px;
    background-color: #98a2b3;
    color: white;
  }
}
::v-deep(#inputStatus.p-dropdown-panel) {
  .p-dropdown-items {
    background-color: red !important;
    border-bottom: 1px solid #fac515;
  }
  .p-dropdown-items .p-highlight {
    color: #495057;
    background: #f70909 !important;
  }
}
.active {
  background-color: $primary !important;
}
.filter-btn {
  background-color: $gray;
}
.text-xs {
  font-size: 0.85rem !important;
}
.backgroundImage {
  display: inline-block;
  background-color: #fac515;
  border-radius: 8px;
  padding: 8px 27px;
  border: none;
  font-size: 16px;
}
</style>
